import * as yup from "yup";

export const formSteps = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "password",
        fieldId: "password",
        label: "Password",
        type: "input-password",
        size: "12",
      },
      {
        name: "confirmPassword",
        fieldId: "confirmPassword",
        label: "Confirm passwod",
        type: "input-password",
        size: "12",
      },
    ],
  },
];

export const confirmResetPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string("The password is not valid.")
      .required("Please enter your password.")
      .min(8, "The password must be at least 8 characters long.")
      .matches(/[a-z]+/, "The password must contain at least one lowercase character.")
      .matches(/[A-Z]+/, "The password must contain at least one uppercase character.")
      .matches(/\d+/, "The password must contain at least one number."),
    confirmPassword: yup
      .string("The password is not valid.")
      .required("Please confirm your password.")
      .min(8, "The password must be at least 8 characters long.")
      .matches(/[a-z]+/, "The password must contain at least one lowercase character.")
      .matches(/[A-Z]+/, "The password must contain at least one uppercase character.")
      .matches(/\d+/, "The password must contain at least one number."),
  })
  .required();
