import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { addToState, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { getCurrentUser, getUserById, getUsers, patchUser } from "./actions";
import { patchPromoter } from "../referrals/actions";

const initialState = {
  loading: false,
  data: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
      }))

      .addCase(patchPromoter.fulfilled, (state, { payload }) => ({
        ...state,
        data: (state.data || []).map((i) => {
          if (i.id === payload.userId) {
            return { ...i, promoter: payload }
          }
          return i;
        }),
      }))

      .addMatcher(isAnyOf(getCurrentUser.fulfilled, patchUser.fulfilled, getUserById.fulfilled), (state, { payload }) => ({
        ...state,
        data: addToState(payload, state.data),
      }))

      .addMatcher(isPendingAction("users"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("users"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("users"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default usersSlice.reducer;
