import { Drawer as MuiDrawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import { DrawerWidth } from "../../utilities/constants";

const openedMixin = (theme) => ({
  width: DrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0px",
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledMuiDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" && prop !== "width" })(({ theme, open, width }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: DrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: DrawerWidth,
    boxSizing: "border-box",
    backgroundColor: "rgba(240, 240, 240, 1) !important",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, width),
    "& .MuiDrawer-paper": closedMixin(theme, width),
  }),
}));

const Drawer = ({ children, ...props }) => (
    <StyledMuiDrawer {...props}>
      {children}
    </StyledMuiDrawer>
)

Drawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
}

export default Drawer;