import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CustomCircularProgress = ({ value }) => (
  <Box sx={{ position: "relative", display: "inline-flex" }}>
    <CircularProgress variant="determinate" value={value} size="150px" sx={{ color: value === 100 ? "green" : "rgb(255, 99, 108)", borderRadius: "100%", boxShadow: "inset 0 0 0px 11px silver" }} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="caption" component="div" color="text.secondary" fontSize="40px">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

CustomCircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CustomCircularProgress;
