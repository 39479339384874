import { useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

import { getCurrentUserSelector } from "../../../../redux/users/selector";
import {
  CompanyVerificationSteps,
  PromoterVerificationSteps,
  Roles,
  UserVerificationSteps
} from "../../../../utilities/constants";
import PrimaryButton from "../../../../components/shared/PrimaryButton";
import CircularProgress from "../../../../components/shared/CircularProgress";
import useWindowDimensions from "../../../../utilities/hooks/useWindowDimensions";
import JobCard from "../../../../components/shared/JobCard";
import { getSavedJobsSelector } from "../../../../redux/jobs/selector";

const UserDashboard = () => {
  const [verificationStatuses, setVerificationStatuses] = useState(null);
  const user = useSelector(getCurrentUserSelector);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const savedJobs = useSelector(getSavedJobsSelector);

  useEffect(() => {
    switch (user.role) {
      case Roles.USER.value:
        setVerificationStatuses(UserVerificationSteps);
        break
      case Roles.COMPANY.value:
        setVerificationStatuses(CompanyVerificationSteps);
        break
      case Roles.PROMOTER.value:
        setVerificationStatuses(PromoterVerificationSteps);
        break
      default:
        break;
    }
  }, [user]);
  
  const verificationTitle = useMemo(() => {
    let text = 'Verify your profile';
    if (user.verificationStatus === 'PENDING') {
      text = 'Your have finished your verification and it is currently being reviewed by an admin.'
    } 
    if (user.verificationStatus === 'APPROVED') {
      text = 'Your account has been verified.'
    }

    let userSpecificText = " to share your referral link"
    if (user.role === Roles.USER.value) {
      userSpecificText = " to apply for jobs";
    }
    if (user.role === Roles.COMPANY.value) {
      userSpecificText = " creating job ads";
    }

    return (
      <Box>
        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
          {text}
        </Typography>
        {!['APPROVED', 'REJECTED', 'PENDING'].includes(user.verificationStatus) && (
          <Typography variant="p">
            Before you start
            {userSpecificText}, you must first verify your profile.
          </Typography>)}
      </Box>
    )
  }, [user]);

  return verificationStatuses && (
      <Box>
        <Box backgroundColor="#f9f9f9" padding="5% 5%">
          <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box marginBottom="25px">
                {verificationTitle}
              </Box>
              <Grid container sx={{ marginBottom: "30px" }}>
                {Object.values(verificationStatuses).map((i, index) => (
                  <Grid
                    key={i.label}
                    item
                    xs={12}
                    sm={12}
                    md={Object.keys(verificationStatuses).length > 3 ? 6 : 12}
                    lg={Object.keys(verificationStatuses).length > 3 ? 6 : 12}
                    sx={{ textAlign: "left", paddingLeft: width > 800 && Object.keys(verificationStatuses).length > 3 && index % 2 === 1 ? "25px" : "0" }}
                  >
                    <Box marginBottom="5px">
                      <Typography variant="p" sx={{ textAlign: "left" }}>
                        {i.label}
                      </Typography>
                      {(user?.isAccountVerified || (user?.verificationStatus === "PENDING" || verificationStatuses[user.verificationStatus].value > i.value)) && <CheckCircleIcon sx={{ float: "right" }} />}
                      <Divider sx={{ paddingBottom: "10px" }} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box>
                {user?.verificationStatus === "PENDING" ? (
                  <Box>
                    <Typography>
                      If the process takes more than 2-3 business days, please contact us at office@posrednik24.com.
                    </Typography>
                  </Box>
                ) : (
                  <PrimaryButton sx={{ marginBottom: '15px' }} onClick={() => navigate(user.isAccountVerified ? "/profile" : "/verification")}>{user.isAccountVerified ? "Edit Profile" : "Finish Verification"}</PrimaryButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={5} sx={{ margin: "auto" }}>
              <CircularProgress
                value={
                  user?.isAccountVerified ? 100 : (
                    user?.verificationStatus === "PENDING"
                      ? 100
                      : verificationStatuses[user.verificationStatus].value === 0
                      ? 0
                      : ((verificationStatuses[user.verificationStatus].value - 1) * 100) / Object.keys(verificationStatuses).length
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="h3">Saved Jobs</Typography>
          {savedJobs.length > 0 ? (
            <Grid container sx={{ marginTop: '3%', margin: 'auto' }}>
              {savedJobs.map((jobAd) => (
                <Grid item key={jobAd.title} xs={12} sm={12} md={6} lg={6} sx={{ paddingLeft: "10px", marginTop: '20px' }}>
                  <JobCard jobAd={jobAd} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box mt={3}>
              <Typography variant="h6">Save jobs that you like so that they show up in your dashboard.</Typography>
            </Box>
          )}
        </Box>
      </Box>
  );
};

export default UserDashboard;
