import { createSlice } from "@reduxjs/toolkit";

import { setPreviousPath } from "./actions";

const initialState = {
  previousPath: "/jobs",
};

export const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setPreviousPath, (state, { payload }) => ({
      ...state,
      previousPath: payload || "/jobs",
    }));
  },
});

export default utilitiesSlice.reducer;
