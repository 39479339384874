import { useEffect } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ListItemText, MenuItem, Select, Typography, Box } from "@mui/material";

import { getCurrentUserSelector } from "../../../redux/users/selector";
import { getJobByIdSelector } from "../../../redux/jobs/selector";
import CustomTabs from "../../../components/shared/Tabs";
import JobForm from "../../../components/shared/JobForm";
import { JobStatus, Roles } from "../../../utilities/constants";
import Applications from "../../private/Applications";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { getJobById, updateJob } from "../../../redux/jobs/actions";
import View from "./components/View";

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SingleJob = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const job = useSelector(getJobByIdSelector(params.id));
  const user = useSelector(getCurrentUserSelector);
  const previousPath = useSelector((state) => state.utilities.previousPath);

  useEffect(() => {
    if (!job) {
      dispatch(getJobById({ id: params.id }))
        .unwrap()
        .catch((err) => {
          if (err?.status === 404) {
            navigate("/jobs");
          }
        });
    }
  }, [job, params.id, dispatch, navigate]);

  return job && (user === null || user?.role === Roles.USER.value ? (
      <View job={job} />
    ) : (
      <CustomTabs
        tabs={[
          {
            label: "View",
            value: "view",
            component: <View job={job} />,
            onClick: () => navigate("?tab=view"),
          },
          {
            label: "Edit",
            value: "edit",
            component: <JobForm job={job} />,
            onClick: () => navigate("?tab=edit"),
            disabled: job?.status === JobStatus.CLOSED.value,
          },
          {
            label: "Applicants",
            value: "applicants",
            component: <Applications jobId={job.id} />,
            onClick: () => navigate("?tab=applicants"),
          },
        ]}
        header={
          <Box pb={1}>
            <PrimaryButton onClick={() => navigate(previousPath || "/jobs")} sx={{ marginRight: "20px" }}>
              Back
            </PrimaryButton>
            <Select sx={{ height: "45px" }} disabled={job.status === JobStatus.CLOSED.value} value={job.status} onChange={(e) => dispatch(updateJob({ ...job, status: e.target.value }))}>
              {Object.values(JobStatus).map((val) => (
                <MenuItem key={val.value} value={val.value} selected={val.value === job.status}>
                  <ListItemText primary={val?.label} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        defaultTab="view"
      />
    )
  );
};

export default SingleJob;
