import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { resetPasswordSchema, formSteps } from "../formDetails";
import Form from "../../../../components/form/Form";
import PrimaryButton from "../../../../components/shared/PrimaryButton";
import Link from "../../../../components/shared/Link";
import { resetPassword } from "../../../../redux/auth/actions";

const styles = {
  pageTitle: {
    color: "#C02026",
    mb: 1,
    // fontSize: {
    //   xs: '13px',
    //   sm: '15px',
    // }
  }
}

const ResetPassword = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);

  const form = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onChange",
  });

  const handleSubmit = (values) => {
    dispatch(resetPassword(values))
      .unwrap()
      .then(() => setActiveStep(1));
  };

  return (
    <Box margin="auto" padding="0 30px">
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={styles.pageTitle}>
          Reset your password
        </Typography>
        <Typography variant="h6">Please enter your email address to continue</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ textAlign: "center", mb: 3 }}>
              <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "400" }}>
                Remembered your password? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} sx={{ mt: 1, textAlign: "center" }}>
              <PrimaryButton type="submit" disabled={isLoading}>
                Reset Password
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

ResetPassword.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default ResetPassword;
