import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { addToState, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import {
  createJob,
  deleteJob,
  getJobById,
  getJobs,
  removeSavedJob,
  saveJob,
  setStepValue,
  updateJob
} from "./actions";
import { createApplication } from "../application/actions";

const initialState = {
  loading: false,
  data: null,
  jobsCount: 0,
  error: null,
  stepValue: null,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setStepValue, (state, { payload }) => ({
        ...state,
        stepValue: payload,
      }))

      .addCase(getJobs.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
        error: null,
      }))

      .addCase(createApplication.fulfilled, (state, { payload }) => ({
        ...state,
        data: addToState(
          {
            id: payload.jobId,
            application: payload,
          },
          state.data,
          false
        ),
      }))

      .addCase(deleteJob.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state.data || []).filter((i) => i.id !== meta.arg.id),
      }))

      .addCase(saveJob.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state.data || []).map((i) => {
          if (i.id === meta.arg.id) {
            return { ...i, isSaved: true };
          }
          return i;
        }),
      }))

      .addCase(removeSavedJob.fulfilled, (state, { meta }) => ({
        ...state,
        data: (state.data || []).map((i) => {
          if (i.id === meta.arg.id) {
            return { ...i, isSaved: false };
          }
          return i;
        }),
      }))

      .addMatcher(isAnyOf(getJobById.fulfilled, createJob.fulfilled, updateJob.fulfilled), (state, { payload }) => ({
        ...state,
        data: addToState(payload, state.data),
        error: null,
      }))

      .addMatcher(isPendingAction("jobs"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("jobs"), (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload,
        jobs: null,
        isAuthenticated: false,
      }))
      .addMatcher(isFulfilledAction("jobs"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default jobsSlice.reducer;
