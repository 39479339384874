import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, Skeleton, Grid, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";
import ErrorBox from "../shared/ErrorBox";

const RadioLocal = ({ field, register, control, errors, isLoading }) => (
  <FormControl component="fieldset" sx={{ minWidth: "100%" }}>
    {isLoading ? (
      <Skeleton animation="wave" />
    ) : (
      <>
        <FormLabel component="legend" sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{
              color: "#525252",
            }}
            fontWeight="bold"
            fontSize="16px"
          >
            {field?.label}
            {field.isRequired && <span>*</span>}
          </Typography>
          <Typography variant="body1" component="span">
            {field?.helpText}
          </Typography>
        </FormLabel>
        <Controller
          rules={{ required: true }}
          control={control}
          className={field.fieldClass}
          name={field.name}
          inputRef={register(field.name)}
          render={({ field: fieldVal }) => (
            <RadioGroup
              {...{
                ...fieldVal,
                value: fieldVal.value === undefined ? false : fieldVal.value.toString(),
              }}
              row
              disabled={field.disabled}
              name={field.name}
            >
              <Grid container gap={field.options.length > 6 ? 0 : 2} margin="auto">
                {field.options.map((option) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={field.options.length < 5 ? 5 : 3}
                    lg={field.options.length < 5 ? 5 : 3}
                    key={option.value}
                    sx={field.options.length < 3 ? { margin: "auto", textAlign: "center" } : {}}
                  >
                    <FormControlLabel
                      sx={{
                        // eslint-disable-next-line no-nested-ternary
                        border: _.get(errors, field.name)?.message ? "1px solid #C75C57" : fieldVal.value?.toString() === option.value ? "1px solid #F2D25C" : "1px solid #AEBBCE",
                        borderRadius: "4px",
                        width: "90%",
                        marginBottom: field.options.length > 6 ? "10px" : "0",
                        marginLeft: "auto",
                        padding: "10px",
                        background: fieldVal.value?.toString() === option.value ? "#FFF7D9" : "#FAFAFA",
                      }}
                      value={option.value}
                      key={option.value}
                      control={
                        <Radio
                          value={option.value}
                          id={field.name}
                          sx={{ color: "#C75C57", fontWeight: "bold" }}
                          disabled={field.disabled}
                          checked={fieldVal.value === undefined ? false : fieldVal.value.toString() === option.value}
                          checkedIcon={<CheckCircleOutlineIcon sx={_.get(errors, field.name)?.message ? { color: "#C75C57" } : { color: "#1e2836" }} />}
                          icon={<RadioButtonUncheckedIcon sx={_.get(errors, field.name)?.message ? { color: "#C75C57" } : { color: "#1e2836" }} />}
                        />
                      }
                      label={option.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          )}
        />
        <Box margin="auto">
          <ErrorBox message={_.get(errors, field.name)?.message} />
        </Box>
      </>
    )}
  </FormControl>
);

RadioLocal.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RadioLocal;
