import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "./actions";

const initialState = {
  loading: false,
  data: [],
};

export const notificationsSlice = createSlice({
  name: "noifications",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => ({
      ...state,
      data: payload,
    }));
  },
});

export default notificationsSlice.reducer;
