import * as yup from "yup";
import { INDUSTRIES, Roles } from "../../../../utilities/constants";

export const filterFormSchema = yup.object().shape({
  role: yup.string("The role is not valid."),
  email: yup.string("The email is not valid."),
  isAccountVerified: yup.bool("The account verification is not valid.").nullable(),
});

export const filterFormFields = (promoters) => [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "email",
        fieldId: "email",
        label: "E-mail",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "profile.address.country",
        fieldId: "country",
        label: "Country",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "profile.address.city",
        fieldId: "city",
        label: "City",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "profile.languages",
        fieldId: "languages",
        label: "Languages",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "profile.workExperience",
        fieldId: "workExperience",
        label: "Work Experience",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "industry",
        searchableFieldName: "profile.workExperience",
        fieldId: "industry",
        label: "Industry",
        type: "select",
        values: INDUSTRIES.asList(),
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "profile.education",
        fieldId: "education",
        label: "Education",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "role",
        fieldId: "role",
        label: "Role",
        type: "select",
        style: {
          padding: "0 10px",
          size: "small",
        },
        values: [{ label: "Please select a role...", value: "" }, ...Roles.asList()],
        isRequired: false,
        size: 4,
      },
      {
        name: "referral",
        fieldId: "referral",
        label: "Referral",
        type: "select",
        values: [{ value: "", label: "Please select a promoter" }, ...promoters.map((i) => ({ value: i.email, label: i.email }))],
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "isAccountVerified",
        fieldId: "isAccountVerified",
        label: "Account Verified",
        type: "select",
        defaultValue: "",
        values: [{value: "", label: "Select a value"}, {value: true, label: "Yes"}, {value: false, label: "No"}],
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "isEmailVerified",
        fieldId: "isEmailVerified",
        label: "Email Verified",
        type: "select",
        values: [{value: "", label: "Select a value"}, {value: true, label: "Yes"}, {value: false, label: "No"}],
        defaultValue: "",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "jobApplicationsCount",
        fieldId: "jobApplicationsCount",
        label: "Has applied to jobs",
        type: "select",
        values: [{value: "", label: "Select a value"}, {value: false, label: "Yes"}, {value: true, label: "No"}],
        defaultValue: "",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
    ],
  },
];
