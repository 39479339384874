import { useState } from "react";

import ResetPassword1 from "./components/ResetPassword";
import CheckMailbox from "./components/CheckMailbox";

const ResetPassword = () => {
  const [activeStep, setActiveStep] = useState(0);

  return activeStep === 0 ? <ResetPassword1 setActiveStep={setActiveStep} /> : <CheckMailbox />;
};

export default ResetPassword;
