import { Box, Typography } from "@mui/material";

const ErrorBoundary = () => (
    <Box height='70vh'>
      <Box sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'left', width: '50%', margin: 'auto' }}>
        <Typography variant='h1' fontSize='100px' sx={{ color: "#C02026" }}>
          Oops!
        </Typography>
        <Typography variant="h3" sx={{ marginTop: '18px' }}>
          Something went wrong here.
        </Typography>
        <Typography variant='h6' sx={{ marginTop: '12px' }}>
          If you see this, please report this error at <strong>office@posrednik24.com</strong>. To continue browsing, you can go back and continue to use our application.
        </Typography>
      </Box>

    </Box>
  )

export default ErrorBoundary;