import { Box, Divider, Grid, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/logo.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Divider />
      <Grid container sx={{ margin: '2% auto 2%', width: '80%' }}>
        <Grid item md={3} lg={3} sx={{ padding: '0 20px', margin: '0 0 20px' }}>
          <Box sx={{ textAlign: 'left' }}>
            <Box mb={1}>
              <img src={Logo} alt="Posrednik Logo" style={{ width: "150px" }} />
            </Box>
            <Box>
              <Typography sx={{ color: '#5E6282' }} variant='p'>Find your perfect job, travel, make experiences and
                friendships that will last a lifetime in Germany.</Typography>
            </Box>
            <Box mt={2}>
              {[
                {
                  link: '',
                  icon: FacebookIcon,
                },
                {
                  link: '',
                  icon: InstagramIcon
                },
                {
                  link: '',
                  icon: TwitterIcon
                }
              ].map((i) => (
                <Box display='inline-block' sx={{ paddingRight: '7px' }}>
                  <i.icon sx={{ fontSize: '30px' }}/>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} lg={3} sx={{ padding: '0 20px', margin: '0 0 20px', textAlign: 'left' }}>
          <Box mb={2}>
            <Typography variant='h6' fontWeight='600'>About the company</Typography>
          </Box>
          <Box>
            {[
              {
                title: "Gallery",
                link: "https://posrednik24.com/gallery/",
              },
              {
                title: "About Us",
                link: "https://posrednik24.com/about-us/",
              },
              {
                title: "Contact",
                link: "https://posrednik24.com/contact/",
              },
              {
                title: "Blog",
                link: "https://posrednik24.com/blog/",
              },
              {
                title: "Imprint",
                link: "https://posrednik24.com/imprint/",
              },
              {
                title: "Terms & Conditions",
                link: "https://posrednik24.com/terms-and-conditions/",
              },
              {
                title: "Privacy Policy",
                link: "https://posrednik24.com/privacy-policy/",
              },
            ].map((i) => (
              <a href={i.link} rel='noreferrer' className='footer-menu-link'>
                <Box>
                  <span>{i.title}</span>
                </Box>
              </a>
            ))}
          </Box>
        </Grid>
        <Grid item md={3} lg={3} sx={{ padding: '0 20px', margin: '0 0 20px', textAlign: 'left' }}>
          <Box sx={{ textAlign: 'left' }}>
            <Box mb={2}>
              <Typography variant='h6' fontWeight='600'>Our Services</Typography>
            </Box>
            {[
              {
                title: "Jobs",
                navigate: () => navigate('/jobs'),
              },
              {
                title: "CV Maker",
                link: "#",
              },
              {
                title: "Documents",
                navigate: () => navigate('/documents'),
              },
              {
                title: "Insurance",
                link: "https://posrednik24.com/insurance/",
              },
              {
                title: "Tax Refund",
                link: "https://posrednik24.com/tax-refund/"
              },
              {
                title: "Transport",
                link: "https://posrednik24.com/transport/",
              },
              {
                title: "Language Courses",
                link: "https://posrednik24.com/language-courses/",
              },
            ].map((i) => (
              <a href={i?.link} onClick={i?.navigate} rel='noreferrer' className='footer-menu-link'>
                <Box>
                  <span>{i.title}</span>
                </Box>
              </a>
            ))}
          </Box>
        </Grid>
        <Grid item md={3} lg={3} sx={{ padding: '0 20px', margin: '0 0 20px' }}>
          <Box  sx={{ textAlign: 'left' }}>
            <Box mb={2}>
              <Typography variant='h6' fontWeight='600'>Contact Us</Typography>
            </Box>
            <Box>
              {[
                {
                  content: "Address: st. Franjo Kluz 7/2-5, 1000 Skopje, N. Macedonia",
                  icon: LocationOnIcon,
                },
                {
                  content: "Phone: +389 79 332 862",
                  icon: PhoneEnabledIcon
                },
                {
                  content: "E-mail: office@posrednik24.com",
                  icon: MailIcon
                }
              ].map((i) => (
                <Box sx={{ textAlign: "left", display: 'block' }} mb={1}>
                  <Grid container>
                    <Grid item md={1} lg={1}>
                      <i.icon/>
                    </Grid>
                    <Grid item md={11} lg={11} sx={{ paddingLeft: '10px' }}>
                      <Typography variant='p' sx={{ verticalAlign: 'middle', color: '#5E6282' }}>{i.content}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ height: '35px' }}>
        <Typography sx={{ color: '#5E6282' }}>Posrednik24 Skopje {new Date().getFullYear()} All rights are reserved.</Typography>
      </Box>
    </Box>
  )
}

export default Footer;