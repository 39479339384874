import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createDocuments, deleteDocument, getAdminDocuments, getUserDocuments, updateDocuments } from "./actions";
import { addToState } from "../../utilities";

const initialState = {
  loading: false,
  adminDocuments: null,
  userDocuments: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAdminDocuments.fulfilled, (state, { payload }) => ({
        ...state,
        adminDocuments: payload,
      }))

      .addCase(getUserDocuments.fulfilled, (state, { payload }) => ({
        ...state,
        userDocuments: payload,
      }))

      .addCase(deleteDocument.fulfilled, (state, { meta }) => {
        if (meta.arg.isAdminDocument) {
          return {
            ...state,
            adminDocuments: state.adminDocuments.filter((i) => i.id !== meta.arg.id),
          }
        }
        return {
          ...state,
          userDocuments: state.userDocuments.filter((i) => i.id !== meta.arg.id),
        }
      })

      .addMatcher(isAnyOf(createDocuments.fulfilled, updateDocuments.fulfilled), (state, { payload }) => {
        if (payload[0].isAdminDocument) {
          return {
            ...state,
            adminDocuments: addToState(payload[0], state.adminDocuments),
          }
        }
        return {
          ...state,
          userDocuments: addToState(payload[0], state.userDocuments),
        }
      })
  },
});

export default documentsSlice.reducer;
