import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

const ErrorBox = ({ message, showIcon }) =>
  message && (
    <Box mt={1.5}>
      {showIcon && <WarningIcon sx={{ color: "#C75C57", mr: 1 }} />}
      <Typography variant="h6" component="span" sx={{ color: "#C75C57", verticalAlign: "middle", fontSize: "14px" }}>
        {message}
      </Typography>
    </Box>
  );

ErrorBox.propTypes = {
  message: PropTypes.string,
  showIcon: PropTypes.bool,
};

ErrorBox.defaultProps = {
  message: "",
  showIcon: true,
};

export default ErrorBox;
