import { Box, FormControl, FormLabel, Grid, Skeleton, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ErrorBox from "../shared/ErrorBox";

const popperSx = {
  "& .MuiPaper-root": {
    border: "1px solid black",
    padding: 2,
    marginTop: 1,
    backgroundColor: "#FAFAFAFF",
  },
  "& .MuiCalendarPicker-root": {
    backgroundColor: "#FAFAFAFF",
  },
  "& .PrivatePickersSlideTransition-root": {},
  "& .MuiPickersDay-dayWithMargin": {
    color: "#1e2836",
    backgroundColor: "#FAFAFAFF",
  },
  "& .Mui-selected": {
    backgroundColor: "#C02026 !important",
    border: "0px",
    color: "white",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" },
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)

const DateField = ({ fieldDetails, register, control, errors, isLoading }) => {

  const getFormLabel = () => (
    <FormLabel component="legend" sx={{ mb: 2 }}>
      <Typography variant="h6">
        {fieldDetails?.label}
        {fieldDetails.isRequired && <span> *</span>}
      </Typography>
      <Typography variant="body1" component="span">
        {fieldDetails?.helpText}
      </Typography>
    </FormLabel>
  )

  const getFormField = () => (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          control={control}
          id={fieldDetails.fieldId}
          className={fieldDetails.fieldClass}
          name={fieldDetails.name}
          disabled={fieldDetails.disabled}
          inputRef={register(fieldDetails.name)}
          render={({ field }) => (
            <DatePicker
              popperPlacement="bottom-start"
              openTo="year"
              timezone="system"
              disableFuture={fieldDetails?.disableFuture}
              views={["year", "month", "day"]}
              PopperProps={{ sx: popperSx }}
              InputAdornmentProps={{ position: "start" }}
              format="DD/MM/YYYY"
              defaultValue={null}
              {...field}
              value={field.value === undefined ? null : (dayjs.isDayjs(field.value) ? field.value : dayjs(field.value)).tz(dayjs.tz.guess(), true).startOf('day')}
              onChange={(val) => field.onChange(val.tz(dayjs.tz.guess(), true).startOf('day').format())}
            />
          )}
        />
      </LocalizationProvider>
      <ErrorBox message={_.get(errors, fieldDetails.name)?.message} />
    </>
  )

  return (
    <FormControl component="fieldset" sx={{ minWidth: "100%" }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (fieldDetails?.options?.sameRow ? (
        <Grid container>
          <Grid item xs={4} sm={3} md={4} lg={4}>
            {getFormLabel()}
          </Grid>
          <Grid item xs={8} sm={9} md={8} lg={8}>
            {getFormField()}
          </Grid>
        </Grid>
      ) : (
          <Box>
            {getFormLabel()}
            {getFormField()}
          </Box>
        )
      )}
    </FormControl>
  );
}

DateField.propTypes = {
  fieldDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    multiple: PropTypes.bool,
    helpText: PropTypes.string,
    isImages: PropTypes.bool,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    fieldId: PropTypes.string,
    type: PropTypes.string.isRequired,
    min: PropTypes.number,
    defaultValue: PropTypes.string,
    icon: PropTypes.element,
    fieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.shape({
      find: PropTypes.string,
      sameRow: PropTypes.bool,
    }),
    style: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
  }),
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DateField.defaultProps = {
  fieldDetails: {
    isRequired: false,
    disableFuture: false,
  }
}

export default DateField;
