import { useEffect, useState } from "react";

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import Table from "../../../../components/shared/Table";
import { capitalizeFirstLetter } from "../../../../utilities";
import { getReferrals } from "../../../../redux/referrals/actions";
import SecondaryButton from "../../../../components/shared/SecondaryButton";
import { getCurrentUserSelector } from "../../../../redux/users/selector";
import { setPreviousPath } from "../../../../redux/utilities/actions";
import { ViewButton } from "../../Users";

const ReferralCodeDialog = ({ referralCode, setOpen }) => (
    <Box>
      <DialogTitle>
        Referral code
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <Typography variant="h4">
              Your referral code is: <strong>{referralCode}</strong>
            </Typography>
            <Box mt={2}>
              <Typography>
                Share the link below with other people to register/login, and they will be added as your referrals:
                <br />
              </Typography>
              <Box border="1px solid silver" borderRadius="7px" padding="13px 5px">
                <strong>
                  {window.location.origin}/login?referral_code={referralCode}
                </strong>
                <ContentCopyIcon
                  sx={{ float: "right", cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/register?referral_code=${referralCode}`);
                    toast.success("Coppied to clipboard", { toastId: "clipboard-copy" });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );

ReferralCodeDialog.propTypes = {
  referralCode: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const columns = (dispatch) => [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "email",
    label: "E-mail",
    value: (row) => row?.email,
    props: {
      align: "center",
    },
  },
  {
    id: "createdAt",
    label: "Created Date",
    value: (row) => capitalizeFirstLetter(moment(row.createdAt).format("DD/MM/YYYY")),
    props: {
      align: "center",
    },
  },
  {
    id: "view-button",
    value: (row) => <ViewButton id={row.id} onClick={() => dispatch(setPreviousPath("/users"))} />,
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
];

const PromoterDashboard = () => {
  const referrals = useSelector((state) => state.referrals);
  const [open, setOpen] = useState(false);
  const user = useSelector(getCurrentUserSelector);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!referrals.data) {
      dispatch(getReferrals());
    }
  }, [referrals.data]);

  return (
    <Box>
      <Table
        data={referrals.data || []}
        columns={columns(dispatch)}
        isLoading={referrals.loading}
        header={
          <Box padding="10px 10px">
            <Grid container>
              <Grid item md={6} lg={6}>
                <Typography variant="h3">Referrals</Typography>
              </Grid>
              <Grid item md={6} lg={6} sx={{ textAlign: "right" }}>
                <Box>
                  <SecondaryButton onClick={() => setOpen(true)} sx={{ marginRight: 1 }}>
                    <span>{user?.promoter?.code ? "View Code" : "Create a Code"}</span>
                  </SecondaryButton>
                  <SecondaryButton onClick={() => dispatch(getReferrals())}><span>Refresh</span></SecondaryButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
        noResultsContent="There are no referrals. Share your link/code to get them."
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <ReferralCodeDialog referralCode={user?.promoter?.code} setOpen={setOpen} />
      </Dialog>
    </Box>
  );
};

export default PromoterDashboard;
