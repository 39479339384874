import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";
import { createFormData } from "../../utilities";

export const getAdminDocuments = createAsyncThunk("documents/adminGetAll", async (values, { rejectWithValue }) =>
  makeRequest('/documents', "GET", values, rejectWithValue)
);

export const getUserDocuments = createAsyncThunk("documents/userGetAll", async (values, { rejectWithValue }) =>
  makeRequest(`/documents${values?.userId ? `?userId=${values.userId}` : ""}`, "GET", values, rejectWithValue)
);

export const createDocuments = createAsyncThunk("documents/create", async (values, { rejectWithValue }) => makeRequest(`/documents${values?.userId ? `?userId=${values?.userId}` : ""}`, "POST", createFormData({ documents: values.documents }), rejectWithValue));

export const updateDocuments = createAsyncThunk("documents/update", async (values, { rejectWithValue }) => makeRequest("/documents", "PUT", createFormData(values), rejectWithValue));

export const deleteDocument = createAsyncThunk("documents/delete", async (values, { rejectWithValue }) => makeRequest(`/documents/${values?.id}?type=${values?.isAdminDocument ? 'document' : 'file'}`, "DELETE", values, rejectWithValue));
