import MuiPhoneNumber from "material-ui-phone-number";
import PropTypes from "prop-types";
import { FormLabel, Grid, Typography } from "@mui/material";
import _ from "lodash";

import ErrorBox from "../shared/ErrorBox";

const PhoneField = ({ value, setValue, field, errors }) => (
    <Grid container>
      <Grid item xs={4} sm={3} md={4} lg={4}>
        <FormLabel component="legend" sx={{ mb: 2, ...(field.label === "" && { minHeight: "20px" }) }}>
          <Typography variant="h6">
            {field?.label}
            &nbsp;
            {field?.isRequired && <span>*</span>}
          </Typography>
          <Typography variant="body1" component="span">
            {field?.helpText}
          </Typography>
        </FormLabel>
      </Grid>
      <Grid item xs={4} sm={3} md={8} lg={8}>
        <MuiPhoneNumber
          style={{ border: '1px solid silver', padding: '7px 5px', borderRadius: '5px', width: '100%' }}
          defaultCountry='mk'
          placeholder={field?.placeholder || "Enter phone number"}
          value={value}
          onChange={(phone) => setValue(field.name, phone)}
        />
        <ErrorBox message={_.get(errors, field.name)?.message} showIcon={false} />
      </Grid>
    </Grid>
  )

PhoneField.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default PhoneField;
