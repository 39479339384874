/* eslint-disable eqeqeq */
import { Roles } from "../../utilities/constants";

const getUserTypeData = (user, companies, profiles) => {
  if (user.role === Roles.COMPANY.value) {
    return companies?.find((i) => i.userId == user.id);
  }
  if (user.role === Roles.USER.value) {
    return profiles?.find((i) => i.userId == user.id);
  }
  return null;
};

export const getUserSelector = (userId) => (state) => {
  if (userId && state.auth.isAuthenticated && state.users.data) {
    const user = state.users.data.find((i) => i.id == userId);
    if (user) {
      return {
        ...user,
        [user.role === Roles.USER.value ? "profile" : "company"]: getUserTypeData(user, state.companies.data, state.profiles.data),
      }
    }
  }
  return null;
};

export const getCurrentUserSelector = (state) => getUserSelector(state.auth.currentUserId)(state);
