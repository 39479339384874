import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { addToState, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { getCompanies, getCompany, patchCompany } from "./actions";
import { getCurrentUser, getUserById, patchUser } from "../users/actions";
import { Roles } from "../../utilities/constants";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
        error: null,
      }))

      .addMatcher(isAnyOf(getCompany.fulfilled, patchCompany.fulfilled), (state, { payload }) => ({
        ...state,
        data: addToState(payload, state.data),
        error: null,
      }))

      .addMatcher(isAnyOf(getCurrentUser.fulfilled, patchUser.fulfilled, getUserById.fulfilled), (state, { payload }) =>
        payload.role === Roles.COMPANY.value
          ? {
              ...state,
              data: addToState(payload.company, state.data),
            }
          : state
      )

      .addMatcher(isPendingAction("company"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("company"), (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload,
      }))
      .addMatcher(isFulfilledAction("company"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default companySlice.reducer;
