import React, { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import PrimaryButton from "../PrimaryButton";
import Form from "../../form/Form";
import { jobFormSchema, jobFormSteps } from "./formDetails";
import { createJob, updateJob } from "../../../redux/jobs/actions";
import { getCompanies } from "../../../redux/company/actions";
import { Roles } from "../../../utilities/constants";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import { getAdminDocuments } from "../../../redux/documents/actions";

const JobForm = ({ job }) => {
  const [page, setPage] = useState(0);
  const [steps, setSteps] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getCurrentUserSelector, shallowEqual);
  const companies = useSelector((state) => state.companies.data);
  const documents = useSelector((state) => state.documents.adminDocuments);
  const isLoading = useSelector((state) => state.jobs.loading);

  const form = useForm({
    resolver: yupResolver(jobFormSchema(user, documents)),
    mode: "onChange",
  });

  useEffect(() => {
    if (job) {
      Object.keys(job).forEach((k) => form.setValue(k, job[k]));
    }
  }, [job]);

  useEffect(() => {
    if (documents === null) {
      dispatch(getAdminDocuments());
    }

    if (user.role === Roles.USER.value) {
      navigate("/jobs");
    } else if (user.role === Roles.COMPANY.value) {
      if (!user?.isAccountVerified) {
        navigate("/dashboard");
        toast.error(
          user?.verificationStatus && user.verificationStatus === "PENDING" ? "Your verification must be approved to create a job." : "You must first finish the verification to create a job ad.",
          { toastId: "finish-verification-error" }
        );
      } else {
        setSteps(
          jobFormSteps(
            user,
            [],
            documents.map((i) => ({ value: i.name, label: i.name }))
          )
        );
      }
    } else if (user.role === Roles.ADMIN.value) {
      if (companies === null) {
        dispatch(getCompanies());
      } else {
        setSteps(
          jobFormSteps(
            user,
            companies,
            documents.map((i) => ({ value: i.name, label: i.name }))
          )
        );
      }
    }
  }, [user, companies, documents]);

  const handleNext = async () => {
    await form.trigger(steps[page].fields.map((step) => step.name));
    if (Object.keys(form.formState.errors).length === 0) {
      if (steps.length === page + 1) {
        if (job) {
          dispatch(updateJob(form.getValues()))
            .unwrap()
            .then(() => {
              toast.success("The job ad has been updated.", { toastId: "job-ad-update-success" });
              navigate(`?tab=view`);
            });
        } else {
          dispatch(createJob(form.getValues()))
            .unwrap()
            .then((data) => {
              toast.success("The job ad has been created.", { toastId: "job-ad-create-success" });
              navigate(`/jobs/${data.id}?tab=view`);
            });
        }
      } else {
        setPage((oldPage) => oldPage + 1);
      }
    }
  };

  return (
    user && (
      <Box width="80%" margin="20px auto">
        {steps && (
          <Form
            steps={steps}
            form={form}
            startPage={page}
            FormButtons={
              <Grid container justifyContent="space-between" margin="auto">
                {page !== 0 && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PrimaryButton onClick={() => setPage((oldPage) => oldPage - 1)}>Back</PrimaryButton>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={page !== 0 ? 6 : 12} lg={page !== 0 ? 6 : 12}>
                  <PrimaryButton onClick={handleNext} loading={isLoading}>
                    {steps.length === page + 1 ? (job ? "Update Job" : "Post Job") : "Next"}
                  </PrimaryButton>
                </Grid>
              </Grid>
            }
          />
        )}
      </Box>
    )
  );
};

JobForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  job: PropTypes.object,
};

JobForm.defaultProps = {
  job: null,
};

export default JobForm;
