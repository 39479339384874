import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";

import ErrorBox from "../shared/ErrorBox";

export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ error }) => ({
  backgroundColor: "white",
  "&.MuiInputBase-root": {
    borderColor: error && "#C75C57",
    "&:hover fieldset": {
      border: error ? "1px solid #C75C57" : "1px solid black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: error ? "1px solid #C75C57" : "1px solid black",
    },
  },
  "&.MuiPaper-root": {
    minWidth: "150px !important",
  },
}));

const SelectField = ({ field, register, control, errors, isLoading }) => {
  const renderLabels = (fieldVal) =>
    field.values.map((option, index) => {
      let item;
      if (field.multiple && index !== 0) {
        item = (
          <>
            <Checkbox
              sx={{
                "&.MuiCheckbox-root": {
                  color: "#1e2836",
                },
              }}
              checked={!fieldVal.value ? false : fieldVal.value.includes(option.value)}
            />
            <ListItemText primary={option?.label} />
          </>
        );
      } else if (field.isImages) {
        if (option.value === "") {
          item = <ListItemText primary={option?.label} />;
        } else {
          item = <img src={option.label} alt="test" style={{ width: "100px" }} />;
        }
      } else {
        item = <ListItemText primary={option?.label} />;
      }

      return (
        <MenuItem
          key={option.value}
          value={option.value}
          selected={fieldVal.value !== undefined ? option.value === fieldVal.value : index === 0}
        >
          {item}
        </MenuItem>
      );
    });

  const getFormLabel = () => (
    <FormLabel component="legend" sx={{ mb: 2 }}>
      <Typography variant="h6">
        {field?.label}
        {field.isRequired && <span>*</span>}
      </Typography>
      <Typography variant="body1" component="span">
        {field?.helpText}
      </Typography>
    </FormLabel>
  )

  const getFormField = () => (
    <Box>
      <Box
        sx={{
          minHeight: "45px",
          height: "45px",
          ...(field?.isImages && { width: "160px", margin: "auto" }),
        }}
      >
        <Controller
          control={control}
          className={field.fieldClass}
          name={field.name}
          inputRef={register(field.name)}
          render={({ field: fieldVal }) => (
            <StyledSelect
              error={!!_.get(errors, field.fieldId)}
              sx={{ width: "100%", height: field?.style?.size === "small" ? "35px" : "45px" }}
              disabled={field.disabled}
              defaultValue={field?.defaultValue}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiListItemText-root": {
                      textAlign: "left !important",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "rgba(240, 240, 240, 1)",
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "rgba(240, 240, 240, 0.5)",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "rgba(240, 240, 240, 0.5)",
                    },
                  },
                },
              }}
              multiple={field?.multiple}
              {...{
                ...fieldVal,
                value: [undefined, null].includes(fieldVal.value) ? (field.multiple ? [] : "") : fieldVal.value,
              }}
              renderValue={(val) => {
                if (field.multiple && val) {
                  return field.values
                    .filter((item) => val && val.includes(item.value))
                    .map((item) => item.label)
                    .join(", ");
                }
                if (field.isImages && val) {
                  return (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {_.get(errors, field.name)?.message && <WarningIcon sx={{ color: "#C75C57", mr: 1 }} />}
                      <img src={field.values.find((item) => item?.value === val)?.label} alt="select field option" style={{ width: "100px" }} />
                    </Box>
                  );
                }
                return (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {_.get(errors, field.name)?.message && <WarningIcon sx={{ color: "#C75C57", mr: 1 }} />}
                    {field.values.find((item) => item?.value === (val === undefined ? "" : val))?.label}
                  </Box>
                );
              }}
              displayEmpty
              autoWidth={field?.isImages}
            >
              {renderLabels(fieldVal)}
            </StyledSelect>
          )}
        />
      </Box>
      <ErrorBox message={_.get(errors, field.name)?.message} showIcon={false} />
    </Box>
  )

  return (
    <FormControl component="fieldset" sx={{ minWidth: "100%", width: "150px" }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (field?.options?.sameRow ? (
        <Grid container>
          <Grid item xs={4} sm={3} md={4} lg={4}>
            {getFormLabel()}
          </Grid>
          <Grid item xs={8} sm={9} md={8} lg={8}>
            {getFormField()}
          </Grid>
        </Grid>
      ) : (
        <Box>
          {getFormLabel()}
          {getFormField()}
        </Box>
      ))}
    </FormControl>
  );
};

SelectField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    multiple: PropTypes.bool,
    helpText: PropTypes.string,
    isImages: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldId: PropTypes.string,
    fieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.bool,
    options: PropTypes.shape({
      find: PropTypes.string,
      sameRow: PropTypes.bool,
    }),
    values: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
    })).isRequired,
    style: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SelectField;
