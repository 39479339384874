import * as yup from "yup";

export const registerSchema = yup
  .object()
  .shape({
    email: yup.string("The email is not valid.").email("Please enter a valid email.").required("Please enter your email."),
    tos: yup
      .bool("The TOS is not valid.")
      .required("Please accept our Terms of Service.")
      .test("tos-accept", "You must accept our Terms of Service to continue.", (val) => val === true),
  })
  .required();

export const formSteps = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "email",
        fieldId: "email",
        label: "Email address",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "tos",
        fieldId: "tos",
        label: "I have read and understood the privacy policy and agree to the Terms & Conditions.",
        isRequired: true,
        type: "checkbox",
        size: 12,
      },
    ],
  },
];
