import { forwardRef, useEffect, useState } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";

// eslint-disable-next-line import/no-cycle
import FormFields from "./FormFields";
import { getCurrentUserSelector } from "../../redux/users/selector";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const Form = forwardRef(
  ({ onSubmit, steps, form, startPage, isLoading, FormButtons, className, renderInputFields, options }, ref) => {
    const [step, setStep] = useState(null);
    const currentUser = useSelector(getCurrentUserSelector, shallowEqual);
    const { width } = useWindowDimensions();

    useEffect(() => {
      if (steps) {
        const currentSteps = {
          ...steps[startPage],
          fields: steps[startPage].fields.filter((field) => field?.condition === undefined || field.condition),
        };
        setStep(currentSteps);
      }
    }, [currentUser, steps, startPage]);

    return (
      <Paper
        sx={{
          background: "white",
          textAlign: "start",
          ...(!step?.mainIcon && { justifyContent: "space-around" }),
        }}
        elevation={0}
      >
        {step && (
          <Box className={className}>
            <Grid container>
              {step?.title && options?.showTitle && (
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pb: 2, textAlign: "center" }}>
                  <Typography variant="h3" sx={{ color: "#C02026" }}>
                    {step.title}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <form ref={ref} onSubmit={form.handleSubmit(onSubmit)} style={{ width: options?.width ? options.width : "100%", margin: "auto" }}>
              <Grid container sx={width > 900 && { width: "80%", margin: "auto" }}>
                <FormFields
                  form={form}
                  page={step}
                  isLoading={isLoading}
                  renderInputFields={renderInputFields}
                />
              </Grid>
              {FormButtons !== null && (
                <Box textAlign="center" margin="15px auto">
                  {FormButtons}
                </Box>
              )}
            </form>
          </Box>
        )}
      </Paper>
    );
  }
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  startPage: PropTypes.number,
  isLoading: PropTypes.bool,
  FormButtons: PropTypes.element,
  renderInputFields: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

Form.defaultProps = {
  onSubmit: () => {},
  startPage: 0,
  isLoading: false,
  FormButtons: null,
  renderInputFields: true,
  className: "",
  options: {},
};

export default Form;
