import { Box, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { IMAGE_FILE_EXTENSIONS, Roles } from "../../../utilities/constants";
import { createDocuments } from "../../../redux/documents/actions";
import Form from "../../form/Form";
import PrimaryButton from "../PrimaryButton";
import { getCurrentUserSelector } from "../../../redux/users/selector";

const createDocumentSteps = (user, location, documents) => [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "name",
        fieldId: "name",
        label: "Name",
        type: "input-text",
        condition: !location.pathname.includes("users"),
        size: 12,
      },
      {
        name: "id",
        fieldId: "document",
        label: "Document",
        type: "select",
        size: 12,
        values: [{ value: "", label: "Please select a document" }, ...documents.filter((i) => i?.isAdminDocument).map((i) => ({ value: i.id, label: i.name }))],
        condition: user.role === Roles.ADMIN.value && location.pathname.includes("users"),
        isRequired: true,
      },
      {
        name: "file",
        fieldId: "file",
        label: "File",
        defaultValue: null,
        size: 12,
        allowedFormats: ["jpg", "png", "jpeg", "pdf", "docx", "doc"],
        type: "file",
        multiple: false,
        floatRight: true,
      },
    ],
  },
];

const createDocumentSchema = (location) => yup.object().shape({
  name: yup.string("The name is not valid.").test("admin-document-name", "The name field is required.", (val) => location.pathname.includes("users") || val !== undefined),
  id: yup.number("The document is not valid.").test("admin-document-doc", "The document field is required.", (val) => !location.pathname.includes("users") || val !== undefined),
  file: yup
    .mixed()
    .nullable()
    .test(
      "check-file-type",
      `The allowed file types are: ${[...IMAGE_FILE_EXTENSIONS, "pdf", "docx", "doc"].join(", ")}.`,
      // eslint-disable-next-line no-bitwise
      (file) => !file || [...IMAGE_FILE_EXTENSIONS, "pdf", "docx", "doc"].includes(file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2))
    )
});

const CreateDocument = ({ userId, setOpen, isLoading }) => {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents.adminDocuments);
  const currentUser = useSelector(getCurrentUserSelector);
  const location = useLocation();

  const form = useForm({
    resolver: yupResolver(createDocumentSchema(location)),
    mode: "onChange",
  });

  const handleSubmit = async () => {
    await form.trigger();

    if (form.formState.isValid) {
      const formData = form.getValues();
      dispatch(createDocuments({ documents: [formData?.file ? formData : { name: formData.name }], userId }))
        .unwrap()
        .then((resp) => {
          toast.success("The document has been uploaded.", { toastId: `create-document-${resp.id}` });
          setOpen(false);
        });
    }
  };

  return (
    <Box>
      <DialogTitle>
        Upload a new job document
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {(documents || currentUser) && <Form
            steps={createDocumentSteps(currentUser, location, documents)}
            form={form}
            FormButtons={
              <Box>
                <PrimaryButton onClick={handleSubmit} loading={isLoading}>Submit</PrimaryButton>
              </Box>
            }
          />}
        </Box>
      </DialogContent>
    </Box>
  );
};

CreateDocument.propTypes = {
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userId: PropTypes.number,
};

CreateDocument.defaultProps = {
  userId: null,
}

export default CreateDocument;
