import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PromoterViewer = ({ data, zoomOut }) => (
    <Box>
      <Box sx={{ boxShadow: "0px 0px 40px -24px rgba(0,0,0,0.75)", borderRadius: "10px", padding: "20px 20px", wordWrap: "break-word" }}>
        <Box sx={{ marginBottom: "20px" }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h4" fontSize={zoomOut ? "13px" : "16px"} sx={{ color: "#C02026" }}>
              {data.firstName}&nbsp;{data.lastName}
            </Typography>
          </Box>
          <hr style={{ border: "2px solid rgb(0 0 0 / 43%)" }} />
          <Box sx={{ textAlign: 'left' }}>
            <Typography fontSize={zoomOut ? "11px" : "14px"}>Phone: {data.phone}</Typography>
            <Typography fontSize={zoomOut ? "11px" : "14px"}>
              Address:{" "}
              {data?.address && data.address?.country && data.address?.city && data.address?.zipCode &&
                data.address?.address && `${data?.address?.country}, 
                                ${data?.address?.city} ${data?.address?.zipCode}, 
                                ${data?.address?.address}`}
            </Typography>
            <Typography fontSize={zoomOut ? "11px" : "14px"}>Referral Code: {data.code}</Typography>
          </Box>
          <hr />
        </Box>
      </Box>
    </Box>
  )

PromoterViewer.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    code: PropTypes.string,
  }),
  zoomOut: PropTypes.bool,
};

PromoterViewer.defaultProps = {
  data: {
    firstName: "John",
    lastName: "Doe",
    phone: "",
    address: {
      country: "",
      city: "",
      zipCode: "",
      address: "",
    },
    code: ""
  },
  zoomOut: false,
}

export default PromoterViewer;