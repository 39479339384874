import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { DrawerWidth } from "../../utilities/constants";

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: "white",
  boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 15px 0px rgb(0 0 0 / 10%)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const styles = {
  headerGrid: { display: "flex", alignItems: "center", justifyContent: "center" },
  logoBox: { float: "left", display: "flex", alignItems: "center", justifyContent: "center" },
  logo: { width: "150px" },
  menuGrid: { marginLeft: "0", textAlign: "left", display: 'flex' },
  menuList: { padding: "70px 0", height: "100%", border: "0" },
  listItem: {
    display: "block",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  listItemBtn: {
    minHeight: 30,
    height: "40px",
    px: 2.5,
  }
}