import { useEffect, useState } from "react";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PropTypes from "prop-types";

const ProfilePicture = ({ profilePicture, zoomOut }) => {
  const [fileUrl, setFileUrl] = useState(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (profilePicture) {
      if (profilePicture instanceof File) {
        const url = URL.createObjectURL(profilePicture);
        if (fileUrl !== url) {
          setFileUrl(url);
        }
        return () => URL.revokeObjectURL(url);
      }
      setFileUrl(profilePicture?.fileUrl);
    }
  }, [profilePicture]);

  return profilePicture ?
    <img
      src={fileUrl}
      style={{ maxWidth: '100%', height: zoomOut ? '75px' : '185px' }}
      alt="profile"
    /> : <AccountBoxIcon sx={{ fontSize: '50px' }} />
}

ProfilePicture.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profilePicture: PropTypes.object,
  zoomOut: PropTypes.bool,
};

ProfilePicture.defaultProps = {
  profilePicture: null,
  zoomOut: false,
}

export default ProfilePicture;