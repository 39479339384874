import { useEffect, useState } from "react";

import { Box, Typography, Paper, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from "@mui/material/styles";

import { convertWord } from "../../../utilities";
import { createDocuments, updateDocuments } from "../../../redux/documents/actions";
import SecondaryButton from "../SecondaryButton";
import PrimaryButton from "../PrimaryButton";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  backgroundColor: 'white',
  width: 1,
});

const DocumentField = ({ document }) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (file) {
      if (document.fileUrl) {
        dispatch(updateDocuments({ documents: [{ id: document.id, file, fileId: document.fileId }]})).unwrap()
          .then(() => toast.success("The file has been updated."));
      } else {
        dispatch(createDocuments({ documents: [{ id: document.id, file }]})).unwrap()
          .then(() => toast.success("The file has been created."));
      }
      setFile(null)
    }
  }, [file])

  return (
    <Paper align="center" elevation={0} sx={{ marginBottom: '25px', marginTop: 'auto', border: '1px solid silver', borderRadius: '10px', padding: '10px 15px' }}>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6} style={{ textAlign: 'left', paddingBottom: '10px' }}>
            {document.templateUrl ? (
              <a href={document.templateUrl} rel='noreferrer' target='_blank'>
                <Typography variant='h4' style={{ textDecoration: 'underline' }}>
                  {convertWord(document.name)}
                </Typography>
              </a>
            ) : (
              <Typography variant='h4'>
                {convertWord(document.name)}
              </Typography>
            )}
          </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingBottom: '10px' }}>
              <div>
                <a href={document.downloadTemplate} target='_blank' rel='noreferrer' download>
                  <SecondaryButton disabled={!document.templateUrl}><span>Download Template</span></SecondaryButton>
                </a>
              </div>
              {document?.fileUrl && (
                <div>
                  <a
                    href={document?.fileUrl}
                    target='_blank' rel='noreferrer'
                    style={{ color: 'black', textTransform: 'uppercase', textDecoration: "underline" }}
                  >View current file</a>
                </div>
              )}
            </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <PrimaryButton component="label" variant="contained" startIcon={<CloudUploadIcon />}>
              Upload file
              <VisuallyHiddenInput value={file?.filename} onChange={(e) => setFile(e.target.files[0])} type="file" />
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

DocumentField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    downloadTemplate: PropTypes.string.isRequired,
    fileId: PropTypes.number,
    templateUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentField;
