/* eslint-disable react/forbid-prop-types,no-param-reassign */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import SecondaryButton from "./SecondaryButton";
import Form from "../form/Form";
import PrimaryButton from "./PrimaryButton";

const Filter = ({ filterSteps, filterSchema, items, setFilteredItems, shouldStartOpen }) => {
  const [hideFilters, setHideFilters] = useState(shouldStartOpen);
  const [searchParams, setSearchParams] = useSearchParams();

  const form = useForm({
    resolver: yupResolver(filterSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (Array.from(searchParams.keys()).filter((k) => k !== "_gl").length === 0) {
      setFilteredItems(items)
    }
  }, [items, searchParams]);

  const flatten = (obj, prefix = '', res = {}) =>
    Object.entries(obj).reduce((r, [key, val]) => {
      const k = prefix !== '' ? `${prefix}.${key}` : `${prefix}${key}`
      if(typeof val === 'object'){
        flatten(val, `${k}`, r)
      } else if (![null, undefined, ''].includes(val)) {
        res[k] = val
      }
      return r
    }, res)

  const handleSubmit = async () => {
    await form.trigger();
    if (Object.keys(form.formState.errors).length === 0) {
      const filterObject = flatten(form.getValues());
      setSearchParams((oldSearch) => ({ ...oldSearch, ...filterObject }))
      if (Object.keys(filterObject).length !== 0) {
        setFilteredItems(
          items.filter((i) => {
            const filterBoolResult = Object.keys(filterObject)
              .filter((filterKey) => filterObject[filterKey] !== undefined)
              .map((filterKey) => {
                if (typeof _.get(filterObject, filterKey) === 'boolean') {
                  return _.get(i, filterKey) === _.get(filterObject, filterKey)
                }
                
                if (/\.[0-9]+/.test(filterKey)) {
                  // eslint-disable-next-line prefer-destructuring
                  const tempFilterKey = filterKey.split(".")[0];
                  filterObject[tempFilterKey] = filterObject?.tempFilterKey ? [...filterObject.tempFilterKey, filterObject[filterKey]] : [filterObject[filterKey]];
                  delete filterObject[filterKey];
                  filterKey = tempFilterKey;
                }

                const searchableFieldName = filterSteps[0].fields.find((innerI) => innerI?.searchableFieldName && innerI.name === filterKey)?.searchableFieldName;
                const objectFilterKey = _.get(i, searchableFieldName || filterKey);
                
                if (!objectFilterKey) {
                  return false;
                }
                if (_.get(filterObject, filterKey) instanceof Date) {
                  if (filterKey.includes("startDate")) {
                    return objectFilterKey > _.get(filterObject, filterKey)
                  }
                  return objectFilterKey < _.get(filterObject, filterKey)
                }
                if (Array.isArray(objectFilterKey)) {
                  return _.get(filterObject, filterKey).every(element => objectFilterKey.includes(element));
                }

                return objectFilterKey.toLowerCase().includes(_.get(filterObject, filterKey).toLowerCase())
              });
              return filterBoolResult.every((isTrue) => isTrue);
            })
        );
      } else {
        setFilteredItems(items);
      }
    }
  };

  useEffect(() => {
    const queryParams = Object.fromEntries(searchParams.entries());
    Object.keys(queryParams).filter((k) => k !== "_gl").forEach((k) => {
      form.setValue(k, queryParams[k]);
    })
    handleSubmit();
  }, []);


  useEffect(() => {
    const subscription = form.watch(() => setTimeout(() => handleSubmit(), 600));
    return () => subscription.unsubscribe();
  }, [items, form.watch]);

  return (
    <Box>
      <Box>
        <Box textAlign="right" marginBottom="20px">
          <SecondaryButton onClick={() => setHideFilters((val) => !val)}>
            <span>{hideFilters ? "Hide Filters" : "Show Filters"}</span>
          </SecondaryButton>
        </Box>
      </Box>
      <Collapse orientation="vertical" in={hideFilters}>
        <Form
          steps={filterSteps}
          form={form}
          onSubmit={handleSubmit}
          FormButtons={
            <Grid container sx={{ width: "40%", margin: "auto" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "15px", fontWeight: "400", cursor: "pointer" }}
                  onClick={async () => {
                    await form.reset();
                    setFilteredItems(items);
                  }}
                >
                  Reset all filters
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginLeft: "auto" }}>
                <PrimaryButton type="submit">Search</PrimaryButton>
              </Grid>
            </Grid>
          }
        />
      </Collapse>
    </Box>
  );
};

Filter.propTypes = {
  items: PropTypes.array,
  setFilteredItems: PropTypes.func.isRequired,
  filterSchema: PropTypes.object.isRequired,
  filterSteps: PropTypes.array.isRequired,
  shouldStartOpen: PropTypes.bool,
};

Filter.defaultProps = {
  items: [],
  shouldStartOpen: false,
};

export default Filter;
