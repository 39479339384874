export const getDocumentsForUser = (userId) => (state) => {
  if (userId && state.documents.userDocuments) {
    // eslint-disable-next-line eqeqeq
    const documents = state.documents.userDocuments.filter((i) => i.userId == userId);
    return {
      data: documents,
      loading: state.documents.loading,
    };
  }
  return null;
};
