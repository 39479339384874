import { Box, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import PrimaryButton from "../../../../components/shared/PrimaryButton";

const JobApplicationModal = ({ setOpen, jobTitle }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <DialogTitle>
        Job Application - {jobTitle}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6">
            You must first finish your verification in order to apply for a job.
          </Typography>
          <Box mt={2}>
            <PrimaryButton onClick={() => navigate("/verification")}>Verify</PrimaryButton>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );
};

JobApplicationModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  jobTitle: PropTypes.string.isRequired,
};

export default JobApplicationModal;
