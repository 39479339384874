import { useRef } from "react";

import { Box, FormControl, FormLabel, InputAdornment, Skeleton, TextField, Typography } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";
import { Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import ErrorBox from "../shared/ErrorBox";
import SecondaryButton from "../shared/SecondaryButton";

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ error }) => ({
  backgroundColor: "white",
  borderRadius: "6px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid silver",
    },
    "&:hover fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid black",
    },
    "&.Mui-focused fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid black",
    },
  },
  "& input": {
    color: error ? "rgba(171, 63, 58, 1)" : "rgba(66, 66, 66, 1)",
    fontFamily: "Poppins",
    fontWeight: "500",
    height: "10px !important",
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const TagsTextField = ({ field, register, control, setValue, errors, isLoading }) => {
  const inputRef = useRef();

  const handleAdd = (value, onChange) => {
      if (!value.includes(inputRef.current.value) && inputRef.current.value !== "") {
        const newTags = [...value, inputRef.current.value];
        inputRef.current.value = "";
        setValue(field.name, newTags);
        onChange(newTags);
    }
  }

  return (
    <FormControl component="fieldset" sx={{ minWidth: "100%", width: "100%" }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (
        <>
          <FormLabel component="legend" sx={{ mb: 2, ...(field.label === "" && { minHeight: "20px" }) }}>
            <Typography variant="h6">
              {field?.label}
              &nbsp;
              {field?.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </FormLabel>
          <Controller
            control={control}
            id={field.fieldId}
            defaultValue={[]}
            className={field.fieldClass}
            name={field.name}
            inputRef={register(field.name)}
            render={({ field: { onChange, value, ...rest } }) => (
              <Box overflow="none">
                <StyledTextField
                  id={field?.name}
                  className={field?.fieldClass}
                  name={field?.name}
                  size="medium"
                  inputRef={inputRef}
                  placeholder={field?.placeholder}
                  {...rest}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleAdd(value, onChange)
                    }
                  }}
                  InputLabelProps={{
                    min: 0,
                    shrink: true,
                  }}
                  minRows={field.type === "input-text-area" ? 5 : 1}
                  multiline={field.type === "input-text-area"}
                  disabled={field?.disabled}
                  type="text"
                  fullWidth
                  error={!!errors[field.fieldId]}
                  InputProps={{
                    startAdornment: _.get(errors, field.name)?.message && (
                      <InputAdornment position="end" sx={{ paddingRight: "7px" }}>
                        <WarningIcon sx={{ color: "#C75C57" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Box p={1}>
                        <SecondaryButton sx={{ padding: "5px 10px" }} onClick={() => handleAdd(value, onChange)}><ArrowUpwardIcon /></SecondaryButton>
                      </Box>
                    )
                  }}
                />
                <Box mt={2}>
                  <ul>
                    {value?.map((item) => (
                      <Box key={item}>
                        <li style={{ wordWrap: "break-word", whiteSpace: "normal", paddingRight: "5px" }} key={item}>
                          {item}
                          <CloseIcon onClick={() => setValue(field.name, value.filter((k) => item !== k))} />
                        </li>
                      </Box>
                    ))}
                  </ul>
                </Box>
              </Box>
            )}
          />
          <ErrorBox message={_.get(errors, field.name)?.message} showIcon={false} />
        </>
      )}
    </FormControl>
  );
}

TagsTextField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    multiple: PropTypes.bool,
    helpText: PropTypes.string,
    isImages: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldId: PropTypes.string,
    fieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
    })).isRequired,
    style: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TagsTextField;
