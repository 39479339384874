import { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Link } from "react-router-dom";

import { getNotifications } from "../../../redux/notifications/actions";
import Table from "../../../components/shared/Table";
import Filter from "../../../components/shared/Filter";
import { NotificationTypes, Roles } from "../../../utilities/constants";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import SecondaryButton from "../../../components/shared/SecondaryButton";

export const filterFormSchema = yup.object().shape({
  notificationType: yup.string("The notification type is not valid."),
});

export const filterFormFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "notificationType",
        fieldId: "notificationType",
        label: "Notification Type",
        type: "select",
        style: {
          padding: "0 10px",
          size: "small",
        },
        values: [{ value: "", label: "Please select a type" }, ...NotificationTypes.asList()],
        isRequired: false,
        size: 4,
      },
    ],
  },
];

const renderNotificationButtons = (row) => (
  <Grid container>
    {row.context?.userId && (
      <Grid item style={{ padding: '0 5px 5px 0' }}>
        <Link to={`/users/${row.context?.userId}`}><SecondaryButton>User</SecondaryButton></Link>
      </Grid>
    )}
    {row.context?.jobId && (
      <Grid item>
        <Link to={`/jobs/${row.context?.jobId}`}><SecondaryButton>Job</SecondaryButton></Link>
      </Grid>
    )}
    {row.context?.companyId && (
      <Grid item>
        <Link to={`/users/${row.context?.companyId}`}><SecondaryButton>Company</SecondaryButton></Link>
      </Grid>
    )}
  </Grid>
)

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const currentUser = useSelector(getCurrentUserSelector);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    if (notifications?.data && currentUser.role !== Roles.ADMIN.value) {
      setFilteredNotifications(notifications.data)
    }
  }, [notifications]);

  const columns = [
    {
      id: "id",
      label: "ID",
      props: {
        align: "center",
      },
    },
    {
      id: "content",
      label: "Title",
      value: (row) => row?.content,
      props: {
        align: "center",
      },
    },
    {
      id: "notificationType",
      label: "Type",
      value: (row) => NotificationTypes.getLabel(row?.notificationType),
      props: {
        align: "center",
      },
    },
    ...(currentUser?.role === Roles.ADMIN.value ? [{
      id: "actions",
      label: "Actions",
      value: renderNotificationButtons,
      props: {
        align: "center",
      },
    }] : []),
    {
      id: "createdAt",
      label: "Date",
      value: (row) => new Date(row?.createdAt).toLocaleString('en-GB'),
      props: {
        align: "center",
      },
    },
  ];

  return (
    <Box>
      {currentUser.role === Roles.ADMIN.value && <Filter items={notifications.data || []} setFilteredItems={setFilteredNotifications} filterSteps={filterFormFields} filterSchema={filterFormSchema} />}
      <Table
        columns={columns}
        data={filteredNotifications}
        isLoading={notifications.loading}
        options={{
          shortenContent: false,
        }}
        noResultsContent="There are no notifications."
      />
    </Box>
  );
};

export default Notifications;
