import { useEffect } from "react";

import { Box, Tab, Grid, Tabs } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const CustomTabs = ({ tabs, header, defaultTab }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has("tab")) {
      navigate(`?tab=${defaultTab}`);
    }
  }, [searchParams]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container>
          <Grid item md={5} lg={5}>
            <Tabs
              value={tabs.findIndex((tabItem) => tabItem.value === searchParams.get("tab")) || 0}
              sx={{ "& :hover": { border: "none" }, "& .Mui-selected": { color: "#C02026 !important" } }}
              TabIndicatorProps={{ style: { backgroundColor: "#C02026" } }}
            >
              {tabs.map((i, index) => (
                <Tab key={i.label} label={i.label} value={index} onClick={i.onClick} disabled={i?.disabled} />
              ))}
            </Tabs>
          </Grid>
          <Grid item md={7} lg={7} textAlign="right">
            {header}
          </Grid>
        </Grid>
      </Box>
      {tabs.map((i, index) => (
        <TabPanel key={i.value} value={tabs.findIndex((tabItem) => tabItem.value === searchParams.get("tab")) || 0} index={index}>
          {i.component}
        </TabPanel>
      ))}
    </Box>
  );
};

CustomTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array.isRequired,
  header: PropTypes.element,
  defaultTab: PropTypes.string.isRequired,
};

CustomTabs.defaultProps = {
  header: null,
};

export default CustomTabs;
