/* eslint-disable no-bitwise */
import _ from "lodash";
import dayjs from "dayjs";

import {
  DrivingLicenses,
  hobbies,
  IMAGE_FILE_EXTENSIONS, INDUSTRIES, JobIndustries,
  LanguageLevels,
  languages, Roles,
  Skills
} from "../../../utilities/constants";
import yup from "../../../utilities/yup";

const WorkExperienceSchema = yup.object().shape({
  jobTitle: yup.string("The job title field is not valid."),
  employer: yup.string("The employer field is not valid.").required("The employer field is required."),
  industry: yup.string("The industry field is not valid.").required("The industry field is required."),
  startDate: yup
    .string()
    .test("start-date-validation", "The start date must be in the past.", (val) => !val || (dayjs.isDayjs(val) ? val : dayjs(val)).isBefore(dayjs()))
    .default(null)
    .required("The start date field is required.")
    .nullable(),
  endDate: yup.string()
    .default(null).nullable()
    .test({
      name: 'conditional-required',
      exclusive: false,
      message: 'The end date field is required.',
      test () {
        const { endDate, isPresent } = this.parent;
        return endDate || isPresent;
      },
    }),
  city: yup.string("The city field is not valid.").required("The city field is required."),
  description: yup.string("The description field is not valid."),
  isPresent: yup.boolean().typeError("The is present field is not valid."),
})

const EducationSchema = yup.object().shape({
  institutionName: yup.string("The institution name field is not valid."),
  // .required("Please enter an institution name."),
  degree: yup.string("The degree field is not valid."),
  // .required("Please enter a degree."),
  startDate: yup
    .string()
    // .required("The start date is required.")
    .test("start-date-validation", "The start date must be in the past.", (val) => !val || (dayjs.isDayjs(val) ? val : dayjs(val)).isBefore(dayjs()))
    .nullable()
    .default(null),
  endDate: yup
    .string()
    .nullable()
    .default(null)
    .test({
      name: 'conditional-required',
      exclusive: false,
      message: 'The end date field is required.',
      test () {
        const { startDate, isPresent } = this.parent;
        return startDate || isPresent;
      },
    }),
  city: yup.string("The city field is not valid."),
  // .required("Please enter a city."),
  isPresent: yup.boolean().typeError("The is present field is not valid."),
})

export const profileVerificationSchema = (user) => yup.object().shape({
  email: yup.string("The email field is not valid.").required("The email field is required."),
  firstName: yup.string("The first name field is not valid.").required("The first name field is required."),
  lastName: yup.string("The last name field is not valid.").required("The last name field is required."),
  jobTitle: yup.string("The jobTitle field is not valid."),
    // .required("The job title field is required."),
  summary: yup.string("The summary field is not valid."),
    // .required("The profile summary field is required."),
  dateOfBirth: yup
    .string().required("The date of birth is required.")
    .test("dob-validation", "The date of birth must be in the past.", (val) => dayjs(val).isBefore(dayjs())),
  profilePhoto: yup
    .mixed()
    .test(
      "photo-required",
      "The profile photo is required.",
      (val) => val && (!user || user.role !== Roles.ADMIN.value),
    )
    .test(
      "check-file-type",
      `The allowed file types are: ${IMAGE_FILE_EXTENSIONS.join(", ")}.`,
      (file) => !file || (file.length !== 0 && IMAGE_FILE_EXTENSIONS.includes(file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)))
    ),
  nationality: yup.string("The nationality is not valid."),
    // .required("The nationality field is required."),
  profession: yup.string("The profession is not valid."),
    // .required("The profession field is required."),
  address: yup.object().shape({
    country: yup.string("The country is not valid.").trim().required("Please enter your country."),
    city: yup.string("The city is not valid.").trim(),
      // .required("Please enter your city."),
    address: yup.string("The address is not valid.").trim(),
      // .required("Please enter your address."),
    zipCode: yup
      .string("The zip code is not valid.")
      .trim()
      // .min(4, "The ZIP code must be at least 4 characters long.")
      // .required("Please enter your zip code.")
      // .matches(/^[0-9]+$/, "The zip code must only contain numbers.")
      .nullable()
      .default(null),
  }),
  phone: yup.string("The phone is not valid.").trim().required("Please enter your phone."),
  skills: yup.array(),
  languages: yup.array().required('You must add at least one language.').strict().min(1, "You must add at least one language."),
  forkLiftLicense: yup.boolean(),
  drivingLicenses: yup.array(),
  hobbies: yup.array(),
  socialLinks: yup.array(),
  education: yup
    .array()
    .of(EducationSchema)
    .strict(),
  certificates: yup
    .array()
    .of(
      yup.object().shape({
        institutionName: yup.string("The institution name field is not valid."),
        // .required("Please enter an institution name."),
        certificateName: yup.string("The certificate name field is not valid."),
        // .required("Please enter a certificate name."),
      })
    ),
  workExperience: yup
    .array()
    .of(WorkExperienceSchema)
    .strict()
    .required("You must enter at least one work experience.")
    .min(1, "You must enter at least one work experience."),
  bankName: yup.string("The bank name is not valid.").trim(),
  iban: yup.string("The IBAN is not valid.").trim(),
  swift: yup.string("The SWIFT is not valid.").trim(),
  shoeSize: yup.number().typeError("The shoe size is not valid.").required("The shoe size field is required."),
  pantsSize: yup.number().typeError("The pants size is not valid.").required("The pants size field is required."),
  shirtSize: yup.string("The shirt size is not valid.").required("The shirt size field is required.")
});

export const profileVerificationSteps = (user) => [
  {
    stepNo: 0,
    title: "Information about Posrednik24",
    fields: [
      {
        name: "notice",
        fieldId: "notice",
        fieldClass: "normal banner notice",
        contents: [
          {
            id: 0,
            type: "h3",
            title: "Welcome to Posrednik24!",
          },
          {
            id: 1,
            type: "p",
            content:
              "Please fill out the form in order to verify on our platform and get access to " +
              " 100+ active job ads in Germany and Croatia. \n PS: You can leave the form whenever you want " +
              "and come back at a later time. All of your information will be saved!",
          },
          {
            id: 2,
            type: "p",
            content: [
              {
                value: "Are you having trouble with the form?",
              },
              {
                props: {
                  sx: { fontWeight: "bold" },
                },
                value: "Please email us directly at office@posrednik24.com.",
              },
            ],
          },
        ],
        type: "banner",
      },
    ],
  },
  {
    stepNo: 1,
    title: "Personal Information",
    fields: [
      {
        name: "email",
        fieldId: "Email",
        label: "Email",
        type: "input-text",
        isRequired: true,
        options: {
          sameRow: true,
        },
        disabled: !!user,
        size: 12,
      },
      {
        name: "profilePhoto",
        fieldId: "profilePhoto",
        label: "Profile Photo",
        options: {
          size: "small",
          sameRow: true,
        },
        allowedFormats: ["jpg", "png", "jpeg"],
        type: "file",
        multiple: false,
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "firstName",
        fieldId: "firstName",
        label: "First name",
        type: "input-text",
        isRequired: true,
        options: {
          sameRow: true,
        },
        size: 12,
      },
      {
        name: "lastName",
        fieldId: "lastName",
        label: "Last name",
        type: "input-text",
        floatRight: true,
        options: {
          sameRow: true,
        },
        isRequired: true,
        size: 12,
      },
      {
        name: "nationality",
        fieldId: "nationality",
        label: "Nationality",
        type: "input-text",
        options: {
          sameRow: true,
        },
        floatRight: true,
        // isRequired: true,
        size: 12,
      },
      {
        name: "dateOfBirth",
        fieldId: "dob",
        placeholder: "dd/mm/yyyy",
        label: "Date of birth",
        type: "input-date",
        options: {
          sameRow: true,
        },
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "profession",
        fieldId: "profession",
        label: "Profession",
        type: "input-text",
        options: {
          sameRow: true,
        },
        floatRight: true,
        // isRequired: true,
        size: 12,
      },
      {
        name: "phone",
        fieldId: "phone",
        label: "Phone",
        type: "input-tel",
        options: {
          sameRow: true,
        },
        isRequired: true,
        size: 12,
      },
      {
        name: "address.address",
        fieldId: "address.address",
        label: "Address",
        type: "input-text",
        options: {
          sameRow: true,
        },
        // isRequired: true,
        floatRight: true,
        size: 12,
      },
      {
        name: "address.zipCode",
        fieldId: "address.zipCode",
        label: "Zip Code",
        type: "input-text",
        options: {
          sameRow: true,
        },
        // isRequired: true,
        size: 12,
      },
      {
        name: "address.city",
        fieldId: "address.city",
        label: "City",
        type: "input-text",
        options: {
          sameRow: true,
        },
        floatRight: true,
        // isRequired: true,
        size: 12,
      },
      {
        name: "address.country",
        fieldId: "address.country",
        label: "Country",
        options: {
          sameRow: true,
        },
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "summary",
        fieldId: "summary",
        label: "Professional summary",
        type: "input-text-area",
        options: {
          sameRow: true,
        },
        // isRequired: true,
        size: 12,
      },
    ],
  },
  {
    stepNo: 2,
    title: "Education & Certificates",
    name: "education",
    multiple: [
      {
        placeholder: "Education",
        name: "education",
        placeholderValues: ["institutionName", "degree"],
        schema: EducationSchema,
        startOpened: true,
        defaultValues: {
          institutionName: "",
          degree: "",
          startDate: null,
          endDate: null,
          isPresent: false,
          city: "",
        },
      },
      {
        placeholder: "Certificate",
        name: "certificates",
        placeholderValues: ["institutionName", "certificateName"],
        schema: yup.object().shape({
          institutionName: yup.string("The institution name field is not valid."),
            // .required("Please enter an institution name."),
          certificateName: yup.string("The certificate name field is not valid."),
            // .required("Please enter a certificate name."),
        }),
        defaultValues: {
          institutionName: "",
          certificateName: "",
        },
      },
    ],
    fields: [
      [
        {
          name: "institutionName",
          fieldId: "institutionName",
          label: "Institution name",
          type: "input-text",
          options: {
            sameRow: true,
          },
          size: 12,
        },
        {
          name: "city",
          fieldId: "city",
          label: "City",
          options: {
            sameRow: true,
          },
          type: "input-text",
          size: 12,
        },
        {
          name: "degree",
          fieldId: "degree",
          label: "Degree Name",
          type: "input-text",
          options: {
            sameRow: true,
          },
          floatRight: true,
          size: 12,
        },
        {
          name: "startDate",
          fieldId: "startDate",
          label: "Start Date",
          disableFuture: true,
          options: {
            sameRow: true,
          },
          type: "input-date",
          size: 12,
        },
        {
          name: "endDate",
          fieldId: "endDate",
          label: "End Date",
          options: {
            sameRow: true,
          },
          type: "input-date",
          disabledDepends: "isPresent",
          disabled: (value, setValue) => {
            if (value) {
              setValue('endDate', null);
            }
            return value
          },
          floatRight: true,
          size: 10,
        },
        {
          name: "isPresent",
          fieldId: "isPresent",
          label: "Present",
          options: {
            sameRow: true,
          },
          type: "checkbox",
          defaultValue: false,
          floatRight: true,
          size: 2,
        },
      ],
      [
        {
          name: "institutionName",
          fieldId: "institutionName",
          label: "Institution name",
          options: {
            sameRow: true,
          },
          type: "input-text",
          // isRequired: true,
          size: 12,
        },
        {
          name: "certificateName",
          fieldId: "certificateName",
          label: "Certificate Name",
          options: {
            sameRow: true,
          },
          type: "input-text",
          floatRight: true,
          // isRequired: true,
          size: 12,
        },
      ],
    ],
  },
  {
    stepNo: 3,
    title: "Work Experience",
    name: "workExperience",
    multiple: [
      {
        placeholder: "Work Experience",
        name: "workExperience",
        schema: WorkExperienceSchema,
        placeholderValues: ["jobTitle", "employer"],
        startOpened: true,
        defaultValues: {
          jobTitle: "",
          employer: "",
          startDate: null,
          endDate: null,
          city: "",
          description: "",
          isPresent: false,
        },
      },
    ],
    fields: [
      [
        {
          name: "jobTitle",
          fieldId: "jobTitle",
          label: "Job Title",
          options: {
            sameRow: true,
          },
          type: "input-text",
          size: 12,
        },
        {
          name: "employer",
          fieldId: "employer",
          label: "Employer",
          type: "input-text",
          options: {
            sameRow: true,
          },
          floatRight: true,
          size: 12,
          isRequired: true,
        },
        {
          name: "industry",
          fieldId: "industry",
          label: "Industry",
          type: "select",
          values: [{ value: "", label: "Please select an industry" }, ...INDUSTRIES.asList()],
          options: {
            sameRow: true,
          },
          size: 12,
          isRequired: true,
        },
        {
          name: "startDate",
          fieldId: "startDate",
          disableFuture: true,
          label: "Start Date",
          type: "input-date",
          options: {
            sameRow: true,
          },
          size: 12,
          isRequired: true,
        },
        {
          name: "endDate",
          fieldId: "endDate",
          label: "End Date",
          options: {
            sameRow: true,
          },
          type: "input-date",
          floatRight: true,
          disabledDepends: "isPresent",
          disabled: (values, setValue) => {
            if (values?.endDate !== null && values?.isPresent) {
              setValue('endDate', null);
            }
            return values?.isPresent
          },
          size: 10,
          isRequired: true,
        },
        {
          name: "isPresent",
          fieldId: "isPresent",
          label: "Present",
          options: {
            sameRow: true,
          },
          type: "checkbox",
          defaultValue: false,
          floatRight: true,
          size: 2,
        },
        {
          name: "city",
          fieldId: "city",
          label: "City",
          type: "input-text",
          options: {
            sameRow: true,
          },
          isRequired: true,
          size: 12,
        },
        {
          name: "description",
          fieldId: "description",
          label: "Description",
          type: "input-text-area",
          options: {
            sameRow: true,
          },
          size: 12,
        },
      ],
    ],
  },
  {
    stepNo: 4,
    title: "Skills",
    name: "socialLinks",
    multiple: [
      {
        placeholder: "Language",
        name: "languages",
        placeholderValues: ["language", "languageLevel"],
        schema: yup.object().shape({
          language: yup.string("The language field is not valid.").required("The language field is required."),
          languageLevel: yup.string("The language level field is not valid.").required("The language level field is required."),
        }),
        startOpened: true,
        defaultValues: {
          language: "",
          languageLevel: "",
        },
      },
      {
        placeholder: "Social Website",
        name: "socialLinks",
        placeholderValues: ["socialLinkName"],
        schema: yup.array().nullable(),
        startOpened: true,
        defaultValues: {
          socialLinksName: "",
          socialLinksValue: "",
        },
      },
    ],
    fields: [
      {
        name: "drivingLicenses",
        fieldId: "drivingLicenses",
        label: "Do you own a driving license?",
        type: "select",
        multiple: true,
        floatRight: true,
        values: [ { label: "", value: "Select an option..." }, ...DrivingLicenses.asList()],
        size: 5,
      },
      {
        name: "forkLiftLicense",
        fieldId: "forkLiftLicense",
        label: "Do you own a forklift license?",
        type: "checkbox",
        floatRight: true,
        size: 6,
      },
      [
        {
          name: "language",
          label: "Language",
          type: "select",
          values: languages.map((i) => ({ label: i.name, value: i.name })),
          options: {
            sameRow: true,
          },
          separateRow: true,
          size: 12,
          isRequired: true,
        },
        {
          name: "languageLevel",
          label: "Language Level",
          type: "select",
          values: LanguageLevels.map((i) => ({ label: i, value: i })),
          options: {
            sameRow: true,
          },
          separateRow: true,
          isRequired: true,
          size: 12,
        },
      ],
      {
        name: "skills",
        fieldId: "skills",
        label: "Skills",
        type: "select",
        options: {
          sameRow: true,
        },
        multiple: true,
        values: Skills.map((i) => ({ label: i, value: i })),
        size: 12,
      },
      {
        name: "hobbies",
        fieldId: "hobbies",
        label: "Hobbies",
        type: "select",
        options: {
          sameRow: true,
        },
        multiple: true,
        values: hobbies.map((i) => ({ label: i, value: i })),
        size: 12,
      },
      [
        {
          name: "socialLinkName",
          fieldId: "socialLinkName",
          label: "Website/Social Media Name",
          type: "input-text",
          separateRow: true,
          options: {
            sameRow: true,
          },
          size: 12,
        },
        {
          name: "socialLinkValue",
          fieldId: "socialLinkValue",
          label: "Websites/Social Media Link",
          type: "input-text",
          options: {
            sameRow: true,
          },
          separateRow: true,
          size: 12,
        },
      ],
    ],
  },
    ...(user ? [{
    stepNo: 5,
    title: "Bank Details",
    fields: [
      {
        name: "idNumber",
        fieldId: "idNumber",
        label: "ID/Tax Number",
        type: "input-text",
        placeholder: "12345678",
        floatRight: true,
        size: 12,
      },
      {
        name: 'empty',
        label: '',
        type: "empty",
        fieldId: 'blank-1',
        size: 12,
      },
      {
        name: "bankName",
        fieldId: "bankName",
        label: "Bank Name",
        type: "input-text",
        placeholder: "ACME Bank",
        floatRight: true,
        size: 12,
      },
      {
        name: "iban",
        fieldId: "iban",
        label: "IBAN",
        type: "input-text",
        placeholder: "MK07200002785123453",
        size: 12,
      },
      {
        name: "swift",
        fieldId: "swift",
        label: "SWIFT",
        placeholder: "AAAA-BB-CC-123",
        type: "input-text",
        floatRight: true,
        size: 12,
      },
    ],
  }] : []),
    ...(user ? [{
    stepNo: 6,
    title: "Job Equipment",
    fields: [
      {
        name: "shoeSize",
        fieldId: "shoeSize",
        label: "Shoe Size",
        type: "select",
        values: _.range(35, 48).map((i) => ({ value: i, label: i })),
        size: 12,
        isRequired: true,
      },
      {
        name: "shirtSize",
        fieldId: "shirtSize",
        label: "Shirt Size",
        type: "select",
        values: ["XS", "S", "M", "L", "XL", "XXL"].map((i) => ({ value: i, label: i })),
        floatRight: true,
        size: 12,
        isRequired: true,
      },
      {
        name: "pantsSize",
        fieldId: "pantsSize",
        label: "Pants Size",
        type: "select",
        values: _.range(35, 45).map((i) => ({ value: i, label: i })),
        size: 12,
        isRequired: true,
      },
    ],
  }] : []),
];

export const promoterVerificationSchema = yup
  .object()
  .shape({
    firstName: yup.string("The first name field is not valid.").required("The first name field is required."),
    lastName: yup.string("The last name field is not valid.").required("The last name field is required."),
    address: yup.object().shape({
      country: yup.string("The country is not valid.").trim().required("Please enter your country."),
      city: yup.string("The city is not valid.").trim().required("Please enter your city."),
      address: yup.string("The address is not valid.").trim().required("Please enter your address."),
      zipCode: yup
        .string("The zip code is not valid.")
        .trim()
        .min(4, "The ZIP code must be at least 4 characters long.")
        .required("Please enter your zip code.")
        .matches(/^[0-9]+$/, "The zip code must only contain numbers."),
    }),
    phone: yup.string("The phone is not valid.").trim().required("Please enter your phone."),
    code: yup
      .string("The code is not valid.")
      .required("The referral code is required.")
      .test("uppercase", "The code must be in all uppercase characters, and only contain letters, numbers, @ and !.", (val) => /^[A-Z0-9@!].{5}$/.test(val))
      .test("len", "The code must be exactly 6 characters.", (val) => val && val.length === 6),
  })
  .required()

export const promoterVerificationSteps = [
  {
    stepNo: 0,
    fields: [
      {
        name: "notice",
        fieldId: "notice",
        fieldClass: "normal banner notice",
        contents: [
          {
            id: 0,
            type: "h3",
            title: "Welcome to Posrednik24!",
          },
          {
            id: 1,
            type: "p",
            content:
              "Please fill out the form in order to verify on our platform. PS: You can leave the form whenever you want " +
              "and come back at a later time. All of your information will be saved!",
          },
          {
            id: 2,
            type: "p",
            content: [
              {
                value: "Are you having trouble with the form?",
              },
              {
                props: {
                  sx: { fontWeight: "bold" },
                },
                value: "Please email us directly at office@posrednik24.com.",
              },
            ],
          },
        ],
        type: "banner",
      },
    ],
  },
  {
    stepNo: 1,
    title: "Personal Information",
    fields: [
      {
        name: "email",
        fieldId: "Email",
        label: "Email",
        type: "input-text",
        isRequired: true,
        disabled: true,
        size: 12,
      },
      {
        name: "firstName",
        fieldId: "firstName",
        label: "First name",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "lastName",
        fieldId: "lastName",
        label: "Last name",
        type: "input-text",
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "phone",
        fieldId: "phone",
        label: "Phone",
        type: "input-tel",
        isRequired: true,
        size: 12,
      },
      {
        name: "address.country",
        fieldId: "address.country",
        label: "Country",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "address.city",
        fieldId: "address.city",
        label: "City",
        type: "input-text",
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "address.zipCode",
        fieldId: "address.zipCode",
        label: "Zip Code",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "address.address",
        fieldId: "address.address",
        label: "Address",
        type: "input-text",
        isRequired: true,
        floatRight: true,
        size: 12,
      },
    ],
  },
  {
    stepNo: 2,
    title: "Referral Code",
    fields: [
      {
        name: "code",
        fieldId: "code",
        fieldClass: "normal",
        label: "Referral Code",
        type: "input-text",
        placeholder: 'W&T24',
        helpText: "This needs to be a 6 letter word that can not be changed.",
        size: 12,
      },
    ]
  }
]

export const companyVerificationSteps = [
  {
    stepNo: 0,
    fields: [
      {
        name: "notice",
        fieldId: "notice",
        fieldClass: "normal banner notice",
        contents: [
          {
            id: 0,
            type: "h3",
            title: "Welcome to Posrednik24!",
          },
          {
            id: 1,
            type: "p",
            content:
              "Please fill out the form in order to verify on our platform and get access to " +
              " a large number of users. \n PS: You can leave the form whenever you want " +
              "and come back at a later time. All of your information will be saved!",
          },
          {
            id: 2,
            type: "p",
            content: [
              {
                value: "Are you having trouble with the form?",
              },
              {
                props: {
                  sx: { fontWeight: "bold" },
                },
                value: "Please email us directly at office@posrednik24.com.",
              },
            ],
          },
        ],
        type: "banner",
      },
    ],
  },
  {
    stepNo: 1,
    title: "Company Details",
    fields: [
      {
        name: "email",
        fieldId: "Email",
        label: "Email",
        type: "input-text",
        isRequired: true,
        disabled: true,
        size: 12,
      },
      {
        name: "name",
        fieldId: "name",
        label: "Company Name",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "registeredName",
        fieldId: "registeredName",
        label: "Registered Name",
        type: "input-text",
        isRequired: false,
        size: 12,
      },
      {
        name: "phone",
        fieldId: "phone",
        label: "Phone",
        type: "input-tel",
        isRequired: true,
        size: 12,
      },
      {
        name: "companyLogo",
        fieldId: "companyLogo",
        label: "Company Logo",
        options: {
          size: "small",
        },
        allowedFormats: ["jpg", "png", "jpeg"],
        type: "file",
        multiple: false,
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "industry",
        fieldId: "industry",
        label: "Industry",
        type: "select",
        values: [{ label: "Select company industry...", value: "" }, ...JobIndustries.asList()],
        isRequired: true,
        size: 12,
      },
      {
        name: "taxNumber",
        fieldId: "taxNumber",
        label: "Tax Number",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "address.address",
        fieldId: "address.address",
        label: "Address",
        type: "input-text",
        isRequired: true,
        size: 5,
      },
      {
        name: "address.zipCode",
        fieldId: "address.zipCode",
        label: "ZIP Code",
        type: "input-text",
        isRequired: true,
        floatRight: true,
        size: 5,
      },
      {
        name: "address.city",
        fieldId: "address.city",
        label: "City",
        type: "input-text",
        isRequired: true,
        size: 5,
      },
      {
        name: "address.country",
        fieldId: "address.country",
        label: "Country",
        type: "input-text",
        floatRight: true,
        isRequired: true,
        size: 5,
      },
      {
        buttonText: 'additional address',
        showable: true,
        fields: [
          {
            name: "additionalAddress.address",
            fieldId: "additionalAddress.address",
            label: "Additional Address",
            type: "input-text",
            isRequired: false,
            size: 5,
          },
          {
            name: "additionalAddress.zipCode",
            fieldId: "additionalAddress.zipCode",
            label: "Additional ZIP Code",
            type: "input-text",
            isRequired: false,
            floatRight: true,
            size: 5,
          },
          {
            name: "additionalAddress.city",
            fieldId: "additionalAddress.city",
            label: "Additional City",
            type: "input-text",
            isRequired: false,
            size: 5,
          },
          {
            name: "additionalAddress.country",
            fieldId: "additionalAddress.country",
            label: "Additional Country",
            type: "input-text",
            floatRight: true,
            isRequired: false,
            size: 5,
          },
        ]
      }
    ],
  },
  {
    stepNo: 2,
    title: "Contact Person Information",
    fields: [
      {
        name: "contactPersonEmail",
        fieldId: "contactPersonEmail",
        label: "E-mail",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "contactPersonName",
        fieldId: "contactPersonName",
        label: "First name",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "contactPersonSurname",
        fieldId: "contactPersonSurname",
        label: "Last name",
        type: "input-text",
        floatRight: true,
        isRequired: true,
        size: 12,
      },
      {
        name: "contactPersonPhone",
        fieldId: "contactPersonPhone",
        label: "Phone",
        type: "input-tel",
        isRequired: true,
        size: 12,
      },
    ],
  },
];

export const companyVerificationSchema = yup.object().shape({
  name: yup.string("The name is not valid.").trim().required("The name is required."),
  registeredName: yup.string("The registered name is not valid.").trim(),
  address: yup.object().shape({
    country: yup.string("The country is not valid.").trim().required("Please enter your country."),
    city: yup.string("The city is not valid.").trim().required("Please enter your city."),
    address: yup.string("The address is not valid.").trim().required("Please enter your address."),
    zipCode: yup
      .string("The zip code is not valid.")
      .trim()
      .min(4, "The ZIP code must be at least 4 characters long.")
      .required("Please enter your zip code.")
      .matches(/^[0-9]+$/, "The zip code must only contain numbers."),
  }),
  additionalAddress: yup.object().shape({
    country: yup.string("The country is not valid.").trim(),
    city: yup.string("The city is not valid.").trim(),
    address: yup.string("The address is not valid.").trim(),
    zipCode: yup
      .string("The zip code is not valid.")
      .trim()
      .test(
        "check-zip-code-length",
        "The ZIP code must be at least 4 characters long.",
        (val) => !val || val === '' || val.length >= 4
      )
      .test(
        "check-zip-code-content",
        "The zip code must only contain numbers.",
        (val) => !val || val === '' || /^[0-9]+$/.test(val)
      )
  }),
  phone: yup.string("The phone is not valid.").trim().required("The phone is required."),
  companyLogo: yup
    .mixed()
    .required("The company logo is required.")
    .test(
      "check-file-type",
      `The allowed file types are: ${IMAGE_FILE_EXTENSIONS.join(", ")}.`,
      (file) => !file || (file.length !== 0 && IMAGE_FILE_EXTENSIONS.includes(file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)))
    ),
  industry: yup
    .string("The industry field is not valid.")
    .required("The industry field is required.")
    .test("industry-validation", `The industry must be one of ${JobIndustries.getLabels().join(", ")}.`, (val) => JobIndustries.getValues().includes(val)),
  taxNumber: yup.string("The tax number is not valid.").trim().required("The tax number is required."),
  contactPersonEmail: yup.string("The email is not valid.").email("Please enter a valid email.").required("Please enter your email."),
  contactPersonName: yup.string("The contact person name field is not valid.").required("The contact person name field is required."),
  contactPersonSurname: yup.string("The contact person surname field is not valid.").required("The contact person surname field is required."),
  contactPersonPhone: yup.string("The contact person phone is not valid.").trim().required("The contact person phone field is required."),
});
