import * as yup from "yup";
import { ApplicationStatus, Roles } from "../../../../utilities/constants";

export const filterFormSchema = yup.object().shape({
  status: yup.string("The status is not valid."),
  user: yup.object().shape({
    email: yup.string("The email is not valid."),
  }),
});

export const filterFormFields = (userRole) => [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      ...(userRole === Roles.ADMIN.value ? [{
        name: "user.email",
        fieldId: "email",
        label: "E-mail",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      }] : []),
      {
        name: "status",
        fieldId: "status",
        label: "Status",
        type: "select",
        style: {
          padding: "0 10px",
          size: "small",
        },
        values: [{ label: "Select a status...", value: "" }, ...ApplicationStatus.asList()],
        isRequired: false,
        size: 4,
      },
    ],
  },
];
