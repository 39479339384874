import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Link = ({ content, to, ...props }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <span
      role="button"
      style={{
        color: "#5372A6",
        textDecoration: "underline",
        fontFamily: "Poppins",
        fontSize: "14px",
        cursor: "pointer",
      }}
      tabIndex={0}
      onClick={() => navigate(to + search)}
      onKeyDown={() => navigate(to + search)}
      {...props}
    >
      {content}
    </span>
  );
};

Link.propTypes = {
  content: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
