import * as yup from "yup";

export const formSteps = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "email",
        fieldId: "email",
        label: "Email address",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "password",
        fieldId: "password",
        label: "Password",
        isRequired: true,
        type: "input-password",
        size: 12,
      },
    ],
  },
];

export const loginSchema = yup
  .object()
  .shape({
    email: yup.string("The email is not valid.").email("Please enter a valid email.").required("Please enter your email."),
    password: yup.string("The password is not valid.").required("Please enter your password."),
  })
  .required();
