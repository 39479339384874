import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { createTheme, GlobalStyles, responsiveFontSizes, ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga4';
import { hotjar } from "react-hotjar";

import store from "./redux/store";
import router from "./routes/Router";
import useWindowDimensions from "./utilities/hooks/useWindowDimensions";

let theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Poppins, sans-serif",
      fontSize: '40px',
      fontWeight: "400",
      color: "#1e2836",
    },
    h2: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "28px",
      fontWeight: "400",
      color: "#1e2836",
    },
    h3: {
      fontFamily: "Poppins, sans-serif",
      fontSize: '24px',
      fontWeight: "400",
      color: "#1e2836",
    },
    h4: {
      fontFamily: "Poppins, sans-serif",
      fontSize: '20px',
      fontWeight: "400",
      color: "#1e2836",
    },
    h5: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "400",
      fontSize: "18px",
      color: "#1e2836",
    },
    h6: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "400",
      fontSize: "16px",
      color: "#1e2836",
    },
    body1: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "14px",
      color: "#1e2836",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'], variants: ["h1", "h2", "h3", "h4", "h5", "h6"], factor: 2 })

Sentry.init({
  dsn: process.env.REACT_APP_ENV !== "local" ? "https://fe05e9887ee7051978d869f1c765152b@o4504735633375232.ingest.sentry.io/4506593514487808" : "",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["https://api.posrednik24.com"],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const TRACKING_ID = "G-9FGW43J5BH" // process.env.REACT_APP_ENV === "production" ? "G-6KYX97VLQM" : "";
ReactGA.initialize(TRACKING_ID, {
  testMode: process.env.REACT_APP_ENV !== 'local',
});
hotjar.initialize(3804427, 6);

const App = () => {
  const { width } = useWindowDimensions();

  return (
    <div className="App">
      <ToastContainer position={width < 900 ? "bottom-center" : "top-right"} autoClose={5000} />
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={{ "&:focus": { outline: "0 !important" } }} />
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
