import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { registerUser } from "../../../redux/auth/actions";
import Form from "../../../components/form/Form";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import Link from "../../../components/shared/Link";
import { formSteps, registerSchema } from "./formDetails";
import { Roles } from "../../../utilities/constants";

const styles = {
  formBox: {
    margin: "auto",
    width: {
      xl: "700px",
      md: "700px",
      sm: "550px",
      xs: "300px"
    }
  },
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.loading);
  const location = useLocation();
  const [searchParams,] = useSearchParams();

  const form = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onChange",
  });

  const handleSubmit = async () => {
    await form.trigger();
    if (form.formState.isValid) {
      dispatch(registerUser({ ...form.getValues(), role: location.pathname === "/company/register" ? Roles.COMPANY.value : Roles.USER.value, referralCode: searchParams.get('referral_code') || null }))
        .unwrap()
        .then((data) => {
          toast.success(data?.data);
          navigate("/email-verification");
        });
    }
  };

  return (
    <Box sx={styles.formBox}>
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={{ color: "#C02026", mb: 1 }}>
          {location.pathname === "/company/register" ? "Register a company" : "Hey! Welcome"}
        </Typography>
        <Typography variant="h6">Please enter your email address to create your account.</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: 1, display: "table", textAlign: "center" }}>
              <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "400", display: "table-cell", verticalAlign: "middle" }}>
                Have an account? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: 1, display: "table", textAlign: "center" }}>
              <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "400", display: "table-cell", verticalAlign: "middle" }}>
                &nbsp;
                <Link content="Register as company" to="/company/register" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: 1 }} textAlign="center">
              <PrimaryButton onClick={handleSubmit} disabled={isLoading}>
                Sign Up
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default Register;
