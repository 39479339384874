import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "../../../components/form/Form";
import { emailFormSchema, emailFormSteps } from "./formDetails";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { resendVerificationCode, verifyAccount } from "../../../redux/auth/actions";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.loading);
  const [searchParams] = useSearchParams();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (searchParams.has("code")) {
      dispatch(verifyAccount({ code: searchParams.get("code") }))
        .unwrap()
        .then(() => {
          toast.success("Your email has been verified.");
          navigate("/change-password");
        });
    }
  }, [searchParams]);

  const emailForm = useForm({
    resolver: yupResolver(emailFormSchema),
    mode: "onChange",
  });

  return (
    <Box margin="auto">
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={{ color: "#C02026", mb: 1 }}>
          Email Verification
        </Typography>
      </Box>
      {!showForm ? (
          <Box>
            <Typography variant='h5' sx={{ marginBottom: '10px' }}>Please check your email to verify your account.</Typography>
            <PrimaryButton onClick={() => setShowForm(true)}>Resend Code</PrimaryButton>
          </Box>
        ) : (
          <Form
            steps={emailFormSteps}
            form={emailForm}
            onSubmit={(vals) => {
                dispatch(resendVerificationCode(vals));
                toast.success("Your verification code has been emailed.")
                setShowForm(false);
              }
            }
            FormButtons={
              <PrimaryButton type="submit" disabled={isLoading}>
                Send code
              </PrimaryButton>
            }
          />
      )}
    </Box>
  );
};

export default EmailVerification;
