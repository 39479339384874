import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid, Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  companyVerificationSchema, companyVerificationSteps,
  profileVerificationSchema, profileVerificationSteps, promoterVerificationSchema, promoterVerificationSteps
} from "./formDetails";
import { patchProfile } from "../../../redux/profile/actions";
import { patchCompany } from "../../../redux/company/actions";
import { getCurrentUser, getUserById } from "../../../redux/users/actions";
import PrimaryButton from "../PrimaryButton";
import {
  Roles,
} from "../../../utilities/constants";
import { getCurrentUserSelector, getUserSelector } from "../../../redux/users/selector";
import { patchPromoter } from "../../../redux/referrals/actions";
import FormFields from "../../form/FormFields";

const UserForm = ({ userId }) => {
  const [formDetails, setFormDetails] = useState({
    schema: null,
    steps: null,
  });
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector, shallowEqual);
  const user = useSelector(userId ? getUserSelector(userId) : getCurrentUserSelector, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.profiles.loading);
  const [expanded, setExpanded] = useState([0, 1, 2, 3, 4, 5, 6]);

  const form = useForm({
    resolver: yupResolver(formDetails.schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const profilePhoto = form.watch("profilePhoto");

  useEffect(() => {
    if (profilePhoto instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(profilePhoto);
      reader.onload = () => {
        form.setValue("profilePhoto.fileUrl", reader.result.toString());
      };
    }
  }, [profilePhoto])

  const setStepsAndSchema = () => {
    if (!formDetails.schema || !formDetails.steps) {

      // Handle an unauthenticated user i.e. the public resume maker
      if (!user) {
        setFormDetails({
          schema: profileVerificationSchema(user),
          steps: profileVerificationSteps(false),
        });
        return;
      }

      // Handle schema and steps for auth users
      if (user.role === Roles.USER.value) {
        setFormDetails({
          schema: profileVerificationSchema(user),
          steps: profileVerificationSteps(true),
        });
      } else if (user?.role === Roles.COMPANY.value) {
        setFormDetails({
          schema: companyVerificationSchema,
          steps: companyVerificationSteps,
        });
      } else {
        setFormDetails({
          schema: promoterVerificationSchema,
          steps: promoterVerificationSteps,
        })
      }
      form.setValue("email", user.email);
    }
  }

  useEffect(() => {
    if (userId || user) {
      if (userId && !user) {
        dispatch(getUserById({ id: userId }));
        return;
      }
      let data = null;
      if (user.role === Roles.USER.value) {
        data = user.profile;
      } else if (user.role === Roles.COMPANY.value) {
        data = user.company;
      } else if (user.role === Roles.PROMOTER.value) {
        data = user.promoter;
      }

      if (data) {
        Object.keys(data).forEach((key) => {
          if (data[key] !== null) {
            form.setValue(key, data[key]);
          }
        });
      }

      if (location.pathname.includes("/verification") && (user.isAccountVerified || user.role === Roles.ADMIN.value || (user.role === Roles.COMPANY.value && user.verificationStatus === "PENDING"))) {
        navigate("/dashboard");
      } else if (location.pathname.includes("/profile") && !user.isAccountVerified) {
        navigate("/verification");
        toast.error("You must first finish the verification.", { toastId: "finish-verification-profile-error" });
      }
    }
    setStepsAndSchema()
  }, [userId, user, location.pathname]);

  const handleSubmit = async () => {
    const validationResult = await form.trigger();
    if (validationResult) {
      const data = { id: user.id };
      const formData = form.getValues();
      Object.keys(formData).forEach((k) => {
        // if (k === "id" || k === "empty") {
          data[k] = formData[k];
        // }
      });

      if (user) {
        let actionCall;
        if (user.role === Roles.USER.value) {
          actionCall = patchProfile;
        } else if (user.role === Roles.COMPANY.value) {
          actionCall = patchCompany;
        } else {
          actionCall = patchPromoter;
        }

        dispatch(actionCall(data))
          .unwrap()
          .then(() => {
              if (currentUser?.role !== Roles.ADMIN.value) {
                dispatch(getCurrentUser())
                  .unwrap()
                  .then((respData) => {
                    if (respData?.isAccountVerified) {
                      toast.success(!user.isAccountVerified ? "Your account has been verified." : "Your profile has been updated.");
                      navigate("/dashboard");
                    }
                  });
              } else {
                navigate(`/users/${userId}?tab=view-profile`)
              }
          });
      }
    } else {
      setExpanded([0, 1, 2, 3, 4, 5, 6])
      let firstErrorName = Object.keys(form.formState.errors)[0];
      if (firstErrorName === "languages") {
        firstErrorName = "language"
      } else if (firstErrorName === "education") {
        firstErrorName = "institutionName"
      } else if (firstErrorName === "workExperience") {
        firstErrorName = "jobTitle"
      }
      const element = document.querySelector(`[name="${firstErrorName}"]`)
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 150,
        behavior: "smooth"
      });
    }
  };

  return (
    <Box width="95%" margin="20px auto">
      <Box>
          {formDetails.steps && (
            <Box>
              <Grid container>
                <Grid item md={8} lg={8} order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}>
                  {formDetails.steps.map((i, index) => (
                    <div>
                      <Accordion expanded={expanded.includes(index)} onChange={() => {
                        if (expanded.includes(index)) {
                          setExpanded((oldVal) => oldVal.filter((expI) => expI !== index));
                        } else {
                          setExpanded([...expanded, index])
                        }
                      }} style={{ marginBottom: '25px', border: '1px solid black' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography variant='h5'>
                            {i.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormFields
                            form={form}
                            page={i}
                            isLoading={isLoading}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                  <PrimaryButton onClick={handleSubmit}>
                    Submit
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Box>
        )}
      </Box>
    </Box>
  );
};

UserForm.propTypes = {
  userId: PropTypes.number,
};

UserForm.defaultProps = {
  userId: null,
};

export default UserForm;
