import { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../../../components/shared/Table";
import {
  getCompanyCreatedNotifications,
  getJobApplicationNotifications, getJobCreatedNotifications, getProfileUpdatedUsersNotifications,
  getProfileVerifiedUsersNotifications
} from "../../../../redux/notifications/selectors";
import { getNotifications } from "../../../../redux/notifications/actions";

const columns = [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "title",
    label: "Title",
    value: (row) => row?.content,
    props: {
      align: "left",
    },
  },
];

const AdminDashboard = () => {
  const isLoading = useSelector((state) => state.notifications.loading);
  const verifiedProfileUserNotifications = useSelector(getProfileVerifiedUsersNotifications);
  const profileUpdatedUserNotifications = useSelector(getProfileUpdatedUsersNotifications);
  const jobApplicationNotifications = useSelector(getJobApplicationNotifications);
  const jobCreatedNotifications = useSelector(getJobCreatedNotifications);
  const companyCreatedNotifications = useSelector(getCompanyCreatedNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <Box>
      <Grid container>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box mb={3}>
              <Typography variant='h5'>User Verified Profile</Typography>
            </Box>
            <Table
              columns={columns}
              data={verifiedProfileUserNotifications}
              isLoading={isLoading}
              options={{
                shortenContent: false,
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box mb={3}>
              <Typography variant='h5'>User Profile Updated</Typography>
            </Box>
            <Table
              columns={columns}
              data={profileUpdatedUserNotifications}
              isLoading={isLoading}
              options={{
                shortenContent: false,
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box mb={3}>
              <Typography variant='h5'>Job Applications</Typography>
            </Box>
            <Table
              columns={columns}
              data={jobApplicationNotifications}
              isLoading={isLoading}
              options={{
                shortenContent: false,
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box mb={3}>
              <Typography variant='h5'>Job Created</Typography>
            </Box>
            <Table
              columns={columns}
              data={jobCreatedNotifications}
              isLoading={isLoading}
              options={{
                shortenContent: false,
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Box>
            <Box mb={3}>
              <Typography variant='h5'>Company Created</Typography>
            </Box>
            <Table
              columns={columns}
              data={companyCreatedNotifications}
              isLoading={isLoading}
              options={{
                shortenContent: false,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminDashboard;