import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import ProfilePicture from "./ProfilePicture";

const CompanyViewer = ({ data, zoomOut }) => (
    <Box>
      <Box sx={{ boxShadow: "0px 0px 40px -24px rgba(0,0,0,0.75)", borderRadius: "10px", padding: "20px 20px", wordWrap: "break-word" }}>
        <Grid container className="grid-container" sx={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Box padding="0 10%">
              <ProfilePicture profilePicture={data.companyLogo} zoomOut={zoomOut} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h4" fontSize={zoomOut ? "13px" : "16px"} sx={{ color: "#C02026" }}>
                {data.name}
              </Typography>
            </Box>
            <hr style={{ border: "2px solid rgb(0 0 0 / 43%)" }} />
            <Box sx={{ textAlign: 'left' }}>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>Registered Name: {data?.registeredName}</Typography>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>Phone: {data.phone}</Typography>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>
                Address:{" "}
                {data?.address && data.address?.country && data.address?.city && data.address?.zipCode &&
                  data.address?.address && `${data?.address?.country}, 
                                  ${data?.address?.city} ${data?.address?.zipCode}, 
                                  ${data?.address?.address}`}
              </Typography>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>
                Additional address:{" "}
                {data?.additionalAddress && data.additionalAddress?.country && data.additionalAddress?.city && data.additionalAddress?.zipCode &&
                  data.additionalAddress?.address && `${data?.additionalAddress?.country}, 
                                  ${data?.additionalAddress?.city} ${data?.additionalAddress?.zipCode}, 
                                  ${data?.additionalAddress?.address}`}
              </Typography>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>Tax Number: {data.taxNumber}</Typography>
              <Typography fontSize={zoomOut ? "11px" : "14px"}>Industry: {data.industry}</Typography>
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: '5px' }}>
              <Typography fontSize={zoomOut ? "11px" : "14px"} fontWeight='500'>Contact Person</Typography>
              <Box p='5px'>
                <Typography fontSize={zoomOut ? "11px" : "14px"}>Name: {data.contactPersonName}</Typography>
                <Typography fontSize={zoomOut ? "11px" : "14px"}>Surname: {data.contactPersonSurname}</Typography>
                <Typography fontSize={zoomOut ? "11px" : "14px"}>Phone: {data.contactPersonPhone}</Typography>
              </Box>
            </Box>
            <hr />
          </Grid>
        </Grid>
      </Box>
    </Box>
);

CompanyViewer.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    registeredName: PropTypes.string,
    companyLogo: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    taxNumber: PropTypes.string,
    industry: PropTypes.string,
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    additionalAddress: PropTypes.shape({
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    contactPersonName: PropTypes.string,
    contactPersonSurname: PropTypes.string,
    contactPersonPhone: PropTypes.string,
  }),
  zoomOut: PropTypes.bool,
};

CompanyViewer.defaultProps = {
  data: {
    name: "ACME Company",
    registeredName: "",
    taxNumber: "",
    phone: "",
    industry: "",
    contactPersonName: "",
    contactPersonSurname: "",
    contactPersonPhone: "",
  },
  zoomOut: false,
}

export default CompanyViewer;
