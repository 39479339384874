import * as yup from 'yup'
import dayjs from "dayjs";

yup.addMethod(yup.object, 'dayjs', function method(message) {
  return this.test('dayjs', message, (value) => {
    if (!value) {
      return true
    }
    return dayjs.isDayjs(value)
  })
})

export default yup;