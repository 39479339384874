import { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

import Header from "../shared/Header";
import { addReferralToUser } from "../../redux/users/actions";
import Footer from "../shared/Footer";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [searchParams,] = useSearchParams();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    hotjar.stateChange(location.pathname + location.search)
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      if (searchParams.has('referral_code')) {
        dispatch(addReferralToUser({ code: searchParams.get('referral_code') }));
      }
      navigate(`/jobs${search}`);
    }
  }, [search, isAuthenticated]);

  return (
    <Box>
      <Box sx={{ height: "100vh" }}>
          <CssBaseline />
          <Header menuDrawerOpen={menuDrawerOpen} setMenuDrawerOpen={setMenuDrawerOpen} />
          <Box component="main" sx={{ flexGrow: 1, p: 2, display: "table", height: "90%", margin: "auto" }}>
            <Box sx={{ ...(!pathname.includes("jobs") && { display: "table-cell", verticalAlign: "middle" }) }}>
              <Outlet />
            </Box>
          </Box>
          <Footer />
      </Box>
    </Box>
  );
};

export default DefaultLayout;
