import { useState } from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import SecondaryButton from "../shared/SecondaryButton";

const ShowableFields = ({ showableFields, constructField }) => {
  const [showFields, setShowFields] = useState(false);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ textAlign: 'center', mt: 4, mb: 2 }}
      >
        <SecondaryButton onClick={() => setShowFields((oldVal) => !oldVal)}>
          <span>{showFields ? 'Hide' : 'Add'}&nbsp;{showableFields.buttonText}</span>
        </SecondaryButton>
      </Grid>
      {showFields && showableFields.fields.map((field) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={field.size}
            lg={field.size}
            xl={field.size}
            key={field.name}
            sx={{
              padding: field?.style?.padding,
              margin: field.type === "file" && field?.options && field.options.size === "small" ? "auto 0 0 auto" : `10px ${field?.floatRight ? "0 0 auto" : "0"}`,
            }}
          >
            {constructField(field)}
          </Grid>
      ))}
    </>
  )
}

ShowableFields.propTypes = {
  showableFields: PropTypes.objectOf({
    showable: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.objectOf({
      name: PropTypes.string.isRequired,
      fieldId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isRequired: PropTypes.bool.isRequired,
      size: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  constructField: PropTypes.func.isRequired,
}

export default ShowableFields;