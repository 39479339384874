import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { addToState, isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { getProfile, patchProfile } from "./actions";
import { getCurrentUser, getUserById, patchUser } from "../users/actions";
import { Roles } from "../../utilities/constants";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(getProfile.fulfilled, patchProfile.fulfilled), (state, { payload }) => {
        Object.keys(payload).forEach((item) => {
          if (["education", "workExperience", "certificates", "languages", "socialLinks", "hobbies", "skills"].includes(item)) {
            // eslint-disable-next-line no-param-reassign
            payload[item] = JSON.parse(payload[item]);
          }
        });
        return {
          ...state,
          data: addToState(payload, state.data),
          error: null,
        };
      })

      .addMatcher(isAnyOf(getCurrentUser.fulfilled, patchUser.fulfilled, getUserById.fulfilled), (state, { payload }) =>
        payload.role === Roles.USER.value
          ? {
              ...state,
              data: addToState(payload.profile, state.data, true, ["education", "workExperience", "certificates", "languages", "socialLinks", "hobbies", "skills"]),
            }
          : state
      )

      .addMatcher(isPendingAction("profile"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("profile"), (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload,
      }))
      .addMatcher(isFulfilledAction("profile"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default profileSlice.reducer;
