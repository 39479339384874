import { Checkbox, FormControlLabel, Skeleton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";
import ErrorBox from "../shared/ErrorBox";

const CheckBox = ({ field, register, control, errors, isLoading }) =>
  isLoading ? (
    <Skeleton animation="wave" />
  ) : (
    <>
      <FormControlLabel
        control={
          <Controller
            control={control}
            id={field.fieldId}
            className={field.fieldClass}
            name={field.name}
            defaultValue={field?.defaultValue}
            inputRef={register(field.name)}
            render={({ field: { onChange, value } }) => <Checkbox checked={value} disabled={field.disabled} defaultValue={field?.defaultValue} onChange={(e) => onChange(e.target.checked)} />}
          />
        }
        label={
          <>
            <Typography variant="p">
              {field?.label}
              {field.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </>
        }
      />
      <ErrorBox message={_.get(errors, field.name)?.message} />
    </>
  );

CheckBox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    multiple: PropTypes.bool,
    helpText: PropTypes.string,
    isImages: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldId: PropTypes.string,
    type: PropTypes.string.isRequired,
    min: PropTypes.number,
    defaultValue: PropTypes.string,
    icon: PropTypes.element,
    fieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.shape({
      find: PropTypes.string,
    }),
    style: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CheckBox;
