import { useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

import Header from "../shared/Header";
import { DrawerWidth } from "../../utilities/constants";
import { getCurrentUserSelector } from "../../redux/users/selector";
import { getCurrentUser } from "../../redux/users/actions";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";
import { PrivateRoutes, SharedRoutes } from "../../routes/Routes";
import AppMenuDrawer from "../shared/AppMenuDrawer";

const Main = styled("main")(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: open ? `${DrawerWidth}px` : '0px',
  },
}));

const AuthenticatedLayout = () => {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(width > 800);
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector(getCurrentUserSelector, shallowEqual);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    hotjar.stateChange(location.pathname + location.search)
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location.pathname]);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUser());
    } else {
      ReactGA.set({ userId: user.id });
      if (hotjar.initialized()) {
        hotjar.identify(user.id, { userProperty: user.id });
      }
      const route = [...PrivateRoutes, ...SharedRoutes].find((i) => matchPath(i, location.pathname));
      if (route) {
        if (route?.roles && !route.roles.includes(user.role)) {
          navigate("/jobs");
          toast.error("You do not have sufficient permissions to view this page.");
        }
      } else {
        navigate("/dashboard")
      }
    }
  }, [user, location]);

  return (
    <Box>
      <CssBaseline />
      <Box>
        <Header setDrawerOpen={setOpen} setMenuDrawerOpen={setMenuDrawerOpen} menuDrawerOpen={menuDrawerOpen} />
        <AppMenuDrawer open={open} setOpen={setOpen} />
        <Main open={open} width={width}>
          {user && <Outlet />}
        </Main>
      </Box>
    </Box>
  );
};

export default AuthenticatedLayout;
