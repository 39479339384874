import { createSlice } from "@reduxjs/toolkit";

import { getReferrals } from "./actions";

const initialState = {
  loading: false,
  data: null,
};

export const referralSlice = createSlice({
  name: "referrals",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getReferrals.fulfilled, (state, { payload }) => ({
      ...state,
      data: payload,
    }));
  },
});

export default referralSlice.reducer;
