import { useEffect, useRef, useState } from "react";

import { Box, Pagination, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify";

import {deleteJob, getJobs} from "../../../redux/jobs/actions";
import JobCard from "../../../components/shared/JobCard";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { JobCategories, Roles } from "../../../utilities/constants";
import Table from "../../../components/shared/Table";
import { capitalizeFirstLetter } from "../../../utilities";
import Link from "../../../components/shared/Link";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import Filter from "../../../components/shared/Filter";
import { filterFormFields, filterFormSchema } from "./components/filterFormFields";

const columns = (user, dispatch) => [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "title",
    label: "Title",
    value: (row) => row?.title,
    props: {
      align: "center",
    },
  },
  {
    id: "status",
    label: "Status",
    value: (row) => row.status,
    props: {
      align: "center",
    },
  },
  {
    id: "address",
    label: "Address",
    value: (row) => (
      <span>
        {row?.address && row?.address?.country && row?.address?.city && row?.address?.zipCode &&
          row?.address?.address && `${row?.address?.country}, 
                                ${row?.address?.city} ${row?.address?.zipCode}, 
                                ${row?.address?.address}`}
      </span>
    ),
    props: {
      align: "center",
    },
  },
  {
    id: "createdAt",
    label: "Created Date",
    value: (row) => capitalizeFirstLetter(moment(row.createdAt).format("DD/MM/YYYY")),
  },
  {
    id: "company-view",
    label: "Company",
    type: "button",
    value: (row) => <Link to={`/users/${row.companyOwner}`} content={row?.companyName || 'Company'} />,
    condition: Roles.ADMIN.value === user.role,
    props: {
      align: "center",
    },
  },
  {
    id: "category",
    label: "Category",
    value: (row) => (row.jobCategories || []).map((i) => JobCategories.getLabel(i)).join(', '),
    props: {
      align: "center",
    },
  },
  {
    id: "job-delete",
    type: "button",
    value: (row) => <PrimaryButton onClick={() => (
      dispatch(deleteJob({ id: row.id })).unwrap()
          .then(() => toast.success("The job has been deleted."))
    )}>Delete</PrimaryButton>,
    condition: [Roles.ADMIN.value, Roles.COMPANY.value].includes(user.role),
    props: {
      align: "center",
    },
  },
  {
    id: "view-button",
    value: (row) => (
        <RouterLink to={`/jobs/${row.id}`} style={{ textDecoration: "none" }}>
          <PrimaryButton>View</PrimaryButton>
        </RouterLink>
    ),
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
];

const JobsList = () => {
  const firstJobRef = useRef();
  const dispatch = useDispatch();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [page, setPage] = useState(0);
  const jobsPerPage = 10;
  const user = useSelector(getCurrentUserSelector);
  const isLoading = useSelector((state) => state.jobs.loading);
  const jobs = useSelector((state) => state.jobs.data);

  useEffect(() => {
    dispatch(getJobs());
  }, []);

  const handlePagination = (pageNumber) => {
    setPage(pageNumber - 1);
    if (firstJobRef?.current) {
      firstJobRef.current.scrollIntoView({ behavior: "smooth", block: "start", top: firstJobRef.current.offsetTop - 300 });
    }
  };

  return (
    !isLoading && (
      <Box sx={{ width: "80%", margin: "auto", mt: "25px" }}>
        {jobs &&
          <Filter shouldStartOpen items={jobs} setFilteredItems={setFilteredJobs} filterSteps={filterFormFields} filterSchema={filterFormSchema} />}
        <hr />
        <Box>
          <Box>
            {[Roles.COMPANY.value, Roles.ADMIN.value].includes(user?.role) ? (
              <Table
                  data={filteredJobs || []}
                  columns={columns(user, dispatch)}
                  isLoading={isLoading}
                  noResultsContent="There are no available jobs."
              />
            ) : (
              <Box ref={firstJobRef}>
                {filteredJobs &&
                  (filteredJobs.length === 0 ? (
                    <Typography variant="h5" marginTop="10px">
                      No jobs match your criteria. &nbsp; Reset your filter options to see all available jobs.
                    </Typography>
                  ) : (
                    <Box sx={{ marginTop: '20px' }}>
                      <Grid container>
                        {filteredJobs.slice(page * jobsPerPage, page * jobsPerPage + jobsPerPage).map((item) => (
                          <Grid item key={item.title} xs={12} sm={12} md={4} lg={4} sx={{ paddingLeft: "10px" }}>
                            <JobCard jobAd={item} />
                          </Grid>
                        ))}
                      </Grid>
                      <Box>
                        <Pagination
                          onChange={(e, pageNumber) => handlePagination(pageNumber)}
                          count={Math.ceil(filteredJobs.length / jobsPerPage)}
                          variant="outlined"
                          color="primary"
                          sx={{
                            "& .MuiPagination-ul": {
                              justifyContent: "center",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
};

export default JobsList;
