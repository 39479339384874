import { createSlice } from "@reduxjs/toolkit";
import { isFulfilledAction, isPendingAction, isRejectedAction } from "../../utilities";
import { createApplication, getApplications, patchApplication } from "./actions";

const initialState = {
  loading: false,
  data: [],
};

export const applicationSlice = createSlice({
  name: "applications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createApplication.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data ? [...state.data, payload] : [payload],
      }))

      .addCase(getApplications.fulfilled, (state, { payload }) => ({
        ...state,
        data: payload,
      }))

      .addCase(patchApplication.fulfilled, (state, { payload }) => ({
        ...state,
        data: state.data
          ? state.data.map((i) => {
              if (i.id === payload.id) {
                return payload;
              }
              return i;
            })
          : [payload],
      }))

      .addMatcher(isPendingAction("applications"), (state) => ({
        ...state,
        loading: true,
      }))
      .addMatcher(isRejectedAction("applications"), (state) => ({
        ...state,
        loading: false,
      }))
      .addMatcher(isFulfilledAction("applications"), (state) => ({
        ...state,
        loading: false,
      }));
  },
});

export default applicationSlice.reducer;
