import { StyleSheet, Font } from "@react-pdf/renderer";
import RobotoMedium from "../../../assets/fonts/Roboto-Medium.ttf";
import RobotoBlack from "../../../assets/fonts/Roboto-Black.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoRegular from "../../../assets/fonts/Roboto-Regular.ttf";
import RobotoThin from "../../../assets/fonts/Roboto-Thin.ttf";

Font.register({ family: 'Roboto', fonts: [
    { src: RobotoMedium, fontStyle: 'medium' },
    { src: RobotoBold, fontStyle: 'bold' },
    { src: RobotoLight, fontStyle: 'light' },
    { src: RobotoRegular, fontStyle: 'normal' },
    { src: RobotoThin, fontStyle: 'thin' },
    { src: RobotoBlack, fontStyle: 'ultrabold' }
  ] });

export const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: "20px",
    fontFamily: "Roboto",
    fontWeight: "normal"
  },
  sectionTitle: {
    color: '#c10000',
    fontSize: '15px',
    fontWeight: 'normal'
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 'thin',
    color: '#323232',
    marginBottom: '3px'
  },
  summary: {
    fontSize: '13px',
    paddingTop: '4px',
    fontWeight: 'light'
  },
  wp30: {
    width: '30%',
  },
  wp70: {
    width: '70%',
    float: 'right'
  },
  wp50: {
    width: '50%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: '1',
  },
  profilePhotoColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    flexBasis: '30%',
  },
  personalProfile: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    paddingLeft: '20px',
    flexBasis: '70%',
  },
  userHistory: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    paddingRight: '15px',
    flexBasis: '60%',
    margin: '15px 0',
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    paddingLeft: '15px',
    flexBasis: '40%',
    margin: '15px 0'
  },
  divider: { height: '0.4em', backgroundColor: 'black', marginTop: '3px' },
  minDivider: { height: '0.4em', backgroundColor: 'black', marginTop: '3px', width: '55%' },
  profile: {
    margin: 10,
    padding: 10,
    width: '70%'
  },
  firstSection: {
    marginBottom: '10px'
  },
});
