import { useEffect, useState } from "react";

import { Box, Dialog, Grid, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate } from "react-router-dom";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WorkIcon from "@mui/icons-material/Work";
import HotelIcon from "@mui/icons-material/Hotel";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EuroIcon from '@mui/icons-material/Euro';

import PropTypes from "prop-types";
import PrimaryButton from "../../../../components/shared/PrimaryButton";
import JobApplicationModal from "./JobApplicationModal";
import { JobCategories, JobIndustries, JobTimeTypes, Roles, SalaryType } from "../../../../utilities/constants";
import { createApplication } from "../../../../redux/application/actions";
import { getCurrentUserSelector } from "../../../../redux/users/selector";
import { getRelatedJobs } from "../../../../redux/jobs/selector";
import MinJobCard from "../../../../components/shared/MinJobCard";
import { getJobs, saveJob, removeSavedJob } from "../../../../redux/jobs/actions";

const View = ({ job }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const relatedJobs = useSelector(getRelatedJobs(job.id));
  const user = useSelector(getCurrentUserSelector);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch(getJobs());
  }, []);
  const handleApply = () => {
    if (!user?.isAccountVerified) {
      setOpenDialog(true);
      return;
    }
    dispatch(createApplication({ jobId: job?.id }))
      .unwrap()
      .then(() => {
        toast.success(`You have applied for ${job?.title}.`);
      });
  };
  const addJobToSaved = () => {
    dispatch(saveJob({ id: job?.id })).unwrap()
      .then(() => toast.success("The job has been saved."));
  }
  const removeJobFromSaved = () => {
    dispatch(removeSavedJob({ id: job?.id })).unwrap()
      .then(() => toast.success("The job has been unsaved."));
  }

  return (
    <Box margin="30px auto" width={isAuthenticated ? '100%' : '80%'}>
      <Grid container>
        {(!isAuthenticated || user?.role === Roles.USER.value) && (
          <Grid item md={3} lg={3} sx={{ padding: "0 10px" }} order={{ xs: 2, sm: 2, md: 1 }}>
            <Box marginBottom="10px">
              <PrimaryButton onClick={() => navigate(-1)}>Back</PrimaryButton>
            </Box>
            {relatedJobs.map((item) => (
              <MinJobCard key={item.title} jobAd={item} vertical />
            ))}
          </Grid>
        )}
        {job && (
          <Grid
            item
            xs={12}
            sm={12}
            md={isAuthenticated && user.role !== Roles.USER.value ? 12 : 9}
            lg={isAuthenticated && user.role !== Roles.USER.value ? 12 : 9}
            sx={{ padding: "10px 10px 10px 45px", wordWrap: "break-word" }}
            order={{ xs: 1, sm: 1, md: 2 }}
          >
            <Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={9} lg={9} sx={{ textAlign: "left" }} pr={3}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <img src={JobIndustries.getItemByValue(job.industry).icon} alt="Job Icon" style={{ width: "80px", margin: 'auto', padding: '5px 0 0 5px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                      <Box sx={{ wordWrap: "break-word" }}>
                        <Typography variant="h4" sx={{ color: "#C02026", fontWeight: "bold" }}>
                          {job?.title}
                        </Typography>
                      </Box>
                      <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                        <Grid container>
                          <Grid item sm={12} xs={12}>
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                              <Grid container>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  <LocationOnIcon sx={{ marginRight: "5px", color: "#C02026" }} />
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  <Typography>{`${job?.address?.city} ${job?.address?.zipCode}, ${job?.address?.country}`}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Box display="flex" alignItems="center" flexWrap="wrap" sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  <CalendarMonthIcon sx={{ marginRight: "5px", color: "#C02026" }} />
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  <Typography>
                                    {moment(job?.startDate).format("DD/MM/YYYY")} - {moment(job?.endDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  {isAuthenticated ? (
                    <Grid container sx={{ marginTop: "30px" }}>
                      <Grid item xs={9} sm={9} md={9} lg={9}>
                        {![Roles.ADMIN.value, Roles.COMPANY.value].includes(user.role) && (user?.role === Roles.USER.value && job?.application ? (
                          <Box>
                            <Typography>You have applied for this job on {moment(job?.application?.createdAt).format("MMMM Do YYYY")}.</Typography>
                          </Box>
                        ) : (
                          <PrimaryButton onClick={handleApply}>Apply</PrimaryButton>
                        ))}
                      </Grid>
                      <Grid
                        item xs={3} sm={3} md={3} lg={3}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        {Roles.USER.value === user.role && (
                          job.isSaved ?
                            <FavoriteIcon sx={{ color: "#C02026", cursor: "pointer" }} onClick={removeJobFromSaved} /> :
                            <FavoriteBorderIcon sx={{ color: "#C02026", cursor: "pointer" }} onClick={addJobToSaved} />
                        )}
                      </Grid>
                    </Grid>
                    ) : (
                      <PrimaryButton onClick={() => navigate('/login')}>Log in to apply</PrimaryButton>
                  )}
                </Grid>
              </Grid>
              <hr />
              <Box padding="0 20px">
                <Box mt={4}>
                  <Box textAlign="left" pb={3} sx={{ whiteSpace: "pre-wrap" }}>
                    {job?.description}
                  </Box>
                  <Grid container sx={{ textAlign: "left" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box mb={1}>
                        <Typography variant="h5" fontWeight="500">
                          Qualifications
                        </Typography>
                      </Box>
                      <Box pr={3} pt={1}>
                        <ul>
                          {job?.qualifications.map((i) => (
                            <li key={i}>{i}</li>
                          ))}
                        </ul>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box pr={3} p={1}>
                        <Typography variant="h5" fontWeight="500">
                          Responsibilites
                        </Typography>
                      </Box>
                      <Box>
                        <ul>
                          {job?.requirements.map((i) => (
                            <li key={i}>{i}</li>
                          ))}
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <hr />
              <Grid container sx={{ mt: 3 }}>
                {[
                  {
                    icon: <EuroIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: <>{job.salaryType === SalaryType.HOURLY.value ? 'Hourly Rate' : 'Monthly Salary'}: {job.salary}€</>,
                    key: `Salary-${job.id}`
                  },
                  {
                    icon: <LocalDiningIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: <>Food: {job?.isFoodIncluded ? <CheckCircleIcon /> : <CancelIcon />}</>,
                    key: `Food-${job?.isFoodIncluded}`
                  },
                  {
                    icon: <HotelIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: <>Accommodation: {job?.isAccommodationIncluded ? <CheckCircleIcon /> : <CancelIcon />}</>,
                    key: `Accommodation-${job?.isAccommodationIncluded}`
                  },
                  {
                    icon: <EmojiTransportationIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: <>Transport: {job?.isTransportIncluded ? <CheckCircleIcon /> : <CancelIcon />}</>,
                    key: `Transport-${job?.isTransportIncluded}`
                  },
                  {
                    icon: <PeopleAltIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: (
                      <>
                        Available Positions: <strong>{job?.availablePositions}</strong>
                      </>
                    ),
                    key: `Positions-${job?.availablePositions}`
                  },
                  {
                    icon: <WorkIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: (
                      <>
                        Categories: <strong>{(job.jobCategories || []).map((i) => JobCategories.getLabel(i)).join(', ')}</strong>
                      </>
                    ),
                    key: `job-categories-${job.jobCategories.join(',')}`
                  },
                  {
                    icon: <AccessTimeIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: (
                      <>
                        Job Type: <strong>{JobTimeTypes.getLabel(job?.jobTimeType)}</strong>
                      </>
                    ),
                    key: `type-${job?.jobTimeType}`
                  },
                  {
                    icon: <ApartmentIcon sx={{ marginRight: "7px", color: "#C02026" }} />,
                    content: (
                      <>
                        Industry: <strong>{job?.industry}</strong>
                      </>
                    ),
                    key: `industry-${job?.industry}`
                  },
                ].map((i, index) => (
                  <Grid item md={3} lg={3} key={i.key} sx={{ padding: "15px 0 15px", borderBottom: index < 8 && "1px solid silver" }}>
                    <Grid container sx={{ textAlign: "left" }}>
                      <Grid item md={2} lg={2}>
                        {i.icon}
                      </Grid>
                      <Grid item md={10} lg={10} sx={{ paddingRight: "10px" }}>
                        <Typography variant="h6" sx={{ fontSize: "14px" }}>
                          {i.content}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box marginTop="30px">
                {isAuthenticated ? ![Roles.ADMIN.value, Roles.COMPANY.value].includes(user.role) && (user?.role === Roles.USER.value && job?.application ? (
                <Box>
                  <Typography>You have applied for this job on {moment(job?.application?.createdAt).format("MMMM Do YYYY")}.</Typography>
                </Box>
              ) : (
                <PrimaryButton onClick={handleApply}>Apply</PrimaryButton>
              )): (
                  <PrimaryButton onClick={() => navigate('/login')}>Log in to apply</PrimaryButton>
                  )}
            </Box>
          </Grid>
        )}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} keepMounted={false}>
          <JobApplicationModal setOpen={setOpenDialog} jobTitle={job.title}/>
        </Dialog>
      </Grid>
    </Box>
  );
};

View.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    isSaved: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    salary: PropTypes.string.isRequired,
    salaryType: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    jobCategories: PropTypes.arrayOf(PropTypes.string),
    jobTimeType: PropTypes.string,
    industry: PropTypes.string,
    application: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
    isAccommodationIncluded: PropTypes.bool,
    isFoodIncluded: PropTypes.bool,
    isTransportIncluded: PropTypes.bool,
    availablePositions: PropTypes.number,
    description: PropTypes.string,
    requirements: PropTypes.objectOf(PropTypes.string),
    qualifications: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default View;
