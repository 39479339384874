import { createAsyncThunk } from "@reduxjs/toolkit";

import { makeRequest } from "../../utilities/api";
import { createFormData } from "../../utilities";

export const getCompanies = createAsyncThunk("company/getAll", async (values, { rejectWithValue }) => makeRequest("/companies", "GET", values, rejectWithValue));

export const getCompany = createAsyncThunk("company/get", async (values, { rejectWithValue }) => makeRequest(`/companies/${values?.companyId}`, "GET", values, rejectWithValue));

export const patchCompany = createAsyncThunk("company/patch", async (values, { rejectWithValue }) => {
  const data = {};
  Object.keys(values).forEach((key) => {
    if ((Array.isArray(values[key]) && values[key].length === 0) || (key.includes("companyLogo") && !(values[key] instanceof File))) {
      return;
    }
    data[key] = values[key];
  });
  return makeRequest(`/companies/${values?.id}`, "PATCH", createFormData(data), rejectWithValue);
});
