import { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box, Divider, Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar, Typography
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import { logOut } from "../../redux/auth/actions";
import { getCurrentUserSelector } from "../../redux/users/selector";
import { DrawerItems, MobileWidth, Roles } from "../../utilities/constants";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";
import Drawer from "./Drawer";

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== "userRole"})(({ userRole }) => ({
  position: 'absolute',
  top: DrawerItems({ isFirstLogin: false, role: userRole }).filter((item) => !item.roles || item.roles.includes(userRole)).length > 7 ? '60%' : '50%',
  right: '0%',
  zIndex: '5000',
  border: "2px solid grey",
  edge: "start",
}));

const AppMenuDrawer = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUserSelector);
  const isFirstLogin = useSelector((state) => state.auth.isFirstLogin);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const location = useLocation();

  const username = useMemo(() => {
    if (!user) {
      return null;
    }
    if (user.role === Roles.COMPANY.value) {
      return user?.company?.name ? user.company.name : user.email;
    }
    return user?.profile?.firstName && user?.profile?.lastName ? `${user.profile.firstName} ${user.profile.lastName}` : user.email;
  }, [user]);

  const userPicture = useMemo(() => {
    if (!user) {
      return null;
    }
    if (user.role === Roles.COMPANY.value && user?.company?.companyLogo?.fileUrl) {
      return  <Avatar style={{ width: "45px" }} src={user.company.companyLogo.fileUrl} />
    }
    if (user.role === Roles.USER.value && user?.profile?.profilePhoto?.fileUrl) {
      return <Avatar style={{ width: "45px" }} src={user.profile.profilePhoto.fileUrl} />
    }
    return <AccountCircleIcon sx={{ height: "100%", verticalAlign: "middle" }} />
  }, [user]);

  return (
    user && (
      <Drawer variant="permanent" open={open} width={width} anchor="left">
        <Toolbar />
        {width > 800 && (
          <StyledIconButton
            color="black"
            onClick={() => setOpen((oldVal) => !oldVal)}
            userRole={user.role}
          >
            {open ? <ArrowBackIosIcon sx={{ position: 'relative', right: '-5px' }}/> : <ArrowForwardIosIcon />}
          </StyledIconButton>
        )}
        <List sx={{ padding: "15px 0", height: "100%", border: "0" }}>
          {DrawerItems({ isFirstLogin, role: user.role }).filter((item) => !item.roles || item.roles.includes(user?.role)).map((item) => (
            <Box key={item.title} sx={{
              ...(item.title === "Sign Out" && {
                position: "absolute",
                bottom: "0",
                width: "100%",
                marginBottom: '100px'
              })
            }}>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  ...(location.pathname === item.title && {
                    backgroundColor: "rgba(255, 247, 217, 1)",
                  }),
                }}
              >
                <ListItemButton
                  onClick={() => {
                    if (width < MobileWidth) {
                      setOpen(false);
                    }
                    if (item.title === "Sign Out") {
                      dispatch(logOut())
                        .unwrap()
                        .then(() => navigate("/login"));
                    }
                    navigate(item.route);
                  }}
                  disabled={item?.disabled}
                  sx={{
                    minHeight: 30,
                    height: "40px",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ...(location.pathname === item.route && {
                      backgroundColor: "rgba(192,32,38,0.06)",
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      color: "#1e2836",
                    }}
                  >
                    <item.icon
                      style={{
                        ...(location.pathname === item.route && {
                          fill: "rgba(192,32,38,0.73)",
                        }),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        ...(location.pathname === item.route ? { color: "rgba(192,32,38,0.73)" } : { color: "#1e2836" }),
                      }}
                      variant="h5"
                      fontWeight="500"
                    >
                      {item.title}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
          <Box key='account-info-drawer-ite' position="absolute" bottom="0" width="100%">
            <Divider
              sx={{
                background: "#D4D4D4",
                borderBottomWidth: "inherit",
                height: "0.8px",
              }}
              light
            />
            <ListItem
              disablePadding
              sx={{
                display: "block",
                padding: "15px 10px",
              }}
            >
              {!open ? (
                <ListItemButton
                  sx={{
                    ":hover": {
                      background: "none",
                    },
                    minHeight: 30,
                    justifyContent: "center",
                    px: 2.5,
                    height: "100%",
                    verticalAlign: "middle",
                  }}
                >
                  {userPicture}
                </ListItemButton>
              ) : (
                <ListItemText sx={{ textAlign: "center" }}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={3} lg={3}>
                      {userPicture}
                    </Grid>
                    <Grid item xs={10} sm={10} md={9} lg={9} sx={{ whiteSpace: "break-spaces", wordWrap: "break-word", textAlign: 'left' }}>
                      <Typography variant="h6" fontWeight="500" sx={{ marginTop: "6px" }}>
                        {username}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              )}
            </ListItem>
          </Box>
        </List>
      </Drawer>
    )
  );
}

AppMenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};


export default AppMenuDrawer;