import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Table from "../Table";
import CreateDocument from "./CreateDocument";
import { deleteDocument } from "../../../redux/documents/actions";
import SecondaryButton from "../SecondaryButton";

const getColumns = (dispatch, includeFileUrl) => [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "name",
    label: "Name",
    value: (row) => row?.name,
    props: {
      align: "center",
    },
  },
  {
    id: "file",
    label: "File",
    type: "button",
    value: (row) => (
      <Link to={includeFileUrl ? row.fileUrl : (row?.templateUrl ? row.templateUrl : '#')} style={{ pointerEvents: !includeFileUrl && !row?.templateUrl && 'none' }} target="_blank">
        <SecondaryButton disabled={includeFileUrl ? !row.fileUrl : !row.templateUrl}><Typography>View</Typography></SecondaryButton>
      </Link>
    ),
    props: {
      align: "center",
    },
  },
  {
    id: "delete",
    type: "button",
    value: (row) => (
      <SecondaryButton
        onClick={() => {
          dispatch(deleteDocument({ id: row.id, isAdminDocument: row.isAdminDocument }))
            .unwrap()
            .then(() => toast.success("The file has been deleted."));
        }}
      >
          Delete
      </SecondaryButton>
    ),
    props: {
      align: "center",
    },
  },
];

const DocumentsTable = ({ userId, documents, loading }) => {
  const dispatch = useDispatch();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const location = useLocation();

  return (
    <Box>
      <Table
        columns={getColumns(dispatch, location.pathname.includes('user'))}
        data={documents || []}
        isLoading={loading}
        noResultsContent="There are no documents."
        header={
          <Grid container sx={{ padding: "15px 15px" }}>
            <Grid item md={6} lg={6} />
            <Grid item md={6} lg={6} sx={{ textAlign: "right" }}>
              <SecondaryButton onClick={() => setOpenCreateModal(true)}>
                <span>Create document</span>
              </SecondaryButton>
            </Grid>
          </Grid>
        }
      />
      <Dialog open={openCreateModal} onClose={setOpenCreateModal} fullWidth maxWidth="xs" keepMounted={false}>
        <CreateDocument userId={userId} setOpen={setOpenCreateModal} isLoading={loading} />
      </Dialog>
    </Box>
  );
};

DocumentsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  documents: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  loading: PropTypes.bool,
  userId: PropTypes.number,
};

DocumentsTable.defaultProps = {
  documents: [],
  loading: false,
  userId: null,
}

export default DocumentsTable;
