import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { login } from "../../../redux/auth/actions";
import Form from "../../../components/form/Form";
import { formSteps, loginSchema } from "./formDetails";
import Link from "../../../components/shared/Link";
import PrimaryButton from "../../../components/shared/PrimaryButton";

const styles = {
  formBox: {
    margin: "auto",
    width: {
      xl: "700px",
      md: "700px",
      sm: "550px",
      xs: "350px"
    }
  },
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.loading);
  const [searchParams] = useSearchParams();

  const form = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const handleSubmit = (values) => {
    dispatch(login({ ...values, referralCode: searchParams.get('referral_code') }))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
      })
      .catch((err) => {
        if (err?.errorCode && err.errorCode === "EMAIL_NOT_VERIFIED") {
          toast.error(err.data);
          navigate("/email-verification");
        }
        toast.error(err, { toastId: "login-failure" });
      });
  };

  return (
    <Box sx={styles.formBox}>
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={{ color: "#C02026", mb: 1 }}>
          Welcome Back
        </Typography>
        <Typography variant="h5">Log In</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ display: "table", textAlign: "center" }}>
              <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "400" }}>
                Don&apos;t have an account? &nbsp;
                <Link content="Register" to="/register" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 2, textAlign: "center" }}>
              <PrimaryButton disabled={isLoading} type="submit">
                Log In
              </PrimaryButton>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2, display: "table", textAlign: "center" }}>
              <Link content="Forgot your password?" to="/reset-password" sx={{ display: "table-cell", verticalAlign: "middle" }} />
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default Login;
