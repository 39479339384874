import { useState } from "react";

import { Box, Dialog, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { PDFDownloadLink } from "@react-pdf/renderer";

import SecondaryButton from "../SecondaryButton";
import { INDUSTRIES, Roles } from "../../../utilities/constants";
import PdfProfile from "./PdfProfile";
import ProfilePicture from "../ProfilePicture";

const styles = {
  page: {
    backgroundColor: 'white',
    fontFamily: "Roboto",
    fontWeight: "normal"
  },
  sectionTitle: {
    color: '#c10000',
    fontWeight: '600'
  },
}

const ProfileViewerDialog = ({ user, profile, setOpen }) => (
  <Box sx={{ background: 'white' }}>
    <DialogTitle>
      <Typography variant='h4'>
        {(profile?.firstName && profile?.lastName) ? `${profile?.firstName} ${profile?.lastName}` : 'Your'} Resume
      </Typography>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <Box pb={3}>
      <ProfileViewer profile={profile} user={user} />
    </Box>
  </Box>
)

const ProfileViewer = ({ user, profile, zoomOut, show, downloadButtonRef }) => {
  const [viewResume, setViewResume] = useState(false);

  return (
    <Box>
      {zoomOut && (
        <Grid container>
          <Grid item xs={12} sm={5} md={6} lg={6} sx={{ paddingRight: '4px' }}>
            <SecondaryButton onClick={() => setViewResume(!viewResume)} size='small' sx={{ marginBottom: '10px' }}>
              <span style={{ fontSize: { lg: '16px', md: '12px', sx: '12px'} }}>
                View Resume
              </span>
            </SecondaryButton>
          </Grid>
          <Grid item xs={12} sm={5} md={6} lg={6} sx={{ paddingLeft: '4px' }}>
            <PDFDownloadLink
              document={<PdfProfile privacy='normal' includePrivateData data={profile} />}
              fileName={`${user ? user?.firstName : profile?.firstName}-${user ? user?.lastName : profile.lastName}-Resume.pdf`}>
              {({ loading }) => (
                <SecondaryButton disabled={loading} sx={{ color: '#C75C57', "& :hover": { color: 'white' }, textDecoration: 'none !important' }} refElement={downloadButtonRef}>
                  <span style={{ textDecoration: 'none !important', fontSize: { lg: '16px', md: '12px', sx: '12px'} }}>Download PDF</span>
                </SecondaryButton>
              )}
            </PDFDownloadLink>
          </Grid>
        </Grid>
      )}
      {show && <Box id="resume" style={{
        width: '90%',
        margin: 'auto',
        boxShadow: zoomOut ? "0px 0px 40px -24px rgba(0,0,0,0.75)" : "",
        borderRadius: "10px",
        padding: "15px 20px",
        wordWrap: "break-word"
      }}>
        <Box style={styles.page}>
          <Grid container sx={{ textAlign: 'left', marginBottom: "20px" }}>
            <Grid item lg={4} md={4} sx={{ padding: zoomOut ? '0 7px 0 0' : '0 30px 0 0', marginLeft: '0', textAlign: 'center' }}>
              <ProfilePicture profilePicture={profile?.profilePhoto} zoomOut={zoomOut} />
            </Grid>
            <Grid item lg={8}>
              <Box>
                {(!user || [Roles.ADMIN.value, Roles.USER.value, Roles.PROMOTER.value].includes(user?.role)) && (
                  <Typography variant={zoomOut ? "h6" : "h4"} style={styles.sectionTitle}>
                    {profile?.firstName || "John"} {profile?.lastName || "Doe"}
                  </Typography>
                )}
                {profile?.dateOfBirth && (
                  <Typography style={{ fontSize: '12px', color: '#323232' }}>
                    {new Date(profile?.dateOfBirth).toLocaleDateString('en-GB')} - {profile?.nationality}
                  </Typography>
                )}
              </Box>
              <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
              <Box>
                <Typography variant='p'
                            fontSize={zoomOut ? "11px" : "16px"}>{profile?.summary || "Enter a profile summary..."}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Grid container sx={{ textAlign: 'left', mt: 3 }}>
              <Grid item sm={8} md={9} lg={8} sx={{ paddingRight: zoomOut ? "25px" : '0px' }}>
                {(profile?.workExperience && profile.workExperience.length > 0) && (
                  <Box>
                    <Box>
                      <Typography fontSize={zoomOut ? "12px !important" : "17px !important"} style={styles.sectionTitle}>Work History</Typography>
                      <hr style={{ width: "70%", marginLeft: "0", border: "1px solid rgb(0 0 0 / 43%)" }} />
                    </Box>
                    <Box mt={1}>
                    {(
                      profile?.workExperience || []
                    ).map((item) => (
                      <Box key={`${item?.jobTitle}-${item.employer}`}>
                        <Box>
                          <Typography variant='h6' fontSize={zoomOut ? "11px" : "14px"} fontWeight='bold'>
                            {item.jobTitle} {item?.employer && `at ${item.employer}`} {item?.city && `- ${item.city}`}
                          </Typography>
                          <Typography variant='h6' sx={{ fontSize: '12px' }}>
                            {INDUSTRIES.getLabel(item.industry)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography fontSize={zoomOut ? "11px" : "14px"}
                                      sx={{ display: "inline-block" }}>{item?.startDate && moment(item.startDate).format("DD/MM/YYYY")}</Typography>
                          &nbsp; - &nbsp;
                          <Typography fontSize={zoomOut ? "11px" : "14px"}
                                      sx={{ display: "inline-block" }}>{item?.isPresent ? "Present" : moment(item.endDate).format("DD/MM/YYYY")}</Typography>
                        </Box>
                        <Box>
                          <Typography fontSize={zoomOut ? "10px" : "13px"} variant='p'>{item?.description}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  </Box>
                )}
                {(profile?.education && profile.education.length > 0) && (
                  <Box sx={{ mt: 3 }}>
                  <Typography fontSize={zoomOut ? "12px !important" : "17px !important"} style={styles.sectionTitle}>Education</Typography>
                  <hr style={{ width: "70%", marginLeft: "0", border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    {(
                      profile?.education || []
                    ).map((item) => (
                      <Box key={item.institutionName + item.degree}>
                        <Typography variant="h6" fontSize={zoomOut ? "11px" : "14px"} fontWeight='bold'>{item?.degree}</Typography>
                        <Typography variant="h6" fontSize={zoomOut ? "11px" : "14px"} sx={{ display: "inline-block" }}>
                          {item?.institutionName}
                        </Typography>
                        &nbsp; - &nbsp;{" "}
                        <Typography variant="h6" fontSize={zoomOut ? "11px" : "14px"} sx={{ display: "inline-block" }}>
                          {item?.city}
                        </Typography>
                        <Box>
                          <Typography fontSize={zoomOut ? "11px" : "14px"}
                                      sx={{ display: "inline-block" }}>{item?.startDate && moment(item.startDate).format("DD/MM/YYYY")}</Typography>
                          &nbsp; - &nbsp;
                          <Typography fontSize={zoomOut ? "11px" : "14px"}
                                      sx={{ display: "inline-block" }}>{item?.isPresent ? "Present" : moment(item.endDate).format("DD/MM/YYYY")}</Typography>
                        </Box>
                        <Typography fontSize={zoomOut ? "11px" : "14px"} variant="p">{item?.description}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>)}
              </Grid>
              <Grid item sm={4} md={3} lg={4}>
                <Box>
                  <Typography variant='h5' fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Contact Information</Typography>
                  <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    <Box style={{ marginTop: '5px' }}>
                      <Typography fontSize={zoomOut ? "11px" : "14px"}>
                        Address:{" "}
                        {profile?.address && profile.address?.country && profile.address?.city && profile.address?.zipCode &&
                          profile.address?.address && `${profile?.address?.address}, 
                          ${profile?.address?.zipCode} ${profile?.address?.city}, ${profile?.address?.country}`}
                      </Typography>
                    </Box>
                    <Box style={{ marginTop: '5px' }}>
                      <Typography fontSize={zoomOut ? "11px" : "14px"}>Phone: {profile?.phone}</Typography>
                    </Box>
                    {(!user || [Roles.ADMIN.value, Roles.USER.value, Roles.PROMOTER.value].includes(user?.role)) && (
                      <Box style={{ marginTop: '5px' }}>
                        <Typography fontSize={zoomOut ? "11px" : "14px"}>E-mail: {profile?.email}</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                {(profile?.languages && profile.languages.length > 0) && (
                  <Box style={{ marginTop: "15px" }}>
                    <Typography fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Languages</Typography>
                    <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                    <Box>
                      <ul style={{ paddingLeft: zoomOut ? "18px" : "22px" }}>
                        {(!profile?.languages || profile?.languages === "empty" ? [] : profile.languages).map((item) => (
                          <li style={{ fontSize: zoomOut ? "11px" : "15px" }}
                              key={item.language}>{item?.language} - {item?.languageLevel}</li>
                        ))}
                      </ul>
                    </Box>
                </Box>)}
                {(profile?.skills && profile.skills.length > 0) && (
                <Box style={{ marginTop: "15px" }}>
                  <Typography fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Skills</Typography>
                  <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    <ul style={{ paddingLeft: zoomOut ? "18px" : "22px" }}>
                      {(profile?.skills || []).map((item) => (
                        <li style={{ fontSize: zoomOut ? "11px" : "15px" }} key={item}>{item}</li>
                      ))}
                    </ul>
                  </Box>
                </Box>)}
                {(profile?.hobbies && profile.hobbies.length > 0) && (
                  <Box style={{ marginTop: "15px" }}>
                  <Typography fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Hobbies</Typography>
                  <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    <ul style={{ paddingLeft: zoomOut ? "18px" : "22px" }}>
                      {profile?.hobbies.map((item) => (
                        <li style={{ fontSize: zoomOut ? "11px" : "15px" }} key={item}>{item}</li>
                      ))}
                    </ul>
                  </Box>
                </Box>
                )}
                {(profile?.socialLinks && profile.socialLinks.length > 0) && <Box>
                  <Typography fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Social Media</Typography>
                  <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    <ul style={{ paddingLeft: "0" }}>
                      {profile.socialLinks.map((item) => (
                        <Box key={item.socialLinkValue}>
                          <a style={{ fontSize: zoomOut ? "11px" : "15px" }} href={item.socialLinkValue} target="_blank"
                             rel="noreferrer">
                            {item.socialLinkName}
                          </a>
                        </Box>
                      ))}
                    </ul>
                  </Box>
                </Box>}
                <Box>
                  <Typography variant='h5' fontWeight='500' fontSize={zoomOut ? "12px !important" : "16px !important"} style={styles.sectionTitle}>Personal Information</Typography>
                  <hr style={{ border: "1px solid rgb(0 0 0 / 43%)" }} />
                  <Box>
                    <Box style={{ marginTop: '5px' }}>
                      <Typography fontSize={zoomOut ? "11px" : "14px"}>Driving Licences: {(profile?.drivingLicenses || []).join(', ')}{profile?.forkLiftLicense ? ', Forklift' : ''}</Typography>
                    </Box>
                    <Box style={{ marginTop: '5px' }}>
                      <Typography fontSize={zoomOut ? "11px" : "14px"}>Shoes: {profile?.shoeSize}, Shirt: {profile?.shirtSize}, Pants: {profile?.pantsSize}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {profile?.certificates && profile.certificates.length !== 0 && (
              <Box sx={{ mt: 3, textAlign: "left" }}>
                <Typography variant='h5' fontSize={zoomOut ? "13px" : "16px"} style={styles.sectionTitle}>Certificates</Typography>
                <hr style={{ marginLeft: "0", border: "1px solid rgb(0 0 0 / 43%)" }} />
                <Box>
                  {(
                    profile?.certificates || []
                  ).map((item) => (
                    <Box key={`${item?.certificateName} ${item?.institutionName}`} pb={1}>
                      <Typography variant="h6" fontSize={zoomOut ? "11px" : "14px"} fontWeight='bold'>
                        {item?.institutionName} - {item?.certificateName}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>)}
          </Box>
        </Box>
      </Box>}
      <Dialog open={viewResume} onClose={() => setViewResume(false)} fullWidth maxWidth="md">
        <ProfileViewerDialog user={user} profile={profile} setOpen={setViewResume} />
      </Dialog>
    </Box>
  );
}

const CompPropTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isEmailVerified: PropTypes.bool.isRequired,
    isAccountVerified: PropTypes.bool.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    profilePhoto: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    jobTitle: PropTypes.string,
    summary: PropTypes.string,
    workExperience: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      jobTitle: PropTypes.string,
      employer: PropTypes.string,
      city: PropTypes.string,
      description: PropTypes.string,
    })),
    education: PropTypes.arrayOf(PropTypes.shape({
      institutionName: PropTypes.string,
      city: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      degree: PropTypes.string,
      description: PropTypes.string,
    })),
    certificates: PropTypes.arrayOf(PropTypes.shape({
      certificateName: PropTypes.string,
      institutionName: PropTypes.string,
    })),
    phone: PropTypes.string,
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
      socialLinkName: PropTypes.string,
      socialLinkValue: PropTypes.string,
    })),
    languages: PropTypes.arrayOf(PropTypes.shape({
      language: PropTypes.string,
      languageLevel: PropTypes.string,
    })),
    skills: PropTypes.arrayOf(PropTypes.string),
    hobbies: PropTypes.string,
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    bankName: PropTypes.string,
    swift: PropTypes.string,
    iban: PropTypes.string,
    pantsSize: PropTypes.number,
    shirtSize: PropTypes.string,
    shoeSize: PropTypes.number,
    personalIdentifyingNumber: PropTypes.string,
    userId: PropTypes.number.isRequired,
  }).isRequired,
}

ProfileViewer.propTypes = {
  ...CompPropTypes,
  show: PropTypes.bool,
  zoomOut: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  downloadButtonRef: PropTypes.object,
};
ProfileViewerDialog.propTypes = {
  ...CompPropTypes,
  setOpen: PropTypes.func.isRequired,
};

ProfileViewer.defaultProps = {
  zoomOut: false,
  show: true,
  downloadButtonRef: null,
}

export default ProfileViewer;
