import { styled } from "@mui/system";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const StyledMuiButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled }) => ({
  backgroundColor: disabled ? "#c9adad" : "#C02026",
  color: "white",
  fontFamily: "Poppins",
  border: "0px !important",
  "&:hover": {
    color: "white",
    backgroundColor: "#C02026C9",
  },
}));

const PrimaryButton = ({ children, onClick, disabled, loading, ...props }) => (
  <StyledMuiButton size="large" onClick={onClick} disabled={disabled} {...props}>
    {loading ? <CircularProgress sx={{ color: "white" }} size={30} /> : children}
  </StyledMuiButton>
);

PrimaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  loading: false,
  disabled: false,
};

export default PrimaryButton;
