import { styled } from "@mui/system";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const StyledMuiButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled }) => ({
  backgroundColor: disabled ? "#c9adad" : "#ffffff",
  border: !disabled && "2px solid #C02026",
  borderRadius: "10px",
  color: disabled ? "rgba(161, 161, 161, 1)" : "#C02026",
  padding: "7px 20px",
  fontFamily: "Poppins",
  "&:hover": {
    color: "white !important",
    backgroundColor: "#C02026",
  },
}));

const SecondaryButton = ({ children, onClick, disabled, loading, ...props }) => {
  let refElement = null;

  if ('refElement' in props) {
    refElement = props.refElement;
    // eslint-disable-next-line no-param-reassign
    delete props.refElement;
  }

  return (
    <StyledMuiButton onClick={onClick} disabled={disabled} {...props} ref={refElement}>
      {loading ? <CircularProgress sx={{ color: "white" }} size={30} /> : children}
    </StyledMuiButton>
  )
};

SecondaryButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  refElement: PropTypes.object,
};

SecondaryButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  loading: false,
  refElement: null
};

export default SecondaryButton;
