import { useState } from "react";

import { Radio, FormControl, FormLabel, FormControlLabel, Grid, Skeleton, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

import RadioGroup from "./RadioGroup";
import ErrorBox from "../shared/ErrorBox";

const RadioLocal = ({ field, register, control, errors, controlDepends, isLoading }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    field.options.forEach((option) => {
      if (option.fieldId === event.target.value && option.controlDepends) controlDepends(false);
      else {
        controlDepends(true);
      }
    });
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" sx={{ minWidth: "100%" }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (
        <>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            <Typography
              variant="h5"
              sx={{
                color: "#525252",
              }}
              fontWeight="bold"
              fontSize="16px"
            >
              {field?.label}
              {field.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            className={field.fieldClass}
            defaultValue=""
            name={field.name}
            inputRef={register(field.name)}
            render={({ field: fieldVal }) => (
              <RadioGroup value={value} onChange={handleChange} {...fieldVal} disabled={field.disabled}>
                <Grid container columnSpacing={4}>
                  {field.options.map((option) => (
                    <Grid item xs={option.size} sm={12} md={12} lg={12} key={field.name}>
                      <Box
                        key={option.label}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          minHeight: "56px",
                          padding: "7px",
                          pl: "19px",
                          border: "1px solid #AEBBCE",
                          borderRadius: "4px",
                          boxSizing: "border-box",
                          mb: 2,
                        }}
                      >
                        <FormControlLabel
                          label={option.label}
                          control={
                            <Radio
                              value={option.fieldId}
                              id={option.fieldId}
                              checkedIcon={<CheckCircleOutlineIcon sx={{ color: "#3553D9" }} />}
                              icon={<RadioButtonUncheckedIcon sx={{ color: "#AEBBCE" }} />}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            )}
          />
          <ErrorBox message={_.get(errors, field.name)?.message} />
        </>
      )}
    </FormControl>
  );
};

RadioLocal.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  controlDepends: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RadioLocal;
