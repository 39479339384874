import { useEffect } from "react";

import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentUserSelector } from "../../../redux/users/selector";
import { Roles } from "../../../utilities/constants";
import { getAdminDocuments, getUserDocuments } from "../../../redux/documents/actions";
import UserDocuments from "../../../components/shared/UserDocuments";
import DocumentsTable from "../../../components/shared/DocumentsTable";

const Documents = () => {
  const user = useSelector(getCurrentUserSelector);
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents);

  useEffect(() => {
    if (user.role === Roles.ADMIN.value) {
      if (!documents.adminDocuments) {
        dispatch(getAdminDocuments());
      }
    } else if (!documents.userDocuments) {
        dispatch(getUserDocuments());
      }
  }, [user]);

  const renderDocumentsDashboard = () => {
    switch (user.role) {
      case Roles.ADMIN.value:
        return <DocumentsTable documents={documents?.adminDocuments} loading={documents.isLoading} />;
      case Roles.USER.value:
        return <UserDocuments documents={documents?.userDocuments} loading={documents.isLoading} />;
      default:
        return null;
    }
  };

  return <Box>{renderDocumentsDashboard()}</Box>;
};

export default Documents;
