import { Box, Divider, Paper, Skeleton, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useState } from "react";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import _ from "lodash";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy));

// IE11 sort instead of Array.prototype.sort()
const stableSort = (array, comparator) => {
  if (array === null) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = ({ columns, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    if (property === "id") {
      return;
    }
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          background: "#F0F0F0",
          borderTop: "1px solid #D4D4D4",
          borderBottom: "1px solid #D4D4D4",
        }}
      >
        {columns
          .filter((col) => col?.condition === undefined || col.condition)
          .map((headCell) => (
            <TableCell key={headCell.id} align="center" sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const LoadingSkeleton = ({ columns }) =>
  columns.map((col, index) => (
    <TableRow hover tabIndex={-1} key={`${col.id}-${index.toString()}`} sx={index % 2 !== 0 ? { background: "#FAFAFA" } : {}}>
      {[...Array(5)].map((k, i) => (
        <TableCell key={`${col?.id}-${index.toString()}-${i.toString()}`}>
          <Skeleton animation="wave" variant="text" sx={{ my: 2, mx: 1 }} />
        </TableCell>
      ))}
    </TableRow>
  ));

const Table = ({ columns, data, header, isLoading, noResultsContent, options }) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "95%", margin: "auto" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {header}
        <TableContainer>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns} />
            <TableBody>
              {isLoading ? (
                <LoadingSkeleton columns={columns} />
              ) : (
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover tabIndex={-1} key={row?.createdAt} style={index % 2 !== 0 ? { background: "#FAFAFA" } : {}}>
                        {columns
                          .filter((col) => col?.condition === undefined || col.condition)
                          .map((col) => {
                            if (col?.value) {
                              return (
                                <TableCell {...col?.props} key={`${col.id}-${row.createdAt}`} id={labelId}>
                                  {col.type === "button" ? col.value(row) : options?.shortenContent ? _.truncate(col.value(row), { length: 60, separator: "..." }) : col.value(row)}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell {...col?.props} key={`${col.id}-${row.createdAt}`}>
                                {page === 0 ? index + 1 : index + 1 + rowsPerPage * page}.
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        {data?.length !== 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {data?.length === 0 && (
          <>
            <Divider />
            <Box height="200px" display="table" margin="auto">
              <Typography variant="h3" sx={{ display: "table-cell", verticalAlign: "middle" }}>
                {noResultsContent}
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isLoading: PropTypes.bool,
  options: PropTypes.shape({
    shortenContent: PropTypes.bool,
  }),
  header: PropTypes.element,
  noResultsContent: PropTypes.string,
};

Table.defaultProps = {
  header: null,
  noResultsContent: "There are no results.",
  isLoading: false,
  options: {}
};

export default Table;
