import { useEffect, useState } from "react";

import { Box, FormControl, FormLabel, InputAdornment, Skeleton, TextField, Typography, Grid } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ErrorBox from "../shared/ErrorBox";

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ error }) => ({
  borderRadius: "6px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid silver",
    },
    "&:hover fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid black",
    },
    "&.Mui-focused fieldset": {
      border: error ? "1px solid rgba(222, 136, 133, 1)" : "1px solid black",
    },
  },
  "& input": {
    color: error ? "rgba(171, 63, 58, 1)" : "rgba(66, 66, 66, 1)",
    fontFamily: "Poppins",
    fontWeight: "500",
    height: "10px !important",
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const TextFieldLocal = ({ field, register, errors, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setShowPassword(field.type !== "input-password");
  }, [field]);

  const getType = () => {
    switch (field.type) {
      case "input-email":
        return "email";
      case "input-url":
        return "url";
      case "input-number":
        return "number";
      case "input-tel":
        return "tel";
      case "input-password":
        return showPassword ? "text" : "password";
      default:
        return "text";
    }
  };

  const getFormLabel = () => (
    <FormLabel component="legend" sx={{ mb: 2, ...(field.label === "" && { minHeight: "20px" }) }}>
      <Typography variant="h6">
        {field?.label}
        &nbsp;
        {field?.isRequired && <span>*</span>}
      </Typography>
      <Typography variant="body1" component="span">
        {field?.helpText}
      </Typography>
    </FormLabel>
  )

  const getFormField = () => (
    <>
      <StyledTextField
        id={field?.name}
        className={field?.fieldClass}
        name={field?.name}
        size="medium"
        defaultValue={field?.defaultValue}
        placeholder={field?.placeholder}
        onKeyDown={(e) => {
          if (field.type === "input-number") {
            if (e.key === "e") {
              // eslint-disable-next-line no-param-reassign
              e.preventDefault();
            }
            if (e.key === "-") {
              // eslint-disable-next-line no-param-reassign
              e.preventDefault();
            }
            if (e.key === ",") {
              // eslint-disable-next-line no-param-reassign
              e.key = ".";
            }
          }
        }}
        onKeyUp={(e) => {
          if (Object.prototype.hasOwnProperty.call(field, "min") && !Number.isNaN(parseInt(e.target.value, 10))) {
            // eslint-disable-next-line no-param-reassign
            if (e.target.value < field?.min) e.target.value = "";
          }
        }}
        InputLabelProps={{
          min: 0,
          shrink: true,
        }}
        minRows={field.type === "input-text-area" ? 5 : 1}
        multiline={field.type === "input-text-area"}
        disabled={field?.disabled}
        sx={
          field?.style?.size === "small"
            ? {
              "& .MuiInputBase-root": {
                height: "35px !important",
              },
            }
            : {}
        }
        type={getType()}
        fullWidth
        {...register(field.name)}
        error={!!_.get(errors, field.fieldId)}
        InputProps={{
          startAdornment: _.get(errors, field.name)?.message ? (
            <InputAdornment position="start" sx={{ paddingRight: "7px" }}>
              <WarningIcon sx={{ color: "#C75C57" }} />
            </InputAdornment>
          ) : (
            field.icon && (
              <InputAdornment position="start" sx={{ paddingRight: "7px" }}>
                {field.icon}
              </InputAdornment>
            )
          ),
          endAdornment: field.type === "input-password" && (
            <InputAdornment position="end">
              <Typography variant="h6" sx={{ cursor: "pointer" }} aria-label="toggle password visibility" onClick={() => setShowPassword((oldPassword) => !oldPassword)}>
                {showPassword ? "HIDE" : "SHOW"}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
      <ErrorBox message={_.get(errors, field.name)?.message} showIcon={false} />
    </>
  )

  return (
    <FormControl component="fieldset" sx={{ minWidth: "100%" }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (field?.options?.sameRow ? (
          <Grid container>
            <Grid item xs={4} sm={3} md={4} lg={4}>
              {getFormLabel()}
            </Grid>
            <Grid item xs={8} sm={9} md={8} lg={8}>
              {getFormField()}
            </Grid>
          </Grid>
        ) : (
          <Box>
            {getFormLabel()}
            {getFormField()}
          </Box>
        )
      )}
    </FormControl>
  );
};

TextFieldLocal.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    multiple: PropTypes.bool,
    helpText: PropTypes.string,
    isImages: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldId: PropTypes.string,
    type: PropTypes.string.isRequired,
    min: PropTypes.number,
    defaultValue: PropTypes.string,
    icon: PropTypes.element,
    fieldClass: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.shape({
      find: PropTypes.string,
      sameRow: PropTypes.bool,
    }),
    style: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TextFieldLocal;
