import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { makeRequest } from "../../utilities/api";

export const getJobs = createAsyncThunk("jobs/get", async (values, { rejectWithValue }) => makeRequest("/jobs", "GET", values, rejectWithValue));

export const getJobById = createAsyncThunk("jobs/getJobById", async (values, { rejectWithValue }) => makeRequest(`/jobs/${values.id}`, "GET", values, rejectWithValue));

export const createJob = createAsyncThunk("jobs/create", async (values, { rejectWithValue }) => {
  const data = {};
  Object.keys(values).forEach((k) => {
    if (k === "salary") {
      data[k] = parseFloat(values[k]).toFixed(2);
    } else if (k === "availablePositions") {
      data[k] = parseInt(values[k], 10);
    } else {
      data[k] = values[k];
    }
  });
  return makeRequest(`/jobs`, "POST", data, rejectWithValue);
});

export const updateJob = createAsyncThunk("jobs/update", async (values, { rejectWithValue }) => {
  const data = {};
  Object.keys(values).forEach((k) => {
    if (k === "salary") {
      data[k] = parseFloat(values[k]).toFixed(2);
    } else if (k === "availablePositions") {
      data[k] = parseInt(values[k], 10);
    } else {
      data[k] = values[k];
    }
  });
  return makeRequest(`/jobs/${values.id}`, "PUT", data, rejectWithValue);
});

export const deleteJob = createAsyncThunk("jobs/delete", async (values, { rejectWithValue }) =>
    makeRequest(`/jobs/${values?.id}`, "DELETE", null, rejectWithValue));

export const setStepValue = createAction("jobs/setStepValue");

export const saveJob = createAsyncThunk("jobs/saved", async (values, { rejectWithValue }) =>
  makeRequest(`/jobs/${values?.id}/saved`, "POST", null, rejectWithValue));

export const removeSavedJob = createAsyncThunk("jobs/removeSaved", async (values, { rejectWithValue }) =>
  makeRequest(`/jobs/${values?.id}/saved`, "DELETE", null, rejectWithValue));