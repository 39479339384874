import React, { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// eslint-disable-next-line import/no-cycle
import Form from "./Form";
import SecondaryButton from "../shared/SecondaryButton";
import PrimaryButton from "../shared/PrimaryButton";
import ErrorBox from "../shared/ErrorBox";

const FormFieldsArray = ({ field, fieldInfo, errors, setValue, watch }) => {
  const form = useForm({
    resolver: yupResolver(fieldInfo.schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const existingEntries = watch(fieldInfo.name);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdjusted, setIsAdjusted] = useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    if (!isAdjusted && existingEntries) {
      setValue(fieldInfo.name, (existingEntries || []).map((i, index) => {
        const values = {};
        Object.keys(i).forEach((key) => {
          values[key] = i[key];
          values.index = index
        })
        return values;
      }));
      setIsAdjusted(true);
      return () => setIsAdjusted(false);
    }
    return () => form.reset()
  }, [existingEntries]);

  const handleSubmit = async () => {
    await form.trigger();
    if (form.formState.isValid) {
      const values = form.getValues();
      if (values?.index !== undefined) {
        setValue(fieldInfo.name, existingEntries.map((item) => {
          if (item.index === values.index) {
            return values;
          }
          return item;
        }));
        setIsEdit(false);
      } else {
        setValue(fieldInfo.name, existingEntries ? [...existingEntries, {...values, index: existingEntries.length}] : [{ ...values, index: 0 }]);
      }
      form.reset();
    }
  }

  const editItem = (item) => {
    setIsEdit(true);
    Object.keys(item).map((key) => form.setValue(key, item[key]));
  }

  useEffect(() => {
    const handleErrors = async () => {
      if (errors && errors[fieldInfo.name]) {
        await Promise.all(errors[fieldInfo.name].map(async (err, index) => {
          if (err) {
            editItem(existingEntries[index]);
            await form.trigger();
          }
        }))
      }
    }

    handleErrors()
  }, [errors]);


  const deleteItem = (index) => {
    setIsEdit(false);
    setValue(fieldInfo.name, existingEntries.filter((item) => item.index !== index));
  }

  const getName = (item) => {
    let val = '';
    fieldInfo?.placeholderValues.forEach((fieldName) => {
      val += val === '' ? item[fieldName] : ` - ${item[fieldName]}`;
    });
    return val;
  }

  return (
    <Box sx={{ width: '100%', marginBottom: '20px', padding: '20px 5px', marginTop: '20px' }}>
      <Typography variant='h2' style={{ textAlign: 'center', color: '#C02026', marginBottom: '20px' }}>{fieldInfo.placeholder}</Typography>
      <Box>
        {existingEntries && existingEntries.map((item) => (
          <Grid container sx={{ padding: '5px 7px' }}>
            <Grid item sm={12} md={7} lg={7}>
              <Box>
                <Typography>{getName(item)}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6} md={2} lg={2}>
              <SecondaryButton onClick={() => editItem(item)}><span>Edit</span></SecondaryButton>
            </Grid>
            <Grid item sm={6} md={2} lg={2}>
              <SecondaryButton onClick={() => deleteItem(item.index)}><span>Delete</span></SecondaryButton>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box>
        {errors[fieldInfo.name]?.message && (
          <Box margin="auto" textAlign="center">
            <ErrorBox message={errors[fieldInfo.name]?.message} />
          </Box>
        )}
        <Form steps={[{ stepNo: 1, fields: field}]} form={form} />
        <Box sx={{ margin: '10px auto 0px', textAlign: 'center' }}>
          <PrimaryButton onClick={handleSubmit}>{isEdit ? `Edit ${fieldInfo.placeholder}` : `Add ${fieldInfo.placeholder}`}</PrimaryButton>
        </Box>
      </Box>
    </Box>
  )
}

FormFieldsArray.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  fieldInfo: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default FormFieldsArray;