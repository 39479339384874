import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import DocumentField from "./DocumentField";


const UserDocuments = ({ documents, loading }) => !loading && (
      <Box>
          <Box sx={{ marginLeft: '10%' }}>
              <Box sx={{ textAlign: 'left', width: { xs: '100%', sm: '90%', md: '70%'}, marginTop: '20px', marginBottom: '40px' }}>
                <Typography variant='h4' fontWeight='bold' sx={{ marginBottom: '5px' }}>Important forms and leaflets to download</Typography>
                <Typography variant='h5' sx={{ marginBottom: '10px' }}>Welcome to the Documents section.</Typography>
                <Typography variant='p'>This is the place where you can download the necessary documents for informing
                  and confirming the legal possibilities to apply for certain advertisements. Depending on
                  what service you need, the necessary documents are attached.
                </Typography>
              </Box>
              <Box width='80%'>
                {documents?.map((i) => <DocumentField document={i} /> )}
              </Box>
          </Box>
      </Box>
);

UserDocuments.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    fileType: PropTypes.string
  })),
  loading: PropTypes.bool,
};

UserDocuments.defaultProps = {
  documents: [],
  loading: false,
}

export default UserDocuments;
