import * as yup from "yup";

export const emailFormSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: "email",
        fieldId: "email",
        fieldClass: "normal",
        label: "Email address",
        placeholder: "Type here...",
        type: "input-text",
        size: 12,
        inputLabel: "Email",
      },
    ],
  },
];

export const emailFormSchema = yup
  .object()
  .shape({
    email: yup.string().email("Please enter a valid email.").required("Please enter your email here."),
  })
  .required();
