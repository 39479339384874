import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getApplications = createAsyncThunk("application/get", async (values, { rejectWithValue }) =>
  makeRequest(`/applications${values?.jobId ? `?jobId=${values.jobId}` : ""}`, "GET", values, rejectWithValue)
);

export const createApplication = createAsyncThunk("application/create", async (values, { rejectWithValue }) => makeRequest("/applications", "POST", values, rejectWithValue));

export const patchApplication = createAsyncThunk("application/patch", async (values, { rejectWithValue }) => makeRequest(`/applications/${values.id}`, "PATCH", values, rejectWithValue));
