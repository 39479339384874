import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Form from "../../../components/form/Form";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { changePassword } from "../../../redux/auth/actions";

export const formSteps = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "newPassword",
        fieldId: "newPassword",
        label: "Password",
        type: "input-password",
        size: "12",
      },
      {
        name: "confirmNewPassword",
        fieldId: "confirmNewPassword",
        label: "Confirm password",
        type: "input-password",
        size: "12",
      },
    ],
  },
];

export const passwordChangeSchema = yup
  .object()
  .shape({
    newPassword: yup
      .string("The password is not valid.")
      .required("Please enter your password.")
      .min(8, "The password must be at least 8 characters long.")
      .matches(/[a-z]+/, "The password must contain at least one lowercase character.")
      .matches(/[A-Z]+/, "The password must contain at least one uppercase character.")
      .matches(/\d+/, "The password must contain at least one number."),
    confirmNewPassword: yup
      .string("The password is not valid.")
      .required("Please confirm your password.")
      .min(8, "The password must be at least 8 characters long.")
      .matches(/[a-z]+/, "The password must contain at least one lowercase character.")
      .matches(/[A-Z]+/, "The password must contain at least one uppercase character.")
      .matches(/\d+/, "The password must contain at least one number."),
  })
  .required();

const styles = {
  formBox: {
    margin: "auto",
    width: {
      xl: "700px",
      md: "700px",
      sm: "550px",
      xs: "300px"
    }
  },
};

const PasswordChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstLogin = useSelector((state) => state.auth.isFirstLogin);

  const passwordChangeForm = useForm({
    resolver: yupResolver(passwordChangeSchema),
    mode: "onChange",
  });

  const handleSubmit = () => {
    dispatch(changePassword(passwordChangeForm.getValues()))
      .unwrap()
      .then(() => {
        toast.success("Your password has been changed.");
        navigate('/dashboard')
      });
  }


  return (
    <Box sx={styles.formBox}>
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={{ color: "#C02026", mb: 1 }}>
          {isFirstLogin ? 'Set' : 'Change'} your password
        </Typography>
      </Box>
      <Form
        steps={formSteps}
        form={passwordChangeForm}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "center", mt: 1 }}>
              <PrimaryButton type="submit">
                Confirm
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  )
};

export default PasswordChange;