import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import { PrivateRoutes, PublicRoutes, SharedRoutes } from "./Routes";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
import SharedLayout from "../components/layouts/SharedLayout";
import ErrorBoundary from "../components/shared/ErrorBoundary";

const router = createBrowserRouter([
  {
    element: <DefaultLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      ...PublicRoutes.map((route) => ({
        path: route.path,
        element: <route.view />,
        errorElement: <ErrorBoundary />,
      })),
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
  {
    element: <AuthenticatedLayout />,
    errorElement: <ErrorBoundary />,
    children: PrivateRoutes.map((route) => ({
      path: route.path,
      element: <route.view {...route?.props} />,
      errorElement: <ErrorBoundary />,
    })),
  },
  {
    element: <SharedLayout />,
    errorElement: <ErrorBoundary />,
    children: SharedRoutes.map((route) => ({
      path: route.path,
      element: <route.view />,
      errorElement: <ErrorBoundary />,
    })),
  },
]);

export default router;
