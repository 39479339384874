import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import AuthenticatedLayout from "./AuthenticatedLayout";
import DefaultLayout from "./DefaultLayout";

const SharedLayout = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? (
    <AuthenticatedLayout>
      <Outlet />
    </AuthenticatedLayout>
  ) : (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

export default SharedLayout;
