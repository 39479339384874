export const getJobByIdSelector = (id) => (state) => state.jobs.data?.find((i) => i.id === parseInt(id, 10));

export const getRelatedJobs = (currentJobId) => (state) => {
  const job = getJobByIdSelector(currentJobId)(state);
  const relatedJobs = [];
  if (job) {
    state.jobs.data.filter((i) => i.industry === job.industry && i.id !== job.id).slice(0, 3).map((i) => relatedJobs.push(i));
    state.jobs.data.filter((i) => i.address.city.toLowerCase() === job.address.city.toLowerCase() && i.id !== job.id).slice(0, 3).map((i) => relatedJobs.push(i));
    state.jobs.data?.filter((i) => i.id !== job.id).slice(0, 3).map((i) => relatedJobs.push(i));
  }
  return relatedJobs.reduce((acc, j) => {
    if (!acc.map((i) => i.id).includes(j.id)) {
      acc.push(j);
      return acc;
    }
    return acc;
  }, []).slice(0, 3);
};

export const getSavedJobsSelector = (state) => (state.jobs.data || []).filter((i) => i.isSaved);