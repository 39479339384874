import { createLogger } from "redux-logger/src";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import jobsReducer from "./jobs/reducer";
import profileReducer from "./profile/reducer";
import companyReducer from "./company/reducer";
import applicationReducer from "./application/reducer";
import usersReducer from "./users/reducer";
import referralsReducer from "./referrals/reducer";
import documentsReducer from "./documents/reducer";
import notificationsReducer from "./notifications/reducer";
import utilitiesReducer from "./utilities/reducer";

const logger = createLogger();

const combinedReducer = combineReducers({
  auth: authReducer,
  jobs: jobsReducer,
  profiles: profileReducer,
  companies: companyReducer,
  applications: applicationReducer,
  users: usersReducer,
  referrals: referralsReducer,
  documents: documentsReducer,
  notifications: notificationsReducer,
  utilities: utilitiesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/logout/fulfilled") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === "production" ? getDefaultMiddleware() : [...getDefaultMiddleware(), logger]),
});

export default store;
