import { useEffect } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";

import { Roles } from "../../../utilities/constants";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import PromoterDashboard from "./components/PromoterDashboard";
import UserDashboard from "./components/UserDashboard";
import { getJobs } from "../../../redux/jobs/actions";
import AdminDashboard from "./components/AdminDashboard";

const styles = {
  dashboard: {
    margin: 'auto',
    width: {
      xs: '100%',
      md: '70%'
    }
  }
}



const Dashboard = () => {
  const user = useSelector(getCurrentUserSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobs());
  }, []);

  const renderDashboard = () => {
    if ([Roles.COMPANY.value, Roles.USER.value].includes(user.role)) {
      return <UserDashboard />;
    }
    if (user.role === Roles.PROMOTER.value) {
      return <PromoterDashboard />;
    }
    if (user.role === Roles.ADMIN.value) {
      return <AdminDashboard />;
    }
    return null;
  };

  const getUserName = () => {
    if (user.role === Roles.COMPANY.value) {
      return user?.company?.name ? user.company.name : user.email;
    }
    return user?.name && user?.surname ? `${user?.name} ${user?.surname}` : user.email;
  }

  return (
    user && (
      <Box sx={{ ...styles.dashboard, width: user.role === Roles.ADMIN.value ? '90%' : '70%' }}>
        <Paper sx={{ padding: "5px 5px" }}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <Box textAlign="left" margin="2% 3%">
                <Typography variant="h3" sx={{ fontSize: { md: '20px !important', sm: '17px !important', xs: '15px !important'} }} fontWeight="500" wordWrap='break-word'>
                  Hello, {getUserName()}
                </Typography>
              </Box>
            </Grid>
            {user.role !== Roles.ADMIN.value && (
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Box position="relative" top="50%" sx={{ transform: "translateY(-50%)" }}>
                  {user?.isAccountVerified ? (
                    <Box>
                      <Typography variant="h4" sx={{ fontSize: { md: '21px !important', sm: '17px !important', xs: '15px !important' }}}>
                        Verified <VerifiedIcon sx={{ color: "#008000", fontSize: '17px', marginLeft: "5px" }} />
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h4" sx={{ fontSize: { md: '21px !important', sm: '17px !important', xs: '15px !important'} }}>
                        Not Verified <CancelIcon sx={{ color: "#C02026", fontSize: '17px !important', marginLeft: "5px" }} />
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>)}
          </Grid>
        </Paper>
        <Box margin="5% 0">
          {renderDashboard()}
        </Box>
      </Box>
    )
  );
};

export default Dashboard;
