import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { getApplications, patchApplication } from "../../../redux/application/actions";
import { getApplicationsForJob } from "../../../redux/application/selectors";
import Table from "../../../components/shared/Table";
import { capitalizeFirstLetter } from "../../../utilities";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { ApplicationStatus, Roles } from "../../../utilities/constants";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import { setPreviousPath } from "../../../redux/utilities/actions";
import Filter from "../../../components/shared/Filter";
import { filterFormFields, filterFormSchema } from "./components/filterDetails";

const ViewButton = ({ to, onClick }) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Link to={to} onClick={onClick} style={{ textDecoration: "none" }}>
    <PrimaryButton>View</PrimaryButton>
  </Link>
);

ViewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

const StatusButton = ({ row, onClick, content, status }) => (
  <PrimaryButton onClick={onClick} sx={row.status === status && { background: "green" }}>
    {content}
  </PrimaryButton>
);

StatusButton.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

const getColumns = (user, dispatch, location, handleApplication) => [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "jobTitle",
    label: "Job Title",
    value: (row) => row.job.title,
    props: {
      align: "center",
    },
  },
  {
    id: "email",
    label: "E-mail",
    value: (row) => row.user.email,
    condition: user?.role === Roles.ADMIN.value,
    props: {
      align: "center",
    },
  },
  {
    id: "status",
    label: "Status",
    value: (row) => ApplicationStatus.getLabel(row.status),
    props: {
      align: "center",
    },
  },
  {
    id: "address",
    label: "Location",
    value: (row) => (
      <span>
        {row.job?.address && row.job?.address?.country && row.job?.address?.city && row.job?.address?.zipCode &&
          row.job?.address?.address && `${row.job?.address?.country}, 
                                ${row.job?.address?.city} ${row.job?.address?.zipCode}, 
                                ${row.job?.address?.address}`}
      </span>
    ),
  },
  {
    id: "startDate",
    label: "Start Date",
    value: (row) => moment(row.job.startDate).format("DD/MM/YYYY"),
    props: {
      align: "center",
    },
  },
  {
    id: "endDate",
    label: "End Date",
    value: (row) => moment(row.job.endDate).format("DD/MM/YYYY"),
    props: {
      align: "center",
    },
  },
  {
    id: "createdAt",
    label: "Application Date",
    value: (row) => capitalizeFirstLetter(moment(row.createdAt).fromNow()),
    props: {
      align: "center",
    },
  },
  {
    id: "view-button",
    value: (row) => <ViewButton onClick={() => dispatch(setPreviousPath(location.pathname + location.search))}
                         to={[Roles.ADMIN.value, Roles.COMPANY.value].includes(user?.role) ? `/users/${row.user.id}` : `/jobs/${row.jobId}`} />,
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
  {
    id: "accept-button",
    value: (row) => <StatusButton onClick={() => handleApplication(true, row.id)} row={row} status={ApplicationStatus.ACCEPTED.value} content="Accept" />,
    condition: [Roles.ADMIN.value, Roles.COMPANY.value].includes(user?.role),
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
  {
    id: "decline-button",
    value: (row) => <StatusButton onClick={() => handleApplication(false, row.id)} row={row} status={ApplicationStatus.REJECTED.value} content="Decline" />,
    condition: [Roles.ADMIN.value, Roles.COMPANY.value].includes(user?.role),
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
];

const Applications = ({ jobId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.applications.loading);
  const [columns, setColumns] = useState(null);
  const user = useSelector(getCurrentUserSelector);
  const applications = useSelector(jobId ? (state) => getApplicationsForJob(state)(jobId) : (state) => state.applications.data);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const location = useLocation();

  const handleApplication = (accept, id) => {
    dispatch(
      patchApplication({
        id,
        status: accept ? "ACCEPTED" : "REJECTED",
      })
    );
  };

  useEffect(() => {
    dispatch(getApplications(jobId !== null ? { jobId } : {}));
  }, []);

  useEffect(() => {
    setFilteredApplications(applications);
  }, [applications]);

  useEffect(() => {
    if (!columns && user) {
      setColumns(getColumns(user, dispatch, location, handleApplication));
    }
  }, [user]);

  return (
    columns && (
      <Box>
        <Box>
          <Filter filterSteps={filterFormFields(user.role)} filterSchema={filterFormSchema} items={applications} setFilteredItems={setFilteredApplications} />
        </Box>
        <Table columns={columns} data={filteredApplications || []} isLoading={isLoading} noResultsContent="There are no applications." />
      </Box>
    )
  );
};

Applications.propTypes = {
  jobId: PropTypes.number,
};

Applications.defaultProps = {
  jobId: null,
}

export default Applications;
