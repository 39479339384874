import * as yup from "yup";

import { JobIndustries } from "../../../../utilities/constants";

export const filterFormSchema = yup.object().shape({
  title: yup.string("The title is not valid."),
  address: yup.object({
    country: yup.string("The country is not valid."),
    city: yup.string("The city is not valid."),
  }),
  industry: yup.string("The industry is not valid."),
  jobCategories: yup.array(),
});

export const filterFormFields = [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "title",
        fieldId: "title",
        label: "Title",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 4,
      },
      {
        name: "address.country",
        fieldId: "country",
        label: "Country",
        type: "select",
        style: {
          padding: "0 10px",
          size: "small",
        },
        values: [
          { label: "Select a country", value: "" },
          { label: "Germany", value: "GERMANY" },
          { label: "Croatia", value: "CROATIA" },
        ],
        isRequired: false,
        size: 4,
      },
      {
        name: "address.city",
        fieldId: "city",
        label: "City",
        style: {
          padding: "0 10px",
          size: "small",
        },
        type: "input-text",
        isRequired: false,
        size: 4,
      },
      {
        name: "jobCategories",
        fieldId: "category",
        label: "Categories",
        style: {
          padding: "0 10px",
          size: "small",
        },
        type: "select",
        values: [
          { label: "Select categories", value: "" },
          { label: "Seasonal Job", value: "SEASONAL_JOB" },
          { label: "Student Job", value: "STUDENT_JOB" },
          { label: "Visa Job", value: "VISA_JOB" },
          { label: "EU Passport Job", value: "EU_PASSPORT_JOB" },
        ],
        multiple: true,
        isRequired: false,
        size: 4,
      },
      {
        name: "industry",
        fieldId: "industry",
        label: "Industry",
        style: {
          padding: "0 10px",
          size: "small",
        },
        type: "select",
        values: [{ label: "Select job industry...", value: "" }, ...JobIndustries.asList()],
        isRequired: false,
        size: 4,
      },
      {
        name: "companyName",
        fieldId: "company",
        label: "Company",
        style: {
          padding: "0 10px",
          size: "small",
        },
        type: "input-text",
        isRequired: false,
        size: 4,
      },
    ],
  },
];
