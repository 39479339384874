import { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popper,
  Toolbar,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MainMenu } from "../../utilities/constants";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";
import Logo from "../../assets/images/logo.png";
import PrimaryButton from "./PrimaryButton";
import Drawer from "./Drawer";
import { StyledAppBar, styles } from "../layouts/styles";


const Header = ({ setDrawerOpen, setMenuDrawerOpen, menuDrawerOpen }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, index) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  return (
    <Box>
      <StyledAppBar position="fixed">
        <Toolbar>
          <Grid container sx={styles.headerGrid}>
            <Grid item xs={10} sm={10} md={1} lg={2} sx={{ padding: "2px" }}>
              {(isAuthenticated || !menuDrawerOpen) && (
                <Box sx={styles.logoBox}>
                  <a href="https://posrednik24.com">
                    <img src={Logo} alt="Posrednik Logo" style={styles.logo} />
                  </a>
                </Box>
              )}
            </Grid>
            {width > 1100 ? (
              <>
                <Grid item sx={styles.menuGrid} md={isAuthenticated ? 11 : 7} lg={isAuthenticated ? 10 : 7} style={{ paddingLeft: width < 1310 ? '0px' : '100px' }}>
                  <Toolbar>
                    {MainMenu.map((menuItem, index) => (
                      <div
                        key={menuItem.label}
                        onFocus={(e) => handlePopoverOpen(e, index)}
                        onMouseOver={(e) => handlePopoverOpen(e, index)}
                      >
                        <Button
                          style={{ color: 'black', marginRight: '15px', fontSize: '17px', textTransform: 'capitalize' }}
                          component={Link}
                          onClick={() => {
                            if (menuItem?.to === '/jobs') {
                              navigate('/jobs')
                            }
                          }}
                          to={menuItem?.to || '#'}
                        >
                          {menuItem.label}
                          {menuItem?.children && <ExpandMoreIcon />}
                        </Button>
                        {menuItem?.children && (
                          <Popper
                            open={Boolean(anchorEl && anchorEl[index])}
                            anchorEl={anchorEl && anchorEl[index]}
                            onClose={handlePopoverClose}
                            componentsProps={{
                              onMouseLeave: handlePopoverClose
                            }}
                            sx={{ zIndex: '1300', backgroundColor: 'white' }}
                          >
                            {menuItem.children.map((childItem) => (
                              <MenuItem
                                onMouseLeave={handlePopoverClose}
                                key={childItem.label}
                                component="a"
                                style={{ pointerEvents: 'cursor', width: '175px', padding: '10px 30px' }}
                                href={childItem.to}
                                rel="noopener noreferrer"
                              >
                                {childItem.label}
                              </MenuItem>
                            ))}
                          </Popper >
                        )}
                      </div>
                    ))}
                  </Toolbar>
                </Grid>
                {!isAuthenticated && (
                  <Grid item sx={{ marginLeft: "0", textAlign: "left" }} sm={4} md={4} lg={3}>
                    <Grid container>
                      <Grid item md={5} lg={5} sx={{ textAlign: "right" }}>
                        <PrimaryButton onClick={() => navigate("/register")} sx={{ padding: "7px 30px" }}>Register</PrimaryButton>
                      </Grid>
                      <Grid item md={5} lg={5} sx={{ textAlign: "left", marginLeft: "20px" }}>
                        <PrimaryButton onClick={() => navigate("/login")} sx={{ padding: "7px 30px" }}>Log In</PrimaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={2} sm={2} md={9} lg={4} sx={{ textAlign: "right" }}>
              <IconButton
              onClick={() => {
                if (isAuthenticated) {
                  setDrawerOpen((oldVal) => !oldVal);
                } else {
                  setMenuDrawerOpen((oldVal) => !oldVal);
                }
            }}
              >
              <MenuIcon style={{ color: "#1e2836" }} />
              </IconButton>
                <Drawer
                keepMounted
                variant="permanent"
                anchor="left"
                width={width}
                open={menuDrawerOpen}
                setOpen={() => setMenuDrawerOpen((oldVal) => !oldVal)}
                >
                  <Box sx={{ padding: '10px' }}>
                    <Box sx={styles.logoBox}>
                      <a href="https://posrednik24.com">
                        <img src={Logo} alt="Posrednik Logo" style={styles.logo} />
                      </a>
                    </Box>
                  </Box>
                <List sx={styles.menuList}>
                {MainMenu.map((item, index) => (
                  <Box key={item.title}>
                    <ListItem
                    disablePadding
                    sx={styles.listItem}
                    >
                      <ListItemButton
                      sx={styles.listItemBtn}
                      onClick={(e) => {
                        if (item?.children) {
                          if (anchorEl && anchorEl[index]) {
                            handlePopoverClose();
                          } else {
                            handlePopoverOpen(e, index);
                          }
                        } else if (item.to === '/jobs') {
                            navigate('/jobs');
                        } else {
                          window.location.replace(item.to);
                        }
                      }}
                      >
                        <ListItemText>
                          <Typography
                            variant="h5"
                            fontWeight="500"
                          >
                            {item.label}
                            {item?.children && <ExpandMoreIcon sx={{ verticalAlign: 'middle' }}/>}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    {item?.children && (
                      <Collapse in={Boolean(anchorEl && anchorEl[index])} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.children.map((innerItem) => (
                            <Link to={innerItem.to}>
                              <ListItem>
                                <ListItemText primary={innerItem.label} />
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </Box>
                  ))}
                  </List>
                </Drawer>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <Toolbar />
    </Box>
  );
};

Header.propTypes = {
  setDrawerOpen: PropTypes.func,
  setMenuDrawerOpen: PropTypes.func,
  menuDrawerOpen: PropTypes.bool,
};

Header.defaultProps = {
  setDrawerOpen: () => {},
  setMenuDrawerOpen: () => {},
  menuDrawerOpen: false,
}


export default Header;
