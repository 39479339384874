import { Typography, Box } from "@mui/material";

import Link from "../../../../components/shared/Link";

const CheckMailbox = () => (
  <Box width="100%" padding="0 70px">
    <Box marginBottom="30px">
      <Typography variant="h3" fontWeight='500' textTransform="uppercase" sx={{ color: "#C02026" }}>
        Check your mailbox
      </Typography>
    </Box>
    <Box>
      <Typography variant="h5">
        Your verification code has been emailed.
      </Typography>
      <Box marginTop="5px">
        <Typography variant="h6" fontWeight="400">
          Remember your password? &nbsp;
          <Link content="Log In" to="/login" />
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default CheckMailbox;
