import { useEffect } from "react";

import { Grid, Typography, Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { confirmResetPasswordSchema, formSteps } from "./formDetails";
import Form from "../../../components/form/Form";
import Link from "../../../components/shared/Link";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { confirmResetPassword } from "../../../redux/auth/actions";

const styles = {
  formBox: {
    margin: "auto",
    width: {
      xl: "700px",
      md: "700px",
      sm: "550px",
      xs: "300px"
    }
  },
};

const ResetPasswordConfirm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);

  const form = useForm({
    resolver: yupResolver(confirmResetPasswordSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!searchParams.has("code")) {
      toast.error("It looks like the link you've clicked is not valid. Please try again.");
      navigate("/reset-password");
    }
  }, [navigate, searchParams]);

  const handleSubmit = (values) => {
    dispatch(confirmResetPassword({ ...values, code: searchParams.get("code") }))
      .unwrap()
      .then((responseData) => {
        toast.success(responseData?.data);
        navigate("/login");
      });
  };

  return (
    <Box sx={styles.formBox}>
      <Box mb={3} mt={3}>
        <Typography variant="h1" sx={{ color: "#C02026", mb: 1 }}>
          Enter a new password
        </Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={4} sx={{ textAlign: "center" }}>
              <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "400" }}>
                Remember your password? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "center", mt: 1 }}>
              <PrimaryButton type="submit" disabled={isLoading}>
                Confirm
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default ResetPasswordConfirm;
