import { Box, Card, CardActions, CardContent, Chip, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import HotelIcon from "@mui/icons-material/Hotel";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import PrimaryButton from "./PrimaryButton";
import { JobCategories, JobIndustries, Roles, SalaryType } from "../../utilities/constants";
import { getCurrentUserSelector } from "../../redux/users/selector";
import useWindowDimensions from "../../utilities/hooks/useWindowDimensions";

const JobCard = ({ jobAd }) => {
  const navigate = useNavigate();
  const user = useSelector(getCurrentUserSelector);
  const { width } = useWindowDimensions();

  return (
    <Card
      sx={{ width: "100%", padding: "8px", border: '3px solid black', borderRadius: '8px', marginBottom: "15px",
        boxShadow: '0px 0px 40px -20px rgba(0,0,0,0.4)' }}
    >
      <CardContent sx={{ wordWrap: "break-word", paddingRight: "10px", textAlign: 'left' }}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={8} md={8}>
            <Typography gutterBottom variant="h5" fontWeight='bold' component="div">
              {jobAd.title}
            </Typography>
            <Typography variant='h5' fontWeight='bold'>
              {jobAd.companyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} md={4} sx={{ paddingLeft: '10px' }}>
            {(jobAd.jobCategories || []).map((category) => (
              <Chip
                label={JobCategories.getLabel(category)}
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: JobCategories.getItemByValue(category).color,
                  color: JobCategories.getItemByValue(category).textColor,
                  marginBottom: '10px'
                }}
              />
            ))}
            <Box sx={{ width: '50px', height: '50px', border: '2px solid black', borderRadius: '50%', margin: 'auto' }}>
              <img src={JobIndustries.getItemByValue(jobAd.industry).icon} alt="Job Icon" style={{ width: "40px", padding: '5px 0 0 5px' }} />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '15px' }}>
          <Box>
            <Typography variant='h6' fontSize='15px'>
              {moment(jobAd.startDate).format("DD MMM YYYY")} - {moment(jobAd.endDate).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography>
              Location: {jobAd?.address?.city}
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' fontSize='15px'>
              {jobAd.salaryType === SalaryType.HOURLY.value ? 'Hourly Rate' : 'Monthly Salary'}: {jobAd.salary}€
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: '10px' }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography fontWeight='600'>
                Included:
              </Typography>
              <Box display='inline-block' mr={2}>
                <LocalDiningIcon sx={{ fontSize: "28px", color: jobAd.isFoodIncluded ? "black" : "grey" }} />
              </Box>
              <Box display='inline-block' mr={2}>
                <HotelIcon sx={{ fontSize: "28px", color: jobAd.isAccommodationIncluded ? "black" : "silver" }} />
              </Box>
              <Box display='inline-block' mr={2}>
                <EmojiTransportationIcon sx={{ fontSize: "28px", color: jobAd.isTransportIncluded ? "black" : "silver" }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: width < 700 ? 'left' : 'right' }}>
              <Typography fontWeight='600'>
                Positions:
              </Typography>
              <Box>
                <Typography
                  variant='h6'
                  fontWeight='bold'
                >
                  {jobAd.availablePositions}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <CardActions sx={{ padding: "10px 20px" }}>
        <PrimaryButton
          size="small"
          onClick={() => navigate(`/jobs/${jobAd?.id}`)}
          sx={{ margin: 'auto', backgroundColor: 'white', color: 'black', padding: '7px 15px',
            fontWeight: '600', fontSize: '15px', border: '3px solid black !important',
            borderRadius: '8px', "&:hover": {
              backgroundColor: "black",
              color: "white"
            }}}
        >
          {user !== null && (user?.role === Roles.ADMIN.value || (user.role === Roles.COMPANY.value && jobAd?.company === user.companyId)) ? "Edit" : "Show More"} <ArrowForwardIosIcon sx={{ fontSize: "18px", marginLeft: '3px' }} />
        </PrimaryButton>
      </CardActions>
    </Card>
  );
};

JobCard.propTypes = {
  jobAd: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
    }).isRequired,
    jobCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    salaryType: PropTypes.string.isRequired,
    salary: PropTypes.number.isRequired,
    availablePositions: PropTypes.string.isRequired,
    isFoodIncluded: PropTypes.string.isRequired,
    isAccommodationIncluded: PropTypes.string.isRequired,
    isTransportIncluded: PropTypes.string.isRequired,
    company: PropTypes.number.isRequired,
  }).isRequired,
};

export default JobCard;
